import React, { useEffect, useState } from 'react';
import { ICompanyRetentionData } from './WorkTable';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  DefaultLegendContentProps,
  BarChart,
  Bar,
  Rectangle
} from 'recharts';
import { IWorkMonthGraphData } from './WorkRetention.page';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { IconInfoBlack } from '@src/resources/icon';

interface ITransformedData {
  name: string;
  [key: string]: number | string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    name: string;
    value: number;
    color: string;
  }[];
  label?: string;
}

const TOOLTIP_MESSAGE =
  '현재 연도를 기준으로, 전년도까지의 지난 기간 대비 월별 단어 수와 증감률을 확인할 수 있습니다.';

const RetentionCompareChart = ({
  compareData
}: {
  compareData: ICompanyRetentionData;
}) => {
  const [isToggle, setIsToggle] = useState(true);
  const [graphData, setGraphData] = useState<ICompanyRetentionData>();
  const [transformedData, setTransformedData] = useState<ITransformedData[]>();
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
  ];
  // 년도 변수를 컴포넌트 레벨로 이동
  const currentYear = compareData?.thisYearAmount?.year;
  const previousYear = compareData?.previousYearAmount?.year;

  useEffect(() => {
    setGraphData(compareData);
  }, [compareData]);

  const renderLegend = (props: DefaultLegendContentProps) => {
    const { payload } = props;
    return (
      <ul className="legend--list">
        {payload?.map((entry, index) => (
          <li key={`item-${index}`}>
            <span style={{ backgroundColor: entry.color }}></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (!active || !payload || !payload.length) return null;

    return (
      <div
        className="custom-tooltip"
        style={{
          background: 'white',
          padding: '10px',
          border: '1px solid #ccc'
        }}
      >
        <p>{`${label}`}</p>
        {payload.map((pld) => {
          const month = label?.toLowerCase();
          const isNaN =
            pld.name === `${currentYear}`
              ? isToggle
                ? String(
                    compareData?.momGrowthRate?.[
                      month as keyof typeof compareData.momGrowthRate
                    ]
                  ) === 'NaN'
                : String(
                    compareData?.thisYearAmount?.[
                      month as keyof typeof compareData.thisYearAmount
                    ]
                  ) === 'NaN'
              : isToggle
              ? String(
                  compareData?.yoyForSameMonthGrowthRate?.[
                    month as keyof typeof compareData.yoyForSameMonthGrowthRate
                  ]
                ) === 'NaN'
              : String(
                  compareData?.previousYearAmount?.[
                    month as keyof typeof compareData.previousYearAmount
                  ]
                ) === 'NaN';

          return (
            <p key={pld.name} style={{ color: pld.color }}>
              {`${pld.name}: ${
                isNaN
                  ? 'N/A'
                  : isToggle
                  ? `${pld.value}%`
                  : pld.value.toLocaleString()
              }`}
            </p>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const convertNaNToZero = (value: number | string) => {
      if (String(value) === 'NaN') return 0;
      if (value === undefined) return 0;
      return value;
    };

    const newTransformedData = months.map((month) => ({
      name: month.toUpperCase(),
      ...(isToggle
        ? {
            [currentYear]: convertNaNToZero(
              compareData?.momGrowthRate?.[
                month as keyof typeof compareData.momGrowthRate
              ]
            ),
            [previousYear]: convertNaNToZero(
              compareData?.yoyForSameMonthGrowthRate?.[
                month as keyof typeof compareData.momGrowthRate
              ]
            )
          }
        : {
            [currentYear]:
              compareData?.thisYearAmount?.[
                month as keyof typeof compareData.momGrowthRate
              ],
            [previousYear]:
              compareData?.previousYearAmount?.[
                month as keyof typeof compareData.momGrowthRate
              ]
          })
    }));

    setTransformedData(newTransformedData);
  }, [graphData, isToggle, currentYear, previousYear]);

  return (
    <div className="compare--content">
      <div className="compare--graph--box">
        <div className="compare--title--box">
          <strong className="compare--title--text">
            <span>지난 기간 대비</span>
            <SimpleTooltip message={TOOLTIP_MESSAGE}>
              <IconInfoBlack width={18} height={18} />
            </SimpleTooltip>
          </strong>
          <div className="compare--toggle">
            <button
              onClick={() => setIsToggle(true)}
              className={`toggle--button ${isToggle ? 'active' : ''}`}
            >
              증감률
            </button>
            <button
              onClick={() => setIsToggle(false)}
              className={`toggle--button ${!isToggle ? 'active' : ''}`}
            >
              요청 분량
            </button>
            <span
              className={`toggle--background ${!isToggle ? 'active' : ''}`}
            ></span>
          </div>
        </div>
        <div
          className="compare--graph"
          style={{ width: '100%', height: '500px' }}
        >
          {isToggle ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={transformedData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid
                  stroke="#ccc"
                  strokeDasharray="1"
                  vertical={false}
                />
                <XAxis dataKey="name" type="category" />

                <Tooltip content={<CustomTooltip />} />
                <Legend
                  iconType="circle"
                  iconSize={10}
                  content={renderLegend}
                />
                {[`${currentYear}`, `${previousYear}`].map((key, index) => (
                  <Line
                    type="linear"
                    dataKey={key}
                    stroke={index === 0 ? '#9916F5' : '#F500F5'}
                    strokeWidth={2}
                    key={`${key}-${index}`}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={transformedData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid
                  stroke="#ccc"
                  strokeDasharray="1"
                  vertical={false}
                />
                <XAxis dataKey="name" type="category" />

                <Tooltip content={<CustomTooltip />} />
                <Legend
                  iconType="circle"
                  iconSize={10}
                  content={renderLegend}
                />
                {[`${currentYear}`, `${previousYear}`].map((key, index) => (
                  <Bar
                    type="linear"
                    dataKey={key}
                    fill={index === 0 ? '#9916F5' : '#F500F5'}
                    // activeBar={
                    //   <Rectangle
                    //     fill={index === 0 ? '#B44CF7' : '#F754F7'}
                    //     stroke={index === 0 ? '#9916F5' : '#F500F5'}
                    //   />
                    // }
                    key={`${key}-${index}`}
                    barSize={20}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className="compare--table--box">
        <table>
          <colgroup>
            <col style={{ width: '20%' }} />
            {[...Array(12)].map((_, index) => (
              <col key={index} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">JAN</th>
              <th scope="col">FEB</th>
              <th scope="col">MAR</th>
              <th scope="col">APR</th>
              <th scope="col">MAY</th>
              <th scope="col">JUN</th>
              <th scope="col">JUL</th>
              <th scope="col">AUG</th>
              <th scope="col">SEP</th>
              <th scope="col">OCT</th>
              <th scope="col">NOV</th>
              <th scope="col">DEC</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text--left">
                금년도 업무 요청 분량 ({currentYear})
              </td>
              {months.map((month) => (
                <td key={month}>
                  {graphData?.thisYearAmount?.[
                    month as keyof typeof graphData.thisYearAmount
                  ] === undefined ||
                  String(
                    graphData?.thisYearAmount?.[
                      month as keyof typeof graphData.thisYearAmount
                    ]
                  ) === 'NaN'
                    ? '-'
                    : graphData?.thisYearAmount?.[
                        month as keyof typeof graphData.thisYearAmount
                      ].toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td className="text--left">
                금년도 전월 대비 증감률 ({currentYear})
              </td>
              {months.map((month) => (
                <td key={month}>
                  {graphData?.momGrowthRate?.[
                    month as keyof typeof graphData.momGrowthRate
                  ] === undefined ||
                  String(
                    graphData?.momGrowthRate?.[
                      month as keyof typeof graphData.momGrowthRate
                    ]
                  ) === 'NaN'
                    ? '-'
                    : graphData?.momGrowthRate?.[
                        month as keyof typeof graphData.momGrowthRate
                      ].toLocaleString() + '%'}
                </td>
              ))}
            </tr>
            <tr>
              <td className="text--left">
                전년도 업무 요청 분량 ({previousYear})
              </td>
              {months.map((month) => (
                <td key={month}>
                  {graphData?.previousYearAmount?.[
                    month as keyof typeof graphData.previousYearAmount
                  ] === undefined ||
                  String(
                    graphData?.previousYearAmount?.[
                      month as keyof typeof graphData.previousYearAmount
                    ]
                  ) === 'NaN'
                    ? '-'
                    : graphData?.previousYearAmount?.[
                        month as keyof typeof graphData.previousYearAmount
                      ].toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td className="text--left">
                전년도 동월 대비 증감률 ({previousYear})
              </td>
              {months.map((month) => (
                <td key={month}>
                  {graphData?.yoyForSameMonthGrowthRate?.[
                    month as keyof typeof graphData.yoyForSameMonthGrowthRate
                  ] === undefined ||
                  String(
                    graphData?.yoyForSameMonthGrowthRate?.[
                      month as keyof typeof graphData.yoyForSameMonthGrowthRate
                    ]
                  ) === 'NaN'
                    ? '-'
                    : graphData?.yoyForSameMonthGrowthRate?.[
                        month as keyof typeof graphData.yoyForSameMonthGrowthRate
                      ].toLocaleString() + '%'}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RetentionCompareChart;
