import React, { useEffect, useRef, useState } from 'react';
import api from '@api/Api';
import {
  IconArrowDownRadiusWhite,
  IconArrowRightBlack,
  IconArrowUpRadiusWhite,
  IconCloseWhite,
  IconInfoBlack,
  IconSheetHidden
} from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box, Checkbox } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useGetFileList } from '@src/hooks/file/getList';
import { useAlert } from '@src/hooks/modal/useAlert';
import { TaskCreateStyle } from './TaskCreate.style';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { useGetTaskInfo } from '../file/getTaskInfo';
import { renderRowStatus } from '@src/pages/dashboard/utils/render';
import { LANGUAGE } from '@src/common/config/Code';
import { TPInputCheckSingleBoxBasic } from '@src/units/input/TPInput.checkBoxBasic';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';

interface IFileOption {
  [key: string]: {
    file: string;
    optionData: {
      languageTaskId: string;
      sheetIndex?: number | undefined;
      useHeader: boolean;
      hiddenRowAndColumn?: boolean;
    };
  };
}

interface ISheet {
  key: number;
  sheetIndex: string;
  sheetName: string;
  isHidden: boolean;
}

interface IOption {
  option?: string;
  for?: string;
  optionOpen?: boolean;
  forOpen?: boolean;
}

interface ISegmentContent {
  source: string | null;
  target: string | null;
  limit: string | null;
  sourceOpen: boolean;
  targetOpen: boolean;
  limitOpen: boolean;
  option: IOption[];
}

const TaskCreatePage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { fileId } = useParams();
  const { data: taskData } = useGetTaskInfo(Number(fileId));
  const [fileType, setFileType] = useState('');
  const [fileOption, setFileOption] = useState<IFileOption>({
    xlsx: {
      file: 'xlsx',
      optionData: {
        languageTaskId: fileId || '',
        sheetIndex: 0,
        useHeader: false,
        hiddenRowAndColumn: false
      }
    },
    txt: {
      file: 'txt',
      optionData: {
        languageTaskId: fileId || '',
        useHeader: false
      }
    }
  });
  const { data, getData, postSegment, closeModal } = useGetFileList(
    fileId,
    fileType,
    fileType === 'txt' ? fileOption.txt : fileOption.xlsx
  );
  const { openHandle: alertHandle } = useAlert();
  const [sheetClose, setSheetClose] = useState(false);
  const [fileSheet, setFileSheet] = useState([]);
  const [fileSheetOpen, setFileSheetOpen] = useState(false);
  const [columnHeaders, setColumnHeaders] = useState<string[]>([]);
  const [tdLength, setTdLength] = useState(0);
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [orderList, setOrderList] = useState<string[]>([]);
  const optionKeyList = ['Context', 'Comment'];
  const [selectedSheet, setSelectedSheet] = useState<ISheet>({
    key: 0,
    sheetIndex: '',
    sheetName: '',
    isHidden: false
  });
  const [segmentList, setSegmentList] = useState([
    {
      type: 'headerFooter',
      text: '머리글 및 바닥글 텍스트 가져오기',
      checked: false
    },
    { type: 'hiddenLine', text: '숨겨진 행과 열 포함', checked: false }
  ]);
  const filteredSegments =
    fileType === 'txt'
      ? segmentList.filter((item) => item.type === 'headerFooter')
      : segmentList;

  const [segmentContent, setSegmentContent] = useState<ISegmentContent[]>([
    {
      source: null,
      target: null,
      limit: null,
      sourceOpen: false,
      targetOpen: false,
      limitOpen: false,
      option: []
    }
  ]);
  const [isSegmentAdd, setIsSegmentAdd] = useState(false);
  const getFileSheet = async () => {
    return await api
      .postBora(`/stock/info/xls`, {
        languageTaskId: fileId
      })
      .then((res) => {
        const sheet = res.data.sheets;
        const sheetSort = sheet.map(
          (sheet: { sheetIndex: number; sheetName: string }) => ({
            ...sheet,
            sheetIndex:
              sheet.sheetIndex + 1 < 10
                ? `0${sheet.sheetIndex + 1}`
                : sheet.sheetIndex + 1,
            key: sheet.sheetIndex
          })
        );
        setFileSheet(sheetSort);
        if (sheetSort.length > 0) {
          handleSelectSheet(sheetSort[0]);
        }
      });
  };

  useEffect(() => {
    getFileSheet();
  }, [fileId]);

  const handleAddSegment = () => {
    setSegmentContent([
      ...segmentContent,
      {
        source: null,
        target: null,
        limit: null,
        sourceOpen: false,
        targetOpen: false,
        limitOpen: false,
        option: []
      }
    ]);
  };

  const handleUploadModal = () => {
    alertHandle({
      title: '세그먼트 생성 확인',
      text: '세그먼트를 생성하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        handleUpload();
      }
    });
  };

  const excelColumnNameToIndex = (columnName: string): number => {
    let sum = 0;
    for (let i = 0; i < columnName.length; i++) {
      sum *= 26;
      sum += columnName.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
    }
    return sum - 1;
  };

  const handleUpload = async () => {
    let postData = {};
    if (fileType === 'txt') {
      const transformedContent = segmentContent.map((segment) => {
        const sourceIndex = segment.source
          ? excelColumnNameToIndex(segment.source)
          : null;
        const targetIndex = segment.target
          ? excelColumnNameToIndex(segment.target)
          : sourceIndex !== null
          ? sourceIndex
          : null;
        const limitIndex = segment.limit
          ? excelColumnNameToIndex(segment.limit)
          : null;

        const contextIndices: number[] = [];
        const commentIndices: number[] = [];

        segment.option.forEach((option) => {
          const optionIndex = option?.for
            ? excelColumnNameToIndex(option.for)
            : null;
          if (option.option === 'Context' && optionIndex !== null) {
            contextIndices.push(optionIndex);
          } else if (option.option === 'Comment' && optionIndex !== null) {
            commentIndices.push(optionIndex);
          }
        });

        return {
          source: sourceIndex,
          target: targetIndex,
          option: {
            length: limitIndex,
            context: [...new Set(contextIndices)].sort((a, b) => a - b),
            comment: [...new Set(commentIndices)].sort((a, b) => a - b)
          }
        };
      });

      postData = {
        languageTaskId: fileId,
        txtOptionData: {
          useHeader: fileOption.txt.optionData.useHeader
        },
        columnDataList: transformedContent
      };
    } else if (fileType === 'xlsx') {
      const transformedContent = segmentContent.map((segment) => {
        const sourceIndex = segment.source
          ? excelColumnNameToIndex(segment.source)
          : null;
        const targetIndex = segment.target
          ? excelColumnNameToIndex(segment.target)
          : sourceIndex !== null
          ? sourceIndex
          : null;
        const limitIndex = segment.limit
          ? excelColumnNameToIndex(segment.limit)
          : null;

        const contextIndices: number[] = [];
        const commentIndices: number[] = [];

        segment.option.forEach((option) => {
          const optionIndex = option?.for
            ? excelColumnNameToIndex(option.for)
            : null;
          if (option.option === 'Context' && optionIndex !== null) {
            contextIndices.push(optionIndex);
          } else if (option.option === 'Comment' && optionIndex !== null) {
            commentIndices.push(optionIndex);
          }
        });

        return {
          source: sourceIndex,
          target: targetIndex,
          option: {
            length: limitIndex,
            context: [...new Set(contextIndices)].sort((a, b) => a - b),
            comment: [...new Set(commentIndices)].sort((a, b) => a - b)
          }
        };
      });

      postData = {
        languageTaskId: fileId,
        xlsOptionData: {
          useHeader: fileOption.xlsx.optionData.useHeader,
          hiddenRowAndColumn: fileOption.xlsx.optionData.hiddenRowAndColumn
        },
        columnDataList: transformedContent,
        sheetIndex: selectedSheet.sheetIndex
          ? Number(selectedSheet.sheetIndex) - 1
          : 0
      };
    }
    try {
      postSegment({ segmentObj: postData, fileType });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleInput = (type: string) => {
    setSegmentList(
      segmentList.map((v) =>
        v.type === type ? { ...v, checked: !v.checked } : v
      )
    );
    setFileOption((prevOptions) => {
      if (fileType === 'txt') {
        return {
          ...prevOptions,
          txt: {
            ...prevOptions.txt,
            optionData: {
              ...prevOptions.txt.optionData,
              useHeader: !prevOptions.txt.optionData.useHeader
            }
          }
        };
      } else if (fileType === 'xlsx') {
        return {
          ...prevOptions,
          xlsx: {
            ...prevOptions.xlsx,
            optionData: {
              ...prevOptions.xlsx.optionData,
              useHeader:
                type === 'headerFooter'
                  ? !prevOptions.xlsx.optionData.useHeader
                  : prevOptions.xlsx.optionData.useHeader,
              hiddenRowAndColumn:
                type === 'hiddenLine'
                  ? !prevOptions.xlsx.optionData.hiddenRowAndColumn
                  : prevOptions.xlsx.optionData.hiddenRowAndColumn
            }
          }
        };
      }
      return prevOptions;
    });
  };

  const handleSelectSheet = (sheet: ISheet) => {
    setFileSheetOpen(false);
    if (selectedSheet !== sheet) {
      setSelectedSheet(sheet);
      setFileOption((prevOptions) => ({
        ...prevOptions,
        xlsx: {
          ...prevOptions.xlsx,
          optionData: {
            ...prevOptions.xlsx.optionData,
            sheetIndex: sheet.key,
            useHeader: false,
            hiddenRowAndColumn: false
          }
        }
      }));
      setSegmentList(segmentList.map((v) => ({ ...v, checked: false })));
    }
  };

  //thead 배열 생성
  const getColumnHeaders = (data: any[]) => {
    const maxLength = _.maxBy(data, (arr) => arr.length)?.length || 0;
    const headers = [];
    for (let i = 0; i < maxLength; i++) {
      let header = '';
      let index = i;
      while (index >= 0) {
        header = String.fromCharCode(65 + (index % 26)) + header;
        index = Math.floor(index / 26) - 1;
      }
      headers.push(header);
    }
    return headers;
  };

  useEffect(() => {
    const newHeaders = getColumnHeaders(data);
    // console.log('newHeaders', newHeaders);
    // 배열 내용이 같은지 확인
    if (!_.isEqual(columnHeaders, newHeaders)) {
      setColumnHeaders(newHeaders);
      setSourceList([...newHeaders]);
      setOrderList([...newHeaders]);
    }
  }, [data]);

  // useEffect(() => {
  //   console.log('columnHeaders', columnHeaders, data);
  // }, [columnHeaders]);

  const handleSelectChange = (
    index: number,
    type: string,
    value: string,
    optionKey?: string,
    optionIndex?: number | null
  ) => {
    if (type === 'source') {
      // source 타입 변경 시, 다른 세그먼트의 target이 현재 선택된 source와 같은지 확인
      const targetConflictIndex = segmentContent.findIndex(
        (seg, segIdx) => seg.target === value && segIdx !== index
      );
      if (targetConflictIndex !== -1) {
        // 충돌이 있는 경우, 사용자에게 경고하고 응답에 따라 처리
        alertHandle({
          title: '지정 열 변경',
          text: '이미 지정된 열입니다. <br>변경 시 기존 지정된 열은 초기화됩니다. <br>이 열로 변경하시겠습니까?',
          cancelLabel: '취소',
          ok: () => {
            // 사용자가 확인을 선택한 경우, 상태 업데이트
            setSegmentContent((prevSegments) =>
              prevSegments.map((seg, segIdx) => {
                if (segIdx === index) {
                  return {
                    ...seg,
                    [type]: value,
                    target: '',
                    sourceOpen: false,
                    targetOpen: false,
                    limitOpen: false,
                    option: seg.option.map((opt, optIdx) => {
                      if (optionIndex !== null && optIdx === optionIndex) {
                        return {
                          ...opt,
                          [optionKey as string]: value,
                          optionOpen: false,
                          forOpen: false
                        };
                      }
                      return opt;
                    })
                  };
                } else if (segIdx === targetConflictIndex) {
                  // 충돌하는 세그먼트의 target을 비움
                  return { ...seg, target: '' };
                }
                return seg;
              })
            );
          }
        });
        return; // 경고 후 함수 종료
      }
    }
    // 충돌이 없거나 type이 'source'가 아닌 경우, 일반적인 상태 업데이트
    setSegmentContent((prevSegments) =>
      prevSegments.map((seg, segIdx) => {
        if (segIdx === index) {
          return {
            ...seg,
            [type]: value,
            sourceOpen: false,
            targetOpen: false,
            limitOpen: false,
            option: seg.option.map((opt, optIdx) => {
              if (optionIndex !== null && optIdx === optionIndex) {
                return {
                  ...opt,
                  [optionKey as string]: value,
                  optionOpen: false,
                  forOpen: false
                };
              }
              return opt;
            })
          };
        }
        return seg;
      })
    );
  };

  // 드롭다운 토글
  const toggleDropdown = (
    segmentIndex: number,
    type: 'source' | 'target' | 'limit' | 'option' | 'for',
    optionIndex?: number | null
  ) => {
    setSegmentContent(
      segmentContent.map((item, idx) => {
        if (idx === segmentIndex) {
          return {
            ...item,
            sourceOpen: type === 'source' ? !item.sourceOpen : false,
            targetOpen: type === 'target' ? !item.targetOpen : false,
            limitOpen: type === 'limit' ? !item.limitOpen : false,
            option: item.option.map((opt, optIdx) => {
              if (optionIndex !== null && optIdx === optionIndex) {
                return {
                  ...opt,
                  optionOpen: type === 'option' ? !opt.optionOpen : false,
                  forOpen: type === 'for' ? !opt.forOpen : false
                };
              } else {
                // 다른 option 항목들은 모두 닫기
                return {
                  ...opt,
                  optionOpen: false,
                  forOpen: false
                };
              }
            })
          };
        }
        return {
          ...item,
          sourceOpen: false, // 다른 항목들은 모두 닫기
          targetOpen: false, // 다른 항목들은 모두 닫기
          limitOpen: false,
          option: item.option.map((opt) => ({
            ...opt,
            optionOpen: false, // 다른 항목들은 모두 닫기
            forOpen: false // 다른 항목들은 모두 닫기
          }))
        };
      })
    );
  };

  //옵션 추가
  const handleAddOption = (index: number) => {
    setSegmentContent(
      segmentContent.map((item, idx) => {
        if (idx === index) {
          return { ...item, option: [...item.option, {}] };
        }
        return item;
      })
    );
  };

  //옵션 삭제
  const handleDeleteOption = (index: number, optionIndex: number) => {
    setSegmentContent(
      segmentContent.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            option: item.option.filter((_, optIdx) => optIdx !== optionIndex)
          };
        }
        return item;
      })
    );
  };

  //세그먼트 삭제
  const handleDeleteSegment = (index: number) => {
    setSegmentContent(segmentContent.filter((_, idx) => idx !== index));
  };

  //세그먼트 초기화
  const handleResetAlert = () => {
    alertHandle({
      title: '칼럼 옵션 초기화',
      text: '입력된 내용이 모두 초기화됩니다.',
      cancelLabel: '취소',
      ok: () => {
        handleReset();
      }
    });
  };
  const handleReset = () => {
    setSegmentContent([
      {
        source: null,
        target: null,
        limit: null,
        sourceOpen: false,
        targetOpen: false,
        limitOpen: false,
        option: []
      }
    ]);
  };

  useEffect(() => {
    const maxLength = _.maxBy(data, (arr) => arr.length)?.length || 0;
    setTdLength(maxLength);
  }, [data]);

  useEffect(() => {
    setFileType(taskData?.fileName.split('.').pop() || '');
  }, [taskData]);

  useEffect(() => {
    if (fileType) {
      getData();
    }
  }, [fileType, fileOption]);

  useEffect(() => {
    const targets = segmentContent
      .flatMap((item) => [item.source, item.target])
      .filter((t) => t);
    const isSameElements = _.isEqual(
      [...targets].sort(),
      [...columnHeaders].sort()
    );

    if (segmentContent.length < columnHeaders.length && !isSameElements) {
      setIsSegmentAdd(true);
    } else {
      setIsSegmentAdd(false);
    }

    const handleClickOutside = (event: Event) => {
      if (
        scrollRef.current &&
        !scrollRef.current.contains(event.target as Node)
      ) {
        // 드롭다운 내부의 클릭인지 확인
        const isDropdownClick = segmentContent.some(
          (segment) =>
            segment.sourceOpen ||
            segment.targetOpen ||
            segment.limitOpen ||
            segment.option.some((opt) => opt.optionOpen || opt.forOpen)
        );

        // 드롭다운 외부 클릭 시에만 상태를 초기화
        if (!isDropdownClick) {
          setSegmentContent(
            segmentContent.map((item) => ({
              ...item,
              sourceOpen: false,
              targetOpen: false,
              limitOpen: false,
              option: item.option.map((opt) => ({
                ...opt,
                optionOpen: false,
                forOpen: false
              }))
            }))
          );
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [segmentContent, columnHeaders]);

  return (
    <div css={TaskCreateStyle}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <div className="title">
        <TPHeadlineBasic decoration type="h2">
          세그먼트 생성
        </TPHeadlineBasic>
      </div>
      <div className="filebox">
        <div className="filebox--title">
          <span className="filebox--status">
            {renderRowStatus(taskData?.status)}
          </span>
          <p className="filebox--language">
            <span>
              {LANGUAGE.filter((v) => v.code === taskData?.startLanguage).map(
                (lang) => lang.label
              )}
            </span>
            <span className="arrow">
              <IconArrowRightBlack />
            </span>
            <span>
              {LANGUAGE.filter(
                (v) => v.code === taskData?.destinationLanguage
              ).map((lang) => lang.label)}
            </span>
          </p>
        </div>
        <div className="filebox--content">{taskData?.taskTitle}</div>
      </div>
      <div className="segment--wrap">
        <div className="modal--div--wrapper">
          <strong>작업 파일</strong>
          <div className="fileInfo">
            <span>{fileType}</span>
            <p>{taskData?.fileName}</p>
          </div>
        </div>
        <div className="modal--div--wrapper">
          <button
            className="sheet--close"
            onClick={() => setSheetClose(!sheetClose)}
          >
            {sheetClose ? (
              <IconArrowDownRadiusWhite />
            ) : (
              <IconArrowUpRadiusWhite />
            )}
          </button>
          <strong>
            파일 미리보기 <span>(10줄 까지만 제공)</span>
          </strong>
          {fileType === 'xlsx' && (
            <div className={`sheet--wrap ${sheetClose ? 'close' : ''}`}>
              <button onClick={() => setFileSheetOpen(!fileSheetOpen)}>
                {selectedSheet.sheetIndex ? (
                  <>
                    {selectedSheet.isHidden && (
                      <span className="sheet--hidden">
                        <IconSheetHidden />
                      </span>
                    )}
                    #{selectedSheet.sheetIndex} {selectedSheet.sheetName}
                  </>
                ) : (
                  '시트를 선택해주세요.'
                )}
              </button>
              {fileSheetOpen && (
                <ul>
                  {fileSheet.map((sheet: ISheet) => (
                    <li
                      key={sheet.key}
                      className={`${sheet.isHidden ? 'hidden' : ''}`}
                      onClick={() => {
                        handleSelectSheet(sheet);
                      }}
                    >
                      #{`${sheet.sheetIndex} `}
                      {sheet.sheetName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <div className={`modal--div--checkbox ${sheetClose ? 'close' : ''}`}>
            {filteredSegments.map((item) => (
              <TPInputCheckSingleBoxBasic
                key={item.type}
                option={item.text}
                segmentList={item}
                optionKey={item.type}
                onClick={() => {
                  handleInput(item.type);
                }}
                checked={item.checked}
              />
            ))}
          </div>
        </div>

        <div
          ref={scrollRef}
          className={`modal--div--box ${sheetClose ? 'close' : ''}`}
        >
          <table
            className={`modal--table--table ${tdLength > 4 ? 'more' : ''}`}
          >
            <thead>
              <tr>
                {columnHeaders.map((header, i) => (
                  <td
                    key={i}
                    className={`title ${
                      tdLength === 1
                        ? 'one'
                        : tdLength === 2
                        ? 'two'
                        : tdLength === 3
                        ? 'three'
                        : tdLength === 4
                        ? 'four'
                        : 'more'
                    }`}
                  >
                    {header}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {_.map(data, (item: any, trIdx: number) => (
                <tr key={trIdx}>
                  {_.map(item, (v: any, tdIdx: number) => (
                    <td
                      key={`${trIdx}-${tdIdx}`}
                      className={
                        tdLength === 1
                          ? 'one'
                          : tdLength === 2
                          ? 'two'
                          : tdLength === 3
                          ? 'three'
                          : tdLength === 4
                          ? 'four'
                          : 'more'
                      }
                    >
                      {v}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal--div--bottom">
          <div className="title--box">
            {isSegmentAdd && (
              <div className="segment--title">
                세그먼트 생성 <button onClick={handleAddSegment}>추가</button>
              </div>
            )}
            <div className="segment--reset">
              <button onClick={handleResetAlert}>초기화</button>
            </div>
          </div>
          {segmentContent.map((item, index) => (
            <div key={index} className="segment--box">
              <div className="title--box">
                <div className="title">
                  <div>
                    Source<span className="required">*</span>
                  </div>
                  <div className="target--info">
                    Target
                    <SimpleTooltip
                      message="Target 열 미지정 시 Source 열에 번역 결과가
                      덮어씌워집니다."
                    >
                      <IconInfoBlack width={18} height={18} />
                    </SimpleTooltip>
                  </div>
                  <div>Target text length limit</div>
                </div>
              </div>
              <div className="segment--box--content">
                <div className="selectbox large">
                  <button onClick={() => toggleDropdown(index, 'source')}>
                    {item.source ? item.source : '열선택'}
                  </button>
                  {item.sourceOpen && (
                    <ul className="select--list">
                      {sourceList.map((column, colIndex) => (
                        <li
                          key={colIndex}
                          className={
                            segmentContent.some(
                              (segment) => segment.source === column
                            )
                              ? 'dim'
                              : ''
                          }
                          onClick={() => {
                            if (
                              !segmentContent.some(
                                (segment) => segment.source === column
                              )
                            ) {
                              handleSelectChange(index, 'source', column);
                            }
                          }}
                        >
                          {column}
                        </li>
                      ))}
                    </ul>
                  )}
                  {!item.source && (
                    <p className="warning">* Source가 선택되지 않았습니다.</p>
                  )}
                </div>
                <span>-</span>
                <div className="selectbox large">
                  <button
                    className={item.source ? '' : 'disabled'}
                    onClick={() => {
                      if (item.source) {
                        toggleDropdown(index, 'target');
                      }
                    }}
                  >
                    {item.target ? item.target : '열선택'}
                  </button>
                  {item.targetOpen && (
                    <ul className="select--list">
                      <li
                        onClick={() => handleSelectChange(index, 'target', '')}
                      >
                        선택안함
                      </li>
                      {orderList.map((column, colIndex) => (
                        <li
                          key={colIndex}
                          className={
                            segmentContent.some(
                              (segment) =>
                                segment.source === column ||
                                segment.target === column
                            )
                              ? 'dim'
                              : ''
                          }
                          onClick={() => {
                            if (
                              !segmentContent.some(
                                (segment) =>
                                  segment.source === column ||
                                  segment.target === column
                              )
                            ) {
                              handleSelectChange(index, 'target', column);
                            }
                          }}
                        >
                          {column}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="selectbox large">
                  <button
                    className={item.source ? '' : 'disabled'}
                    onClick={() => {
                      if (item.source) {
                        toggleDropdown(index, 'limit');
                      }
                    }}
                  >
                    {item.limit ? item.limit : '열선택'}
                  </button>
                  {item.limitOpen && (
                    <ul className="select--list">
                      <li
                        onClick={() => handleSelectChange(index, 'limit', '')}
                      >
                        선택안함
                      </li>
                      {orderList.map((column, colIndex) => (
                        <li
                          key={colIndex}
                          className={
                            segmentContent.some(
                              (segment) => segment.source === column
                            )
                              ? 'dim'
                              : ''
                          }
                          onClick={() => {
                            if (
                              !segmentContent.some(
                                (segment) => segment.source === column
                              )
                            ) {
                              handleSelectChange(index, 'limit', column);
                            }
                          }}
                        >
                          {column}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="option--box">
                {item.option.length > 0 && (
                  <div className="option--title">Column settings</div>
                )}
                {item.option.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <div className="selectbox large">
                      <button
                        onClick={() =>
                          toggleDropdown(index, 'option', optionIndex)
                        }
                      >
                        {option.option ? option.option : '열선택'}
                      </button>
                      {option.optionOpen && (
                        <ul className="select--list">
                          <li
                            onClick={() =>
                              handleSelectChange(
                                index,
                                'option',
                                '',
                                'option',
                                optionIndex
                              )
                            }
                          >
                            선택안함
                          </li>
                          {optionKeyList.map((column, colIndex) => (
                            <li
                              key={colIndex}
                              onClick={() =>
                                handleSelectChange(
                                  index,
                                  'option',
                                  column,
                                  'option',
                                  optionIndex
                                )
                              }
                            >
                              {column}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <span>-</span>
                    <div className="selectbox large">
                      <button
                        className={option.option ? '' : 'disabled'}
                        onClick={() => {
                          if (option.option) {
                            toggleDropdown(index, 'for', optionIndex);
                          }
                        }}
                      >
                        {option.for ? option.for : '열선택'}
                      </button>
                      {option.forOpen && (
                        <ul className="select--list">
                          <li
                            onClick={() =>
                              handleSelectChange(
                                index,
                                'for',
                                '',
                                'for',
                                optionIndex
                              )
                            }
                          >
                            선택안함
                          </li>
                          {orderList.map((column, colIndex) => (
                            <li
                              key={colIndex}
                              onClick={() =>
                                handleSelectChange(
                                  index,
                                  'for',
                                  column,
                                  'for',
                                  optionIndex
                                )
                              }
                            >
                              {column}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <button
                      className="option--delete"
                      onClick={() => handleDeleteOption(index, optionIndex)}
                    >
                      <IconCloseWhite />
                    </button>
                  </div>
                ))}
              </div>
              <div className="option--add">
                <span onClick={() => handleAddOption(index)}>옵션 추가</span>
              </div>
              {segmentContent.indexOf(item) > 0 && (
                <button
                  className="segment--delete"
                  onClick={() => handleDeleteSegment(index)}
                >
                  <IconCloseWhite />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={3}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="등록하기"
            fullWidth
            onClick={handleUploadModal}
            size={'large'}
            disabled={
              fileType === 'xlsx'
                ? selectedSheet.sheetName === '' ||
                  segmentContent.some((item) => item.source === null)
                : segmentContent.some((item) => item.source === null)
            }
          />
        </Box>
      </Box>
    </div>
  );
};

export default TaskCreatePage;
