import { css } from '@emotion/react';

export const CalendarWrap = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  .rdp {
    margin: 0;
  }
  .top {
    position: relative;
    height: 40px;
    .close-button {
      position: absolute;
      top: 6px;
      right: 12px;
    }
  }
  .middle {
    /* width: 756px; */
    /* min-height: 421px; */
  }

  .bottom {
    position: relative;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    .timeValidation {
      line-height: 1.66;
      text-align: left;
      margin: 3px 14px 0;
      font-size: 0.75rem;
      color: #e6311a;
      &.leftSide {
        margin-left: 0;
      }
    }
    .select-Startline-Time,
    .select-Deadline-Time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 451px;
      position: relative;

      &:has(.content:focus) {
        border-color: #9b51e0;
        background-color: transparent;
      }

      .IconWatch {
        margin-left: 12px;
        width: 15px;
        height: 15px;
        opacity: 0.5;
      }
      .IconInputCheck {
        width: 12px;
        height: 20px;
        & * {
          fill: #646782;
        }
      }
      .content {
        border: 1px solid transparent;
        width: 100%;
        margin: 0 8px;
        font-size: 14px;
        height: 32px;
        padding: 0 8px;
        color: #727272;
      }
      .Icon {
        width: 24px;
        height: 24px;
        font-size: 20px;
      }
      span {
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 200px;
      }

      &:focus {
        border-color: #9b51e0;
      }
    }
  }
  .buttonWrap {
    padding: 0 32px;
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 30px;
    .cancelButton button {
      width: 352px;
      min-height: 42px;
      height: 42px;
    }
    .confirmButton button {
      width: 352px;
      min-height: 42px;
      height: 42px;
    }
  }

  /* 제발 단일 데이터픽은 여기에 쓰자! */
  &.single {
    width: 378px;
    .close-button {
      position: absolute;
      top: 6px;
      right: 12px;
    }
    /* .rdp-day_selected {
      border-radius: 2px !important;
      ::before {
      }
    } */
    /* .rdp-day_selected.rdp-day_today::before {
      background-color: #fff !important;
    } */
    .middle {
      /* width: 456px; */
    }
    .bottom {
      padding: 37px 32px 29px;
      .select-Deadline-Time {
        border: 1px solid #d6d6d6;
        margin-left: 0;
      }
    }
    .buttonWrap {
      justify-content: space-between;
      margin-bottom: 32px;
    }
    .rdp-nav_button {
      border-radius: 0 !important;
      :hover {
        background-color: #9b51e014;
      }
    }
    div.rdp-months {
      .rdp-month {
        .rdp-caption {
          > .rdp-caption_label {
            /* margin-left: 44px; */
          }
          .rdp-nav_button_previous {
            right: auto;
            left: 40px;
            top: 50px;
            width: 24px;
            height: 24px;
          }
          .rdp-nav_button_next {
            left: 318px;
            top: 50px;
            width: 24px;
            height: 24px;
            fill: transparent;
          }
        }
      }

      .rdp-table .rdp-tbody .rdp-row .rdp-cell {
        .rdp-day_today::before {
          /* background-color: white; */
        }
        .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected {
          color: #fff;
          span:first-of-type {
            color: #fff;
          }
          background-color: #9b51e0;
        }
      }
    }
  }
  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 2px;
  }
  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-bottom-right-radius: 2px;
  }
  .selectTitle {
    display: flex;
    align-items: center;
    padding-left: 4px;
    width: calc(100% - 31px);
    height: 32px;
  }
  .selectList {
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    padding: 4px;
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 1;
    > li {
      padding: 4px 10px;
      text-align: left;
    }
  }
`;
export const CalendarStyle = css`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: center;

  .rdp-caption {
    justify-content: center;
  }

  div.rdp-months {
    justify-content: center;
    color: #2c3558;
    border-radius: 2px;
    margin: auto;

    .rdp-month.rdp-caption_start {
      margin-right: 20px;
    }
    .rdp-month.rdp-caption_end {
      margin-left: 20px;
    }
    .rdp-month {
      .rdp-caption {
        height: 45px;
        margin-bottom: 40px;
        display: flex;
        > .rdp-caption_label {
          display: block;
          font-size: 32px;
          line-height: 45px;
          padding: 0;
        }
      }

      .rdp-month.rdp-caption_start.rdp-caption {
      }
      .rdp-month.rdp-caption_end.rdp-caption {
      }

      .rdp-table {
        width: 280px;
        max-width: none;
        display: flex;
        flex-direction: column;
        .rdp-head {
          .rdp-head_row {
            margin: 4px 0;
            display: flex;
            justify-content: space-between;
          }
          .rdp-head_cell {
            text-transform: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
          }
          .rdp-head_cell:nth-of-type(1) {
            color: red;
          }
        }

        .rdp-tbody {
          /* width: 10rem; */
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .rdp-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            .rdp-cell {
              font-size: 14px;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              .rdp-button_reset {
                &.rdp-button {
                  &.rdp-day {
                    color: #242424;
                    border: none;
                    :hover {
                      background-color: rgba(155, 81, 224, 0.08);
                    }
                    :active {
                      border: none;
                    }
                    &.rdp-day_outside {
                      color: #9a9cb5;
                    }
                    &.rdp-day_selected {
                      //color: #2c3558;
                      :active {
                        border: none;
                      }
                      &.rdp-day_range_start {
                        color: #ffffff;
                        background-color: #9b51e0;
                        border: none;
                        overflow: visible;
                        z-index: 2;
                        position: relative;
                        transform: rotate(360);
                        border-top-left-radius: 2px;
                        ::before {
                          content: '';
                          position: absolute;
                          border-top-left-radius: 2px;
                          top: 0;
                          left: 20px;
                          /* width: 30px; */
                          height: 40px;
                          background-color: #f7f1fd;
                          z-index: -1;
                        }
                        ::after {
                          content: '';
                          position: absolute;
                          top: 0;
                          right: 0;
                          width: 40px;
                          border-radius: 2px;
                          height: 40px;
                          background-color: #9b51e0;
                          z-index: -1;
                        }
                        &.rdp-day_range_end {
                          &::before {
                            content: none;
                          }
                          &::after {
                            content: none;
                          }
                        }
                      }
                      &.rdp-day_range_middle {
                        background-color: #f7f1fd;
                        overflow: visible;
                        color: #242424;
                        span {
                          z-index: 2;
                        }
                        ::after {
                          content: '';
                          position: absolute;
                          top: 0;
                          right: 40px;
                          width: 8px;
                          height: 40px;
                          background-color: #f7f1fd;
                        }
                        /* 639 issue */
                        /* ::before {
                          content: '';
                          position: absolute;
                          top: 0;
                          left: 0px;
                          width: 8px;
                          height: 8px;
                          background-color: #f7f1fd;
                        } */
                      }
                      &.rdp-day_range_end {
                        color: #ffffff;
                        background-color: #9b51e0;
                        border: none;
                        overflow: visible;
                        border-bottom-right-radius: 2px;
                        ::before {
                          content: '';
                          position: absolute;
                          border-radius: 0 2px 2px 0;
                          top: 0;
                          right: 20px;
                          height: 40px;
                          background-color: #f7f1fd;
                          z-index: -1;
                        }
                        ::after {
                          content: '';
                          position: absolute;
                          top: 0;
                          right: 0;
                          width: 40px;
                          border-radius: 2px;
                          height: 40px;
                          background-color: #9b51e0;
                          z-index: -1;
                        }
                      }
                      &.rdp-day_today {
                        &.rdp-day_range_start {
                          border-top-left-radius: 2px;
                          ::before {
                            content: '';
                            position: absolute;
                            border-radius: 100%;
                            top: 0;
                            right: 0;
                            width: 4.5px;
                            height: 4.5px;
                            background-color: #fff;
                            transform: translate(8px, 8px);
                          }
                          ::after {
                            content: none !important;
                          }
                        }
                        &.rdp-day_range_end {
                          border-top-left-radius: 2px;
                          ::before {
                            content: '';
                            position: absolute;
                            border-radius: 100%;
                            top: 0;
                            right: 16px;
                            width: 4.5px;
                            height: 4.5px;
                            z-index: 1;
                            background-color: #fff;
                            transform: translate(8px, 8px);
                          }
                          ::after {
                            content: none !important;
                          }
                        }
                        &.rdp-day_range_start.rdp-day_range_end {
                          ::before {
                            content: '';
                            position: absolute;
                            border-radius: 100%;
                            top: 0;
                            right: 0;
                            width: 4.5px;
                            height: 4.5px;
                            background-color: #fff;
                            transform: translate(8px, 8px);
                          }
                          ::after {
                            content: none !important;
                          }
                        }
                      }
                    }
                    &.rdp-day_selected.rdp-day_outside {
                      border: none;
                    }
                  }
                }
              }

              .rdp-day_today {
                font-weight: 400;
                ::before {
                  content: '';
                  width: 4.5px;
                  height: 4.5px;
                  background-color: #9b51e0;
                  border-radius: 50%;
                  position: absolute;
                  top: 8px;
                  right: 8px;
                  z-index: 1;
                }
                ::after {
                  content: none;
                }

                &.rdp-day_range_start,
                &.rdp-day_range_end {
                  ::before {
                    content: '' !important;
                    width: 4.5px;
                    height: 4.5px;
                    background-color: #9b51e0;
                    border-radius: 50%;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    z-index: 1;
                  }
                  ::after {
                    content: none !important;
                  }
                }
              }

              .rdp-weeknumber,
              .rdp-day {
                border-radius: 0;
              }
            }
          }
        }
      }
      /* .css-1fuf1dn-CalendarStyle div.rdp-months .rdp-month .rdp-table .rdp-tbody .rdp-row .rdp-cell .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start */

      .rdp-nav_button {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 150px;
        border: none;
        :hover {
          border: none;
          background-color: #f7f1fd;
        }
      }
      .rdp-nav_button_previous {
        right: 28px;
      }
      .rdp-nav_button_next {
        left: 28px;
      }
      .rdp-nav_icon {
        color: #646782;
        width: 100%;
        height: 2.667rem;
      }
    }
  }
`;

export const TimeSelectorWrap = css`
  position: absolute;
  left: 0;
  bottom: 52px;
  border: 1px solid #d5d3e4;
  border-radius: 2px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 9;
  .item {
    padding: 8px 24px;
    font-size: 14px;
    text-align: left;
    :hover {
      background-color: #f7f1fd;
    }
  }
`;
