import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { alertWrap } from '@hooks/modal/Modal.style';
import { JobDetailStyle, AssignTheme, SubTaskBox } from './JobDetail.style';
import { Box } from '@mui/material';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import {
  getLanguageLabelByCode,
  getStatus as getTaskStatus
} from '@src/common/config/Code';
import { ThemeProvider } from '@mui/material/styles';
import { useAlert } from '@hooks/modal/useAlert';
import { useGetTask } from '@src/hooks/task/get';
import _ from 'lodash';
import { ITaskDetailRes } from '@src/common/config/ITask';
import { useUpsertTask } from '@src/hooks/task/post';
import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { LoadingButton } from '@mui/lab';
import { Arrow } from '@src/units/sobra/Arrow';
import { Worker } from '@src/units/sobra/Worker';
import { ILanguageSubTaskList } from '@src/common/config/ILanguageTask';
import {
  renderDateTimeNoWrap,
  renderRowStatus,
  renderTaskType
} from '../dashboard/utils/render';
import { LanguageTaskMakerItem } from './LanguageTaskMakerItem.Detail';

import { styled as MuiStyled } from '@mui/material/styles';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import RowStatusBadge from '../components/RowStatusBadge.Detail';
import { usePostLanguageSubTask } from '@src/hooks/languageTask/post';
import { TPSelectFile } from '@src/units/select/TPSelect.file';
import { useDeleteLanguageSubTask } from '@src/hooks/languageTask/deleteTask';
import { AxiosResponse } from 'axios';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { is } from 'date-fns/locale';

//todo
const order = ['TRANSLATOR', 'FIRST_CHECKER', 'SECOND_CHECKER'];

/**
 * 프로젝트 상세정보 모달 페이지
 * route : /detail/:id
 * @returns
 */
function JobDetailPage(props: any) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let { id, jobDetailId } = useParams();
  if (id === undefined) id = window.history.state.projectId;
  if (jobDetailId === undefined) jobDetailId = window.history.state.taskId;

  const { taskDetail, isLoading } = useGetTask(jobDetailId);

  const { putLanguageTask, putFileStatusTask } = usePostLanguageSubTask();
  const { deleteTaskAlert } = useDeleteLanguageSubTask();
  const { deleteTask } = useUpsertTask();
  const { openHandle: alertHandle } = useAlert();

  const { myInfo } = useMyInfo();
  const isPML = myInfo?.roles.includes('PML');

  const closeModal = () => {
    const { state } = window.history;
    if (state?.projectId && state?.taskId) {
      window.history.replaceState({}, '');
      props?.setOpenedJobDetailPage?.(false);
    } else {
      const regExp = /\/detail\/\d+$/;
      if (regExp.test(pathname)) {
        navigate(pathname.replace(regExp, ''));
      } else {
        navigate(-1);
      }
    }
  };
  const deleteWork = () => {
    alertHandle({
      title: '작업 삭제',
      text: '한 번 삭제된 데이터는 복구가 불가능합니다.',
      ok: () => {
        const { state } = window.history;
        if (state?.projectId && state?.taskId) {
          jobDetailId && deleteTask(jobDetailId);
          window.history.replaceState({}, '');
          props?.setOpenedJobDetailPage?.(false);
          queryClient.invalidateQueries(['DashboardKeys']);
        } else {
          jobDetailId && deleteTask(jobDetailId);
          navigate(-1);
        }
      },
      cancelLabel: '취소'
    });
  };

  const changeStatus = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    payload: {
      languageTaskId: number;
      languageTaskStatus: string;
    }
  ) => {
    e.stopPropagation();
    alertHandle({
      title: '상태 변경',
      text: '상태를 변경하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        putLanguageTask(payload);
      }
    });
  };

  const modifyWork = (e: any) =>
    navigate(`/project/${id}/modify/${jobDetailId}`);

  if (isLoading) return <LoadingButton loading />;

  return (
    <div css={[JobDetailStyle, alertWrap]} tabIndex={undefined}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <div className="datail-div-type">
        {renderTaskType(taskDetail.taskType)}
      </div>
      <span className="datail-span-title">{taskDetail.taskTitle}</span>
      <div className="datail-div-content">
        <div className="content-div-infoBox">
          <div className="infoBox-row">
            {renderRowStatus(taskDetail.taskStatus)}
            <div>{taskDetail.taskKey}</div>
          </div>
        </div>

        <div className="content-div-language">상세 정보</div>
        <div className="content-div-info">
          <div className="title">총 분량</div>
          <div
            style={{
              display: 'flex',
              fontSize: '14px'
            }}
          >
            {taskDetail.taskType === 'LQA' ? (
              <>
                {Math.floor(taskDetail.totalGrossAmount / 250)}
                시간 {taskDetail.totalGrossAmount % 250 > 0 ? '30' : '00'}분
              </>
            ) : (
              <>
                <div className="name tm amount">Gross</div>
                {taskDetail.totalGrossAmount}
                <div
                  style={{
                    width: '2px',
                    height: '2px',
                    backgroundColor: '#CBCBCB',
                    margin: '8px'
                  }}
                />
                <div className="name tm amount">Net</div>
                {taskDetail.totalNetAmount}
              </>
            )}
          </div>

          <div className="dates">
            <div className="content">
              <div className="item">
                <div className="title">시작일</div>
                <div className="value">
                  {renderDateTimeNoWrap(taskDetail?.startDateTime, false)}
                </div>
              </div>
              <div className="item">
                <div className="title">마감일</div>
                <div className="value">
                  {renderDateTimeNoWrap(
                    taskDetail?.endDateTime,
                    !['DELIVERY_COMPLETE'].includes(
                      taskDetail?.taskStatus ?? ''
                    )
                  )}
                </div>
              </div>
              <div className="item">
                <div className="title">납품일</div>
                <div className="value">
                  {renderDateTimeNoWrap(
                    taskDetail?.hopeSupplyDateTime,
                    !['DELIVERY_COMPLETE'].includes(
                      taskDetail?.taskStatus ?? ''
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="info-div-text description">
            <div className="title">설명</div>
            <div className="content">{taskDetail.description}</div>
          </div>

          <div className="info-div-text">
            <div className="title">링크</div>
            <div className="content">
              {Array.isArray(taskDetail.linkList) &&
                taskDetail.linkList.map((e, i) => (
                  <a key={i} href={e} target="_blank" rel="noreferrer">
                    {e}
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="content-div-line" />
        <div className="content-div-language bottom">언어 작업</div>
        <TaskComponent
          taskDetail={taskDetail}
          changeStatus={changeStatus}
          putFileStatusTask={putFileStatusTask}
          deleteTaskAlert={deleteTaskAlert}
          setOpenedJobDetailPage={props?.setOpenedJobDetailPage}
        />
      </div>
      <div
        style={{
          padding: '0 20px'
        }}
      >
        {[
          'NEW',
          'COMPLETE_ASSIGN',
          'PROGRESS',
          'COMPLETE',
          ...(isPML ? ['DELIVERY_COMPLETE'] : [])
        ].includes(taskDetail.taskStatus ?? '') ? (
          <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={8}>
            {!['COMPLETE', ...(isPML ? ['DELIVERY_COMPLETE'] : [])].includes(
              taskDetail.taskStatus ?? ''
            ) ? (
              <Box flexGrow={1}>
                <TPButtonBasic
                  variant="outlined"
                  label="삭제"
                  fullWidth
                  onClick={deleteWork}
                  disabled={false}
                  size={'large'}
                />
              </Box>
            ) : null}
            <Box flexGrow={6}>
              <TPButtonBasic
                variant="contained"
                label="수정"
                fullWidth
                disabled={false}
                size={'large'}
                onClick={modifyWork}
              />
            </Box>
          </Box>
        ) : null}
      </div>
    </div>
  );
}

const TaskComponent = ({
  taskDetail,
  changeStatus,
  putFileStatusTask,
  deleteTaskAlert,
  setOpenedJobDetailPage
}: {
  taskDetail: ITaskDetailRes;
  changeStatus: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    payload: {
      languageTaskId: number;
      languageTaskStatus: string;
    }
  ) => void;
  putFileStatusTask: UseMutateFunction<
    AxiosResponse<any, any>,
    any,
    any,
    unknown
  >;
  deleteTaskAlert: ({ id }: { id: string | number }) => void;
  setOpenedJobDetailPage: (value: boolean) => void;
}) => {
  const { languageTaskList, totalNetAmount } = taskDetail;
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { myInfo } = useMyInfo();
  const isPML = myInfo?.roles.includes('PML');

  return (
    <ThemeProvider theme={AssignTheme}>
      {_.map(languageTaskList, (el, idx) => (
        <Accordion defaultExpanded={true} key={`taskAccordion${idx}`}>
          <Box
            component={AccordionSummary}
            display={'flex'}
            alignItems={'center'}
          >
            <Box
              component={'dl'}
              display={'inline-flex'}
              alignItems={'center'}
              gap={1}
            >
              <Box
                component={'dt'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  minWidth: '70px',
                  width: '100%'
                }}
              >
                {/* {renderRowStatus(el.languageTaskStatus)} */}
                <RowStatusBadge
                  statusCode={el.languageTaskStatus}
                  {...{
                    open,
                    setOpen
                  }}
                >
                  {el?.languageTaskStatus === 'COMPLETE' && (
                    <div
                      style={{
                        textAlign: 'center'
                      }}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) =>
                        changeStatus(e, {
                          languageTaskId: el.languageTaskId,
                          languageTaskStatus: 'DELIVERY_COMPLETE'
                        })
                      }
                    >
                      납품완료
                    </div>
                  )}
                </RowStatusBadge>
              </Box>
              <Box
                component={'dd'}
                textOverflow={'ellipsis'}
                flexBasis={100}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
                paddingRight={1}
                minWidth={'200px'}
              >
                <div
                  className="language-pair"
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    fontSize: '14px',
                    width: 'max-content'
                  }}
                >
                  {getLanguageLabelByCode(el.startLanguage)}
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    style={{ marginBottom: '2px' }}
                  >
                    <path
                      d="M1 8.99935L5.24264 4.75671M1 1.35938L5.24264 5.60202"
                      stroke="currentColor"
                      strokeWidth="1.2"
                    />
                  </svg>
                  {getLanguageLabelByCode(el.destinationLanguage)}
                </div>
              </Box>
            </Box>
            {(el.languageTaskStatus !== 'DELIVERY_COMPLETE' ||
              (el.languageTaskStatus === 'DELIVERY_COMPLETE' && isPML)) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: '10px',
                  fontSize: '12px',
                  fontWeight: '700'
                }}
              >
                <Link
                  to={`${
                    !id
                      ? `/dashboard/assignWorker/${el.languageTaskId}`
                      : `/project/${id}/assignWorker/${el.languageTaskId}`
                  }`}
                  style={{ color: '#9B51E0' }}
                  onClick={() => {
                    setOpenedJobDetailPage(false);
                  }}
                >
                  작업자 배정
                </Link>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <TPSelectFile
                selectOptions={el.fileRegisterStatus}
                data={{
                  isBoracatUseOrNot: el?.isBoracatUseOrNot ?? false,
                  languageTaskStatus: el?.languageTaskStatus ?? 'NEW'
                }}
                changeSelect={(state: boolean) =>
                  putFileStatusTask({
                    languageTaskId: el.languageTaskId,
                    fileRegisterStatus: state
                  })
                }
              />

              {languageTaskList.length > 1 &&
                ['NEW', 'COMPLETE_ASSIGN'].includes(
                  el?.languageTaskStatus ?? ''
                ) && (
                  <TPButtonDelete
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteTaskAlert({ id: el.languageTaskId });
                    }}
                  />
                )}
            </Box>
          </Box>
          <LanguageTaskMakerItem
            languageTaskTitle={''}
            isBoracatUse={el?.isBoracatUseOrNot ?? false}
            tmMatch={taskDetail?.tmMatch ?? false}
            taskType={taskDetail?.taskType ?? 'TRANSLATION'}
            taskAmount={taskDetail.taskAmountList}
            languageTaskAmount={el.languageTaskAmount ?? {}}
            grossAmount={taskDetail.totalGrossAmount}
            netAmount={taskDetail.totalNetAmount}
            endDateTime={taskDetail.endDateTime}
            hopeSupplyDateTime={taskDetail.hopeSupplyDateTime}
            key={el.languageTaskId}
            startDateTime={taskDetail.startDateTime}
            onDelete={() => {}}
            onMakeDisabled={() => {}}
            {...el}
          />
        </Accordion>
      ))}
    </ThemeProvider>
  );
};

const Accordion = MuiStyled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: `8px solid transparent`
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = MuiStyled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <div
        style={{
          border: '2px solid #ECECEC',
          borderRadius: '50%',
          width: 18,
          height: 18,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ArrowForwardIosSharpIcon
          sx={{
            fontSize: '0.7rem',
            width: '3rem',
            height: '0.7rem'
          }}
        />
      </div>
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    '& > div': {
      backgroundColor: '#242424',
      border: '2px solid #242424 !important',
      color: '#fff'
    }
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& > div:hover': {
      backgroundColor: '#242424',
      border: '2px solid #242424 !important',
      color: '#fff'
    }
  },
  padding: '0 16px',
  '& .MuiAccordionSummary-content': {
    marginLeft: '10px !important',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

interface TPButtonCloseProps extends React.HTMLAttributes<HTMLDivElement> {}

function TPButtonDelete({ ...props }: TPButtonCloseProps) {
  return (
    <TPButtonCloseWrap className="circle button close" {...props}>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.86987 0.3584L4.14751 3.08076L1.42515 0.3584L1.31909 0.252335L1.21302 0.358401L0.505914 1.06551L0.399848 1.17157L0.505913 1.27764L3.22827 4L0.602589 6.62569L0.496523 6.73175L0.602589 6.83782L1.3097 7.54492L1.41576 7.65099L1.52183 7.54493L4.14751 4.91924L6.86987 7.6416L6.97594 7.74767L7.08201 7.6416L7.78911 6.93449L7.89518 6.82843L7.78911 6.72236L5.06675 4L7.78911 1.27764L7.89518 1.17157L7.78911 1.06551L7.08201 0.3584L6.97594 0.252334L6.86987 0.3584Z"
          strokeWidth="0.3"
        />
      </svg>
    </TPButtonCloseWrap>
  );
}

const TPButtonCloseWrap = styled.div`
  --white: #ffffff;
  --black: #242424;

  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: var(--black);
    fill: var(--black);
  }
`;

export default JobDetailPage;
