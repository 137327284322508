import api from '@api/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fileManagementKeys } from '@hooks/queryKeyFactory';
import _ from 'lodash';
import { useAlert } from '../modal/useAlert';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../modal/useToast';
import { useState } from 'react';

interface ISplitInfo {
  splitList: {
    order: number;
    startSerial: number;
    endSerial: number;
    wordCount: number;
    segmentCount: number;
  }[];
}

interface ISplitPost {
  languageTaskId: number;
  fileSplitRange: { order: number; startSerial: number; endSerial: number }[];
}

export const useSplitInfo = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const reDate = async (languageTaskId: number) => {
    const url = `/stock/split/list?languageTaskId=${languageTaskId}`;
    return await api.getBora(url).then((res) => res.data);
  };

  const getData = async (splitInfo: any): Promise<ISplitInfo> => {
    const url = `/stock/split/contents?languageTaskId=${splitInfo.languageTaskId}&splitType=${splitInfo.splitType}&splitCount=${splitInfo.splitCount}`;
    return await api.getBora(url).then((res) => res.data);
  };

  const changeData = async (splitDetail: any): Promise<ISplitInfo> => {
    const url = `/stock/split/detail?languageTaskId=${splitDetail.languageTaskId}&order=${splitDetail.order}&startSerial=${splitDetail.startSerial}&endSerial=${splitDetail.endSerial}`;
    return await api.getBora(url).then((res) => res.data);
  };

  const deleteData = async (languageTaskId: number) => {
    await api.deleteBora(`/stock/split/${languageTaskId}`, {
      timeout: 360000,
      signal: AbortSignal.timeout(360000)
    });
  };

  const putData = async (splitInfo: ISplitPost) => {
    setIsLoading(true);
    try {
      await api.putBora(`/stock/split`, splitInfo, {
        timeout: 360000,
        signal: AbortSignal.timeout(360000)
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { data = [] } = useQuery(
    [...fileManagementKeys.list],
    ({ queryKey }) => {
      const languageTaskId = parseInt(queryKey[1] as string); // queryKey에서 languageTaskId를 string으로 추정하고 number로 변환
      return reDate(languageTaskId);
    },
    {
      enabled: false
    }
  );

  const { mutate: putSplit } = useMutation({
    mutationFn: putData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/project']);
      queryClient.invalidateQueries(['/taskManager']);
      openHandle({
        text: `작업 파일이 분할되었습니다`,
        severity: 'success'
      });
      navigate('/taskManager', { replace: true });
    },
    onError: () => {
      openHandle({
        text: `작업 파일이 분할되지 않았습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  const closeModal = () => {
    alertHandle({
      title: '파일 분할 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/taskManager', { replace: true });
      }
    });
  };

  return {
    data,
    isLoading,
    reDate,
    getData,
    changeData,
    putSplit,
    deleteData,
    closeModal
  };
};
