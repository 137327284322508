import {
  STATUS_COLOR,
  getStatus,
  getTaskStatusLabel,
  getTaskType
} from '@common/config/Code';
import { Box } from '@mui/material';
import { IcoMenuChevronDown, IconInfoBlack } from '@resources/icon';
import { useGetWorkerList } from '@src/hooks/languageTask/getManagerList';
import { useGetFilter } from '@src/hooks/searchFilter';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { TPSelectCheckManager } from '@src/units/select/TPSelect.checkPM';
import TPSelectLangPair from '@src/units/select/TPSelect.langPair';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { renderLanguageByCode } from '../dashboard/utils/render';
// customizing
import { usePostLanguageTaskTabList } from '@src/hooks/languageTask/postList.tab';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import Footer from '@src/layouts/footer/Footer';
import TPButtonClose from '@src/units/button/TPButton.Close';
import TPButtonReset from '@src/units/button/TPButton.Reset';
import { DashBoard } from '../components/DashBoard';
import { LanguageWrap } from './Task.style';
import { css, cx } from '@emotion/css';
import CategoryTable from '../components/CategoryTable/CategoryTable';
// import { TBody } from '../components/CategoryTable/TBody';

function TaskTabPage() {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const { myInfo, isLoading } = useMyInfo();
  const permissions = useMemo(() => {
    return {
      isTL: Boolean(myInfo?.roles?.includes('TL')),
      isLL: Boolean(myInfo?.roles.find((v: any) => ['TL', 'LL'].includes(v)))
    };
  }, [myInfo?.roles]);

  const { filterData, Component: FilterButton } = useGetFilter('TASK_TAB');
  const { pmList, taskerList } = useGetWorkerList(myInfo);
  const [searchValue, setSearchValue] = useState('');
  const {
    languagePairList,
    languageTaskList,
    isLoading: listIsLoading,
    taskFilter,
    setTaskFilter,
    keyword,
    isFilter,
    sortInfo,
    stateSetters,
    refetchLanguageTaskList
  } = usePostLanguageTaskTabList(filterData);
  const {
    setWorkerUser,
    setPmManager,
    setTaskType,
    setTaskStatus,
    setKeyword,
    setLanguagePairList,
    setResetState,
    setIsSort
  } = stateSetters;
  //#endregion

  //#region 상단 count
  const [categoryCounts, setCateGoryCounts] = useState<any>({});
  const dashBoardItemList = useMemo(() => {
    const dashboardCounts: any = {
      NEW: 0,
      COMPLETE_ASSIGN: 0,
      PROGRESS: 0,
      COMPLETE: 0,
      DELIVERY_COMPLETE: 0,
      STOP: 0
    };

    for (const _ in categoryCounts) {
      const e = categoryCounts[_];
      for (const taskStatus in e) {
        const n = e[taskStatus];
        if (taskStatus in dashboardCounts) {
          dashboardCounts[taskStatus] += n;
        } else {
          dashboardCounts[taskStatus] = n;
        }
      }
    }

    const rs: any[] = [];
    for (let label in dashboardCounts) {
      // skip label
      // if ((label as any) === WidgetCode.ALL) continue;
      rs.push({
        icon: label,
        label: getTaskStatusLabel(label),
        count: dashboardCounts[label]
      });
    }
    return rs;
  }, [categoryCounts]);
  //#region 상단 count

  //#필터에서 언어페어 갯수
  const [languagePairCounts, setLanguagePairCounts] = useState<any>({});
  const languagePairNewCountList = useMemo(() => {
    const rs: any = {};
    for (let _ in languagePairCounts) {
      const e = languagePairCounts[_];
      for (let pair in e) {
        const n = e[pair];
        if (pair in rs) {
          rs[pair] += n;
        } else {
          rs[pair] = n;
        }
      }
    }
    return rs;
  }, [languagePairCounts]);
  //#필터에서 언어페어 갯수

  const resetState = () => {
    setResetState();
    setSearchValue('');
  };

  useLayoutEffect(() => {
    if (ref.current && ref2.current) {
      const { height } = ref.current.getBoundingClientRect();
      ref2.current.style.paddingTop = `${height + 56}px`;
      // ref2.current.style.height = Math.max(window.innerHeight, )
    }
  });

  if (!isLoading && !permissions.isLL) throw new Error('권한이 없습니다.');

  return (
    <section css={cx(cssTaskTab)}>
      <Box
        ref={ref}
        sx={{
          position: 'fixed',
          top: '56px',
          left: 0,
          right: 0,
          mx: -5,
          bgcolor: '#fff',
          zIndex: 1052
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: '"IBM Plex Sans", sans-serif',
            textAlign: 'left',
            px: 10,
            pt: 2
          }}
        >
          <Box mb={2}>
            <Box
              sx={{
                color: '#9B51E0',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px'
              }}
            >
              작업
            </Box>
            <Box
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '34px'
              }}
            >
              작업관리
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '22px',
              letterSpacing: '-0.25px',
              mb: 2
            }}
          >
            <Box
              sx={{
                display: permissions.isLL ? 'flex' : 'none',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
                pb: 1,
                cursor: 'pointer',
                borderBottom: '2px solid #242424'
              }}
            >
              <span>언어 작업</span>
              <SimpleTooltip message="화면에 노출되는 언어 작업에 대해서 검색 또는 필터를 적용할 수 있습니다.">
                <IconInfoBlack width={12} height={12} />
              </SimpleTooltip>
            </Box>
            {/* TL 권한체크 */}
            <Box
              sx={{
                display: permissions.isTL ? 'flex' : 'none',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
                pb: 1,
                cursor: 'pointer',
                borderBottom: '2px solid transparent',
                color: '#727272'
              }}
              onClick={() => {
                navigate('/mytasktab');
              }}
            >
              <span>내 작업</span>
              <SimpleTooltip message="화면에 노출되는 내 작업에 대해서 검색 또는 필터를 적용할 수 있습니다.">
                <IconInfoBlack width={12} height={12} fill="#727272" />
              </SimpleTooltip>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            px: '80px'
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              gap: '8px',
              whiteSpace: 'nowrap'
            }}
          >
            <TPSelectCheck
              label="상태"
              useOptionLabel
              optionList={getStatus([
                'ALL',
                'NEW',
                'COMPLETE_ASSIGN',
                'PROGRESS',
                'COMPLETE',
                'DELIVERY_COMPLETE',
                'STOP'
              ])}
              selectOptions={taskFilter.languageTaskStatus}
              changeSelect={setTaskStatus}
            />
            <TPSelectCheck
              label="유형"
              optionList={getTaskType([
                'ALL',
                'TRANSLATION',
                'POLISHING',
                'POST_EDITING',
                'LQA'
              ])}
              selectOptions={taskFilter.taskType}
              changeSelect={setTaskType}
            />

            <TPSelectLangPair
              badgeContent={languagePairList.length}
              languagePairList={languagePairList}
              onChange={(v: any) => {
                setLanguagePairList((o) => {
                  return [...o, v];
                });
              }}
            />

            <TPSelectCheckManager
              label="담당 PM"
              PMList={pmList}
              showAvatar={true}
              selectOptions={taskFilter.projectManagerUserIdList}
              changeSelect={setPmManager}
            />

            <TPSelectCheckManager
              label="작업자"
              PMList={taskerList}
              showAvatar={true}
              selectOptions={taskFilter.workUserIdList}
              changeSelect={setWorkerUser}
            />

            {isFilter && <TPButtonReset onClick={resetState} border />}
            <FilterButton
              getFilterData={{
                taskFilter,
                // keyword,
                languagePairList
              }}
            />
          </Box>
          <TPInputSearchNew
            value={searchValue}
            placeholder="검색"
            onChange={(e) => setSearchValue(e.target.value)}
            clickSearch={() => setKeyword(searchValue)}
          />
        </Box>
        <Box
          sx={{
            mb:
              Array.isArray(languagePairList) && languagePairList.length > 0
                ? 3
                : 0,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1,
            px: '80px'
          }}
        >
          {Array.isArray(languagePairList) &&
            languagePairList.length > 0 &&
            languagePairList.map((item: any, i: number) => {
              const pair = `${item.startLanguage}»${item.destinationLanguage}`;
              const count = languagePairNewCountList[pair] ?? 0;

              return (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#727272',
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: '22.4px',
                    letterSpacing: '-0.25px',
                    bgcolor: count ? '#38D9000f' : '#fafafa',
                    p: '5px 14px'
                  }}
                >
                  <Box>{renderLanguageByCode(item.startLanguage)}</Box>
                  <IcoMenuChevronDown
                    fill="#727272"
                    style={{ rotate: '-90deg' }}
                  />
                  <Box>{renderLanguageByCode(item.destinationLanguage)}</Box>
                  {count > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 15,
                        height: 15,
                        borderRadius: '50%',
                        bgcolor: STATUS_COLOR.NEW,
                        color: '#fff',
                        fontFamily: '"IBM Plex Sans", sans-serif',
                        fontSize: 10,
                        fontWeight: 700,
                        lineHeight: '13px'
                      }}
                    >
                      {count}
                    </Box>
                  )}
                  <TPButtonClose
                    onClick={() => {
                      setLanguagePairList((o) => {
                        return o.filter((v) => {
                          return !(
                            v.startLanguage === item.startLanguage &&
                            v.destinationLanguage === item.destinationLanguage
                          );
                        });
                      });
                    }}
                  />
                </Box>
              );
            })}
        </Box>

        <Box
          sx={{
            '[class*="DashboardWrap"]': {
              px: '80px',
              background: '#f9f9f9'
            }
          }}
        >
          <DashBoard list={dashBoardItemList} />
        </Box>
      </Box>

      <Box
        ref={ref2}
        sx={{
          bgcolor: '#f9f9f9',
          px: '40px',
          width: '1920px',
          paddingBottom: '80px'
        }}
      >
        {Array.isArray(languageTaskList) &&
          languageTaskList.map((item: any, i) => {
            return (
              <CategoryTable
                key={i}
                type={'task'}
                listIsLoading={listIsLoading}
                taskFilter={{ ...taskFilter, languagePairList, keyword }}
                item={item}
                setCateGoryCounts={setCateGoryCounts}
                setLanguagePairCounts={setLanguagePairCounts}
                refetchLanguageTaskList={refetchLanguageTaskList}
              />
            );
          })}
      </Box>
      <Footer />
      <Outlet />
    </section>
  );
}

export default TaskTabPage;

const cssTaskTab = css`
  /* margin: 71px 40px 90px; */
  font-size: 'IBM Plex Sans', sans-serif;
  /* width: minmax(max-content, available); */

  .layout--div--inner {
  }

  .title--languagePair {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 100%;
    .test {
      width: 100%;
      margin: 0 4px;
      overflow: hidden;
    }
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .language--span--refresh {
    height: 2.8rem;
    top: 1rem;

    display: flex;
    align-items: flex-end;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  .project--dl--info {
    display: flex;
    flex-basis: calc(100% - 200px);
    justify-content: center;
    align-items: center;
    font-weight: 500;
    dd {
      font-size: 0.875rem;
      > button {
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 1920px) {
    width: max-content;
  }
`;
