import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { statisticsCustomerKeys } from '@src/hooks/queryKeyFactory';

interface Company {
  companyId: number;
  companyName: string;
}

export function useGetCustomerList() {
  // 고객사 리스트 GET /project/simpleAllList
  const { data: statisticsCustomerList = [] } = useQuery(
    statisticsCustomerKeys.statisticsCustomerList,
    async function () {
      return await api
        .getPms('/searchFilter/selection/company')
        .then((res) => res.data);
    },
    {
      suspense: false
    }
  );

  // 고객사 선택박스옵션 리스트
  const customerList = (() => {
    if (Array.isArray(statisticsCustomerList.companyList)) {
      return statisticsCustomerList.companyList.map((e: Company) => ({
        code: String(e.companyId),
        label: e.companyName
      }));
    } else {
      return [];
    }
  })();

  return {
    customerList
  };
}
