import styled from '@emotion/styled';
import api from '@api/Api';
import { TPSelectBasic } from './TPSelect.basic';
import { TPSelectCheck } from './TPSelect.check';
import {
  LANGUAGE,
  LanguageCodeType,
  getLanguageLabelByCode,
  getLanguageLabelsByCode,
  getTaskType
} from '@src/common/config/Code';
import { IcoMenuChevronDown } from '@src/resources/icon';
import { useEffect, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { cx } from '@emotion/css';
import { useLanguageAll } from '@src/hooks/statistics/common/getLanguageAll';

interface LanguagePair {
  startLanguage: string;
  destinationLanguage: string;
}

interface TPSelectLangPairPMLProps {
  languagePairList: LanguagePair[];
  badgeContent: number;
  languageAll: any;
  onChange: (e: any) => void;
}

export default function TPSelectLangPairPML(props: TPSelectLangPairPMLProps) {
  const [open, setOpen] = useState(false);
  const [openSource, setOpenSource] = useState(false);
  const [openTarget, setOpenTarget] = useState(false);
  const [source, setSource] = useState('');
  const { myInfo } = useMyInfo(); // myInfo에서 languagePairList를 가져옴
  const languagePairList = myInfo?.languagePairList ?? [];
  const languageStart = props.languageAll?.startLanguageList ?? [];
  const languageTarget = props.languageAll?.destinationLanguageList ?? [];
  const targetLanguages = languageTarget.filter(
    (lang: { key: string; value: string }) => lang.value !== source // source와 비교 대상을 value로 변경
  );

  const distinationLanguages = Array.from(
    new Set(
      languagePairList
        .filter(
          (pair: LanguagePair) =>
            pair.startLanguage === source &&
            !props.languagePairList.some(
              (propPair: LanguagePair) =>
                propPair.destinationLanguage === pair.destinationLanguage
            )
        )
        .map((pair: LanguagePair) => pair.destinationLanguage)
    )
  );

  useEffect(() => {
    // 다른 필터 열릴 시 닫히도록
    document.addEventListener(
      'mouseenter',
      (e) => {
        if (
          e.target instanceof HTMLElement &&
          Array.from(e.target.classList).some((e) => /FilterWrap$/g.test(e))
        ) {
          setOpen(false);
          setSource('');
          setOpenSource(false);
          setOpenTarget(false);
        }
      },
      { capture: true }
    );
  }, []);

  const refOpenTimeout = useRef<any>(null);
  const handleClearTimeoutClose = () => {
    if (refOpenTimeout.current) clearTimeout(refOpenTimeout.current);
    refOpenTimeout.current = null;
  };
  const handleSetTimeoutClose = () => {
    handleClearTimeoutClose();
    refOpenTimeout.current = setTimeout(() => {
      setSource('');
      setOpen(false);
      setOpenSource(false);
      setOpenTarget(false);
    }, 300);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
        setOpenSource(false);
        setOpenTarget(false);
      }}
    >
      <TPSelectLangPairWrap>
        <div
          className={cx('bar', {
            open
          })}
          onMouseOver={() => {
            handleClearTimeoutClose();
            setOpen(true);
          }}
          onMouseLeave={() => {
            if (source) return;
            handleSetTimeoutClose();
          }}
        >
          <div>언어 페어 선택하기</div>
          {typeof props.badgeContent === 'number' && props.badgeContent > 0 && (
            <div className="chip">{props.badgeContent}</div>
          )}
          <IcoMenuChevronDown />
        </div>

        <div className={cx('langs', { open })}>
          <div
            className="lang source"
            onMouseOver={() => {
              handleClearTimeoutClose();
              setOpenSource(true);
              setOpenTarget(false);
            }}
            onMouseLeave={() => {
              if (source) return;
              handleSetTimeoutClose();
            }}
          >
            {source ? getLanguageLabelByCode(source) : '출발어'}
            <IcoMenuChevronDown />
          </div>
          <div
            className="lang target"
            onMouseOver={() => {
              if (!source) return;
              setOpenTarget(true);
              setOpenSource(false);
            }}
          >
            도착어 <IcoMenuChevronDown fill="#aaa" />
          </div>
        </div>

        <div className={cx('list', { open })}>
          <div className="source" style={openSource ? {} : { display: 'none' }}>
            {languageStart.map(
              (e: { key: string; value: string }, i: number) => (
                <div
                  key={i}
                  onMouseOver={() => {
                    handleClearTimeoutClose();
                  }}
                  onMouseLeave={() => {
                    if (source) return;
                    handleSetTimeoutClose();
                  }}
                  onClick={() => {
                    handleClearTimeoutClose();
                    setOpenTarget(true);
                    setOpenSource(false);
                    setSource(e.value);
                  }}
                >
                  {getLanguageLabelByCode(e.key)}
                </div>
              )
            )}
          </div>

          <div
            className="target"
            style={
              openTarget &&
              (distinationLanguages.length || targetLanguages.length) > 0
                ? {}
                : { display: 'none' }
            }
          >
            {targetLanguages.map(
              (e: { key: string; value: string }, i: number) => (
                <div
                  key={i}
                  onClick={() => {
                    const fn = props.onChange;
                    if (typeof fn === 'function') {
                      fn({
                        startLanguage: source,
                        destinationLanguage: e.value
                      });
                    }
                    setOpen(false);
                    setOpenTarget(false);
                    setOpenSource(false);
                    setSource('');
                  }}
                >
                  {getLanguageLabelByCode(e.key)}
                </div>
              )
            )}
          </div>
        </div>
      </TPSelectLangPairWrap>
    </ClickAwayListener>
  );
}

const TPSelectLangPairWrap = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;

  cursor: pointer;

  .bar {
    border-radius: 2px;
    padding: 5px 14px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &.open,
    &:hover {
      background-color: #000;
      color: #fff;
      svg {
        fill: #fff;
      }
      .chip {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .chip {
    background-color: #242424;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 15px;
    min-height: 15px;
    padding: 2px 4px;
  }

  .langs {
    z-index: 1060;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #ececec;
    background: linear-gradient(0deg, #ffffff, #ffffff),
      linear-gradient(0deg, #ececec, #ececec);
    box-shadow: 2px 2px 4px 0px #00000014;
    width: 200px;
    height: 48px;
    position: absolute;
    top: 28px;

    &:not(&.open) {
      display: none;
    }

    .lang.source,
    .lang.target {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
    .lang.target {
      color: #aaa;
    }
  }

  .list {
    z-index: 1060;

    &:not(&.open) {
      display: none;
    }

    .source,
    .target {
      display: block;
      border: 1px solid #ececec;
      background: linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(0deg, #ececec, #ececec);
      box-shadow: 2px 2px 4px 0px #00000014;

      width: 148px;
      max-height: 600px;
      height: fit-content;

      position: absolute;
      top: 78px;

      overflow: auto;
      &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
        margin: 50px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(213, 211, 228);
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
        margin-left: 10px;
      }

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 30px;
        &:hover {
          background: #9b51e00f;
        }
      }
    }

    .source {
    }
    .target {
      transform: translateX(calc(100% - 92px));
    }
  }
`;
