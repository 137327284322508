import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { loginWrap } from '@pages/login/Login.style';
import { useEmailValidation } from '@hooks/auth/getEmailValidation';
import { useModalContainer } from '@hooks/modal/useModalContainer';
import Constant from '@common/config/Constant';
import EmailForm from '@src/pages/login/components/EmailForm.comp';
import PasswordForm from '@pages/login/components/PasswordForm.comp';
import Logo from '@images/login-boracat-logo.svg';
import Illust from '@images/login-boracat-illust.svg';
import LogoNew from '@images/login-boracat-logo-new.svg';

function LoginPage() {
  const navigate = useNavigate();
  const { ModalContainer } = useModalContainer();
  const { type } = useParams();

  useEffect(() => {
    removelocalStorageTokenInfos();
  }, []);

  useEffect(() => {
    if (!['email', undefined].includes(type))
      navigate('/login', { replace: true });
  }, [type]);

  const { validEmail, mutate, variables } = useEmailValidation();

  return (
    <>
      <section css={loginWrap}>
        <div className="loginBox">
          <img src={LogoNew} alt="boracat" className="logo" />
          <h2 className="loginTitle">로그인</h2>
          <p className="loginInfo">
            글자 하나로 달라지는 품질. <br />
            글로벌 게임 성공을 위한 혁신적인 로컬라이제이션 경험을 지금
            시작해보세요!
          </p>

          <div className="formStepWrap">
            <div
              className={`formStep step${
                validEmail === Constant.EMAIL_ACTIVE ? '2' : '1'
              }`}
            >
              <EmailForm mutate={mutate} type={type} />
              {validEmail === Constant.EMAIL_ACTIVE ? (
                <PasswordForm variables={variables} />
              ) : null}
            </div>
          </div>
          {['email'].includes(String(type)) ? (
            <p className="loginPrivacyInfo">
              본 페이지는 <span className="loginPrivacyBtn">관리자 전용</span>{' '}
              로그인 페이지입니다.
              <br />
              권한 부여를 희망할 경우
              <span className="loginPrivacyBtn">
                글로벌라이제이션 센터장
              </span>{' '}
              에게 문의 주세요.
            </p>
          ) : (
            <p className="loginPrivacyInfo">
              새로운 계정 생성을 희망할 경우,{' '}
              <span className="loginPrivacyBtn">글로벌라이제이션 센터장</span>
              에게 문의 주세요.
              <br />
              로그인이 안 될 경우,{' '}
              <span className="loginPrivacyBtn">보라캣개발팀</span>에 문의
              주세요.
            </p>
          )}
        </div>
      </section>
      <ModalContainer />
    </>
  );
}

export default LoginPage;

const removelocalStorageTokenInfos = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessTokenExpiresIn');
  localStorage.removeItem('refreshTokenExpiresIn');
};
