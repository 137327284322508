import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JobAssignStyle, alertWrap } from '@pages/modal/JobAssignNew.style';
import { LANGUAGE } from '@src/common/config/Code';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { renderRowStatus } from '@src/pages/dashboard/utils/render';
import { useAlert } from '@hooks/modal/useAlert';
import { useGetTaskAssignInfo } from '@src/hooks/languageTask/getAssignInfo';
import {
  IconArrowRightBlack,
  IconFileTitleTrue,
  IconFileTitleFalse
} from '@src/resources/icon';
import { JobAssignTaskList } from './JobAssignTaskList';
import { IUser } from '@src/common/config/ICode';
import { usePostLanguageSubTask } from '@src/hooks/languageTask/post';
import { useQueryClient } from '@tanstack/react-query';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import Api from '@src/api/Api';

interface JobAssignNewPageProps {
  close?: () => void;
  setOpenedJobRegisterPage?: (open: boolean) => void;
}

interface ITaskAmountNew {
  percent101: number;
  percentRep: number;
  percent100: number;
  percent9599: number;
  percent8594: number;
  percent7584: number;
  percent5074: number;
  noMatch: number;
}

interface ILanguageSubTaskNew {
  languageSubTaskAmount: ITaskAmountNew;
  languageSubTaskId: number;
  languageSubTaskKey: string;
  memo: null | string;
  memoWarning: boolean;
  taskAmount: number;
  workerList: IUser[] | undefined;
  totalAmount: number;
}

const JobAssignNewPage: React.FC<JobAssignNewPageProps> = (props) => {
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const { id, jobDetailId } = useParams();
  const { assignInfoData } = useGetTaskAssignInfo(jobDetailId ?? id ?? '');
  const data = assignInfoData;
  const [newList, setNewList] = useState([...data.languageSubTaskList]);
  const [totalSum, setTotalSum] = useState(0);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>(
    data.languageSubTaskList.reduce<{ [key: string]: string }>((acc, task) => {
      acc[task.languageSubTaskKey] = task.memo || '';
      return acc;
    }, {})
  );
  const [inputLength, setInputLength] = useState<{ [key: string]: number }>({});
  const [buttonState, setButtonState] = useState(true);
  const [resetTrigger, setResetTrigger] = useState(false);
  const { postLanguageSubTask } = usePostLanguageSubTask();
  /* TM match 관련 초기 설정 (S) */
  const [calculator, setCalculator] = useState('');
  const [tmMatch, setTmMatch] = useState<boolean>(true);
  const { myInfo } = useMyInfo();
  const isPM = myInfo?.roles.includes('PML') || myInfo?.roles.includes('PM');
  const [totalAmount, setTotalAmount] = useState({
    totalGrossAmount: 0
  });
  const taskAmountList: ITaskAmountNew = {
    percent101: 0,
    percentRep: 0,
    percent100: 0,
    percent9599: 0,
    percent8594: 0,
    percent7584: 0,
    percent5074: 0,
    noMatch: 0
  };
  const taskStatus = data.languageTaskStatus ?? '';

  // task가 없을시 기본 task 설정
  const createWorker = (type: string) => ({
    userId: 0,
    name: '',
    avatarImgUrl: '',
    workerType: type,
    workerStatus: 'NEW' as 'NEW'
  });
  const newTask: ILanguageSubTaskNew = {
    languageSubTaskAmount: taskAmountList,
    languageSubTaskId: 0,
    languageSubTaskKey: '',
    memo: null,
    memoWarning: false,
    taskAmount: 0,
    workerList: [
      createWorker('TRANSLATOR'),
      createWorker('SECOND_CHECKER'),
      createWorker('FIRST_CHECKER')
    ],
    totalAmount: 0
  };

  const changeInput = (
    data: { [key: string]: any },
    taskKey?: string,
    AmountListKey?: string
  ) => {
    if (taskKey && AmountListKey) {
      setNewList((prevList): any => {
        return prevList.map((task) => {
          if (task.languageSubTaskKey === taskKey) {
            const updatedTaskAmount = {
              ...task.languageSubTaskAmount,
              [AmountListKey]: data[AmountListKey] || 0
            };
            const totalGrossAmount = Object.values(updatedTaskAmount).reduce(
              (sum, val) => sum + Number(val),
              0
            );

            return {
              ...task,
              languageSubTaskAmount: updatedTaskAmount,
              totalAmount: totalGrossAmount
            };
          }
          return task;
        });
      });
    }
  };
  /* TM match 관련 초기 설정(E) */

  //task 추가 함수
  const handleAddTaskList = () => {
    const lastTask = newList[newList.length - 1];
    let newLanguageSubTaskKey = 'test-01-01-01'; // 기본값 설정

    if (lastTask) {
      const parts = lastTask.languageSubTaskKey.split('-');
      const lastNumber = parseInt(parts[parts.length - 1], 10) + 1;
      parts[parts.length - 1] = lastNumber.toString().padStart(2, '0');
      newLanguageSubTaskKey = parts.join('-');
    }

    const newTaskToAdd = {
      ...newTask,
      languageSubTaskKey: newLanguageSubTaskKey
    };
    setNewList(newList.concat(newTaskToAdd));
  };

  const resetModal = () => {
    alertHandle({
      title: '배정 초기화',
      text: '입력 중인 내용이 모두 초기화됩니다.',
      cancelLabel: '취소',
      ok: () => {
        handleReset();
      }
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        const { state } = window.history;
        if (state?.projectId && state?.taskId) {
          window.history.replaceState({}, '');
          props?.setOpenedJobRegisterPage?.(false);
        } else {
          navigate(-1);
        }
      }
    });
  };

  const clickOk = async () => {
    const simplifiedList = {
      languageTaskId: data.languageTaskId,
      languageSubTaskList: newList.map((task) => ({
        taskAmount: task.totalAmount,
        languageSubTaskAmount: task.languageSubTaskAmount,
        languageSubTaskKey: task.languageSubTaskKey,
        memo: task.memo,
        workerList: task.workerList
          ?.filter((worker) => worker.userId !== 0)
          .map((worker) => ({
            userId: worker.userId,
            workerType: worker.workerType,
            isOutsourceOrNot: worker.isOutsourceOrNot
          }))
      }))
    };
    alertHandle({
      title: '배정 완료',
      text: `해당 업무로 지정된 작업자에게<br/>작업 안내가 발송됩니다.`,
      cancelLabel: '취소',
      ok: async () => {
        postLanguageSubTask(simplifiedList);
        // queryClient.invalidateQueries(languageTaskKeys.listByDay());
        navigate(-1);
      }
    });
  };

  // 초기화
  const handleReset = () => {
    setResetTrigger(!resetTrigger);
    setTmMatch(data.tmMatch!);
    if (data && data.languageSubTaskList.length > 0) {
      const updatedList = data.languageSubTaskList.map((task) => {
        const existingWorkerTypes =
          task.workerList?.map((worker) => worker.workerType) || [];
        const requiredWorkerTypes = [
          'TRANSLATOR',
          'SECOND_CHECKER',
          'FIRST_CHECKER'
        ];
        const updatedWorkerList = requiredWorkerTypes.map((type) => {
          return existingWorkerTypes.includes(type)
            ? task.workerList!.find((worker) => worker.workerType === type)!
            : newTask.workerList!.find((worker) => worker.workerType === type)!;
        });

        return {
          ...task,
          languageSubTaskAmount: {
            percent101: task.languageSubTaskAmount?.percent101 || 0,
            percentRep: task.languageSubTaskAmount?.percentRep || 0,
            percent100: task.languageSubTaskAmount?.percent100 || 0,
            percent9599: task.languageSubTaskAmount?.percent9599 || 0,
            percent8594: task.languageSubTaskAmount?.percent8594 || 0,
            percent7584: task.languageSubTaskAmount?.percent7584 || 0,
            percent5074: task.languageSubTaskAmount?.percent5074 || 0,
            noMatch: task.languageSubTaskAmount?.noMatch || 0
          },
          workerList: updatedWorkerList,
          totalAmount: task.taskAmount,
          memo: task.memo,
          memoWarning: task.memoWarning
        };
      });

      // 메모 입력값 설정
      const inputValuesUpdate = updatedList.reduce<{ [key: string]: any }>(
        (acc, task) => {
          acc[task.languageSubTaskKey] = task.memo || '';
          return acc;
        },
        {}
      );
      // 메모 입력길이 설정
      const inputLengthUpdate = updatedList.reduce<{ [key: string]: number }>(
        (acc, task) => {
          acc[task.languageSubTaskKey] = task.memo ? task.memo.length : 0;
          return acc;
        },
        {}
      );

      setInputValues(inputValuesUpdate);
      setInputLength(inputLengthUpdate);
      setNewList(updatedList);
    } else if (data && data.languageSubTaskList.length === 0) {
      const newLanguageSubTaskKey = `${data.languageTaskKey}-01`;
      const newTaskToAdd: ILanguageSubTaskNew = {
        ...newTask, // 기존 newTask 객체의 구조를 사용
        languageSubTaskKey: newLanguageSubTaskKey // 새로운 key 값으로 업데이트
      };
      setNewList([newTaskToAdd]);
    }
  };

  // 초기 사용자 리스트 정의
  useEffect(() => {
    if (data && data.languageSubTaskList.length > 0) {
      setTmMatch(data.tmMatch!);
      const updatedList = data.languageSubTaskList.map((task) => {
        const existingWorkerTypes =
          task.workerList?.map((worker) => worker.workerType) || [];
        const requiredWorkerTypes = [
          'TRANSLATOR',
          'SECOND_CHECKER',
          'FIRST_CHECKER'
        ];
        const updatedWorkerList = requiredWorkerTypes.map((type) => {
          return existingWorkerTypes.includes(type)
            ? task.workerList!.find((worker) => worker.workerType === type)!
            : newTask.workerList!.find((worker) => worker.workerType === type)!;
        });
        const taskAmounts = {
          percent101: task.languageSubTaskAmount?.percent101 || 0,
          percentRep: task.languageSubTaskAmount?.percentRep || 0,
          percent100: task.languageSubTaskAmount?.percent100 || 0,
          percent9599: task.languageSubTaskAmount?.percent9599 || 0,
          percent8594: task.languageSubTaskAmount?.percent8594 || 0,
          percent7584: task.languageSubTaskAmount?.percent7584 || 0,
          percent5074: task.languageSubTaskAmount?.percent5074 || 0,
          noMatch: task.languageSubTaskAmount?.noMatch || 0
        };
        return {
          ...task,
          languageSubTaskAmount: taskAmounts,
          workerList: updatedWorkerList,
          totalAmount: task.taskAmount,
          memo: task.memo,
          memoWarning: false
        };
      });
      // 메모 입력값 설정
      const inputValuesUpdate = updatedList.reduce<{ [key: string]: any }>(
        (acc, task) => {
          acc[task.languageSubTaskKey] = task.memo || '';
          return acc;
        },
        {}
      );
      // 메모 입력길이 설정
      const inputLengthUpdate = updatedList.reduce<{ [key: string]: number }>(
        (acc, task) => {
          acc[task.languageSubTaskKey] = task.memo ? task.memo.length : 0;
          return acc;
        },
        {}
      );

      setInputValues(inputValuesUpdate);
      setInputLength(inputLengthUpdate);
      setNewList(updatedList);
    } else if (data && data.languageSubTaskList.length === 0) {
      const newLanguageSubTaskKey = `${data.languageTaskKey}-01`;
      const newTaskToAdd: ILanguageSubTaskNew = {
        ...newTask,
        languageSubTaskKey: newLanguageSubTaskKey
      };
      setNewList([newTaskToAdd]);
    }
    setIsLoading(true);
  }, [data]);

  //작업분량 및 배정하기 버튼 on,off
  useEffect(() => {
    const newTotalSum = newList.reduce(
      (acc, task) => acc + task.totalAmount!,
      0
    );

    setTotalSum(newTotalSum);
    if (newTotalSum > data.totalGrossAmount) {
      setCalculator('exceed');
    } else if (newTotalSum < data.totalGrossAmount) {
      setCalculator('below');
    } else {
      setCalculator('equal');
    }

    const isInvalid = newList.some((task) => {
      const translatorCheck = isPM
        ? false
        : task.workerList?.some(
            (worker) => worker.workerType === 'TRANSLATOR' && !worker.name
          );
      return (
        task.totalAmount === 0 ||
        (task.memo && task.memo.length > 30) ||
        translatorCheck
      );
    });
    if (isInvalid) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [newList, data.totalGrossAmount]);

  return (
    <div css={[JobAssignStyle, alertWrap]}>
      <TPButtonClose onClick={closeModal} />
      <div className="title">작업자 배정</div>
      {data && isLoading && (
        <>
          <div className="title--description--box">
            <div>
              <span>{renderRowStatus(data.languageTaskStatus)}</span>
              <p className="task--key">{data.languageTaskKey}</p>
              <p className="task--language">
                <span>
                  {LANGUAGE.filter((v) => v.code === data.startLanguage).map(
                    (lang) => lang.label
                  )}
                </span>
                <span className="arrow">
                  <IconArrowRightBlack />
                </span>
                <span>
                  {LANGUAGE.filter(
                    (v) => v.code === data.destinationLanguage
                  ).map((lang) => lang.label)}
                </span>
              </p>
            </div>
            <p>
              {data.fileRegisterStatus ? (
                <IconFileTitleTrue className="title-fileuse" />
              ) : (
                <IconFileTitleFalse className="title-fileuse" />
              )}
              {data.languageTaskTitle}
            </p>
          </div>
          <div className="all--count">
            <strong className="count-title">총 분량</strong>

            {data.taskType === 'LQA' ? (
              <div className="total--time">
                <p>
                  {Math.floor(data.totalGrossAmount / 250)} <span>시간</span>
                </p>
                <p>
                  {data.totalGrossAmount % 250 > 0 ? '30' : '00'}{' '}
                  <span>분</span>
                </p>
              </div>
            ) : (
              <div className="total--amount">
                <p>
                  <span>Gross</span>
                  {data.totalGrossAmount}
                </p>
                <p>
                  <span>NET</span>
                  {data.totalNetAmount}
                </p>
              </div>
            )}
          </div>
          <div className="detail--task--wrap">
            <div className="top--box">
              {data.taskType === 'LQA'
                ? calculator !== 'equal' && (
                    <p>
                      총
                      <span>
                        {Math.floor(
                          Math.abs(totalSum - data.totalGrossAmount) / 250
                        )}
                        시간{' '}
                        {Math.abs(totalSum - data.totalGrossAmount) % 250 > 0
                          ? '30'
                          : '00'}
                        분
                      </span>
                      이{' '}
                      {calculator === 'exceed'
                        ? '초과되었습니다'
                        : '부족합니다'}
                      .
                    </p>
                  )
                : calculator !== 'equal' && (
                    <p>
                      총
                      <span>{Math.abs(totalSum - data.totalGrossAmount)}</span>
                      단어(자)가{' '}
                      {calculator === 'exceed'
                        ? '초과되었습니다'
                        : '부족합니다'}
                      .
                    </p>
                  )}
              <button className="reset--button" onClick={resetModal}>
                초기화
              </button>
            </div>
            {/* <>{assignMap()}</> */}
            <JobAssignTaskList
              data={data}
              newList={newList}
              setNewList={setNewList}
              totalAmount={totalAmount}
              tmMatch={data.tmMatch!}
              taskAmountList={taskAmountList}
              changeInput={changeInput}
              languageTaskKey={data.languageTaskKey}
              inputValues={inputValues}
              setInputValues={setInputValues}
              inputLength={inputLength}
              setInputLength={setInputLength}
              resetTrigger={resetTrigger}
              isPM={isPM}
            />
          </div>
          {!data.isBoracatUseOrNot &&
            data.taskType !== 'LQA' &&
            taskStatus !== 'DELIVERY_COMPLETE' &&
            taskStatus !== 'COMPLETE' && (
              <div className="task--split">
                <button onClick={handleAddTaskList}>분량 나누기</button>
              </div>
            )}
        </>
      )}
      <div className="cancel--assign--container">
        <TPButtonBasic
          variant="outlined"
          label={'취소하기'}
          fullWidth
          onClick={closeModal}
        />
        <TPButtonBasic
          variant="contained"
          label={'배정하기'}
          fullWidth
          disabled={buttonState}
          onClick={clickOk}
        />
      </div>
    </div>
  );
};

export default JobAssignNewPage;
