import api from '@api/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fileManagementKeys } from '@hooks/queryKeyFactory';
import _ from 'lodash';
import { useAlert } from '../modal/useAlert';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../modal/useToast';

interface IFileOption {
  file: string;
  optionData: {
    languageTaskId: string;
    sheetIndex?: number;
    useHeader: boolean;
    hiddenRowAndColumn?: boolean;
  };
}

export const useGetFileList = (
  fileId: any,
  fileType: string,
  fileOption: IFileOption
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();

  //#region api call
  // const getData = async (): Promise<any[]> => {
  //   return await api
  //     .getBora(`/stock/contents?languageTaskId=${fileId}`)
  //     .then((res) => res.data);
  // };
  const getData = async (): Promise<any[]> => {
    const { file, optionData } = fileOption;
    // return await api
    //   .getBora(
    //     // `/stock/contents/txt?languageTaskId=${fileId}&useHeader=false`
    //     `/stock/contents/xls?languageTaskId=${optionData.languageTaskId}&sheetIndex=0&useHeader=${optionData.useHeader}&hiddenRowAndColumn=${optionData.hiddenRowAndColumn}`
    //     // `/stock/contents/xls?languageTaskId=${fileId}&sheetIndex=0&useHeader=false&hiddenRowAndColumn=false`
    //   )
    //   .then((res) => res.data);
    let url = '';
    if (fileType === 'txt') {
      url = `/stock/contents/txt?languageTaskId=${optionData.languageTaskId}&useHeader=${optionData.useHeader}`;
    } else if (fileType === 'xlsx') {
      url = `/stock/contents/xls?languageTaskId=${optionData.languageTaskId}&sheetIndex=${optionData.sheetIndex}&useHeader=${optionData.useHeader}&hiddenRowAndColumn=${optionData.hiddenRowAndColumn}`;
    }
    return await api.getBora(url).then((res) => res.data);
  };

  const postData = async ({
    segmentObj,
    fileType
  }: {
    segmentObj: any;
    fileType: string;
  }) => {
    let url = '';
    if (fileType === 'txt') {
      url = `/segment/txt`;
    } else if (fileType === 'xlsx') {
      url = `/segment/xls`;
    }
    await api.postBora(url, segmentObj, {
      timeout: 360000,
      signal: AbortSignal.timeout(360000)
    });
  };
  //#endregion

  //#region useQuery define
  // const { data = [] } = useQuery([...fileManagementKeys.list], getData);
  const { data = [] } = useQuery(
    [...fileManagementKeys.list, fileId, fileOption],
    getData,
    { enabled: !!fileType }
  );

  const { mutate: postSegment } = useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries(['/project']);
      queryClient.invalidateQueries(['/languageTask']);
      openHandle({
        text: `Segment 성공되었습니다.`,
        severity: 'success'
      });
      navigate('/taskManager', { replace: true });
    },
    onError: () => {
      openHandle({
        text: `Segment 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/taskManager', { replace: true });
      }
    });
  };

  return { data, getData, postSegment, closeModal };
};
