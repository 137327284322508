import { ThemeProvider } from '@mui/material/styles';
import { TPInputDateTheme } from './TPInput.theme';
import { Box, TextField } from '@mui/material';
import { IconCalendar } from '@src/resources/icon';
import { format } from 'date-fns';
import styled from '@emotion/styled';

interface IInputProps {
  date?: Date | string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  openHandle?: () => void;
  helperText?: string;
  reservationStatus?: boolean;
}

export const TPInputDateSelect = (props: IInputProps) => {
  const {
    date,
    label,
    required = false,
    placeholder,
    openHandle,
    helperText,
    reservationStatus = false
  } = props;

  const dateFormat = 'yyyy.MM.dd HH:mm';
  const showDate = date ? format(new Date(date), dateFormat).toLowerCase() : '';
  const StyledTextField = getStyledTextField(reservationStatus);
  return (
    <ThemeProvider theme={TPInputDateTheme}>
      <div>
        {label ? (
          <Label>
            <span className="label">{label}</span>
            {required ? <span className="star">*</span> : null}
          </Label>
        ) : null}

        <Box mt={label ? 1 : 0} onClick={openHandle}>
          <StyledTextField
            value={showDate}
            placeholder={placeholder}
            helperText={helperText}
            InputProps={{
              readOnly: true,
              endAdornment: <IconCalendar width={24} height={24} />
            }}
          />
        </Box>
      </div>
    </ThemeProvider>
  );
};

const Label = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  .label {
  }
  .star {
    color: #e6311a;
  }
`;

const getStyledTextField = (reservationStatus: boolean) =>
  styled(TextField)({
    '& .MuiInputBase-input::placeholder': {
      color: `${reservationStatus ? '#AA51E0' : '#727272'} !important`
    }
  });
