import axios, { AxiosRequestConfig } from 'axios';

import interceptor from '@api/Interceptor';
import Config from '@src/common/config/Config';

/*
  Api 요청 공통 service
*/
const Api = {
  get: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let configure = { ...config, baseURL: `${Config.apiBaseUrl}tms` } || {};
    configure.params = data;
    return interceptor.get(url, configure);
  },
  post: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.post(url, body, {
      ...config,
      baseURL: `${Config.apiBaseUrl}tms`
    });
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.put(url, body, {
      ...config,
      baseURL: `${Config.apiBaseUrl}tms`
    });
  },
  delete: (url: string, config?: AxiosRequestConfig) => {
    return interceptor.delete(url, {
      ...config,
      baseURL: `${Config.apiBaseUrl}tms`
    });
  },

  getPms: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let configure = { ...config, baseURL: `${Config.apiBaseUrl}pms` } || {};
    configure.params = data;
    return interceptor.get(url, configure);
  },
  postPms: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.post(url, body, {
      ...config,
      baseURL: `${Config.apiBaseUrl}pms`
    });
  },
  putPms: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.put(url, body, {
      ...config,
      baseURL: `${Config.apiBaseUrl}pms`
    });
  },
  deletePms: (url: string, config?: AxiosRequestConfig) => {
    return interceptor.delete(url, {
      ...config,
      baseURL: `${Config.apiBaseUrl}pms`
    });
  },

  getAlarm: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let configure = config || {};
    configure.params = data;
    return axios.get(url, configure);
  },

  getBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let configure = { ...config, baseURL: `${Config.apiBaseUrl}cat` } || {};
    configure.params = data;
    return interceptor.get(url, configure);
  },
  postBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.post(url, body, {
      ...config,
      baseURL: `${Config.apiBaseUrl}cat`
    });
  },
  putBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.put(url, body, {
      ...config,
      baseURL: `${Config.apiBaseUrl}cat`
    });
  },
  deleteBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.delete(url, {
      ...config,
      baseURL: `${Config.apiBaseUrl}cat`
    });
  }
};

export default Api;
