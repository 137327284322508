import { cx } from '@emotion/css';
import { alertWrap } from '@hooks/modal/Modal.style.v2';
import { useAlert } from '@hooks/modal/useAlert';
import { Box } from '@mui/material';
import { IconCircleClose } from '@resources/icon';
import { context } from '@src/Context';
import { categories } from '@src/common/config/Code';
import { useSingleCalendar } from '@src/hooks/modal/useSingleCalendar';
import { useGetTask } from '@src/hooks/task/get';
import { useGetLanguageSpecList } from '@src/hooks/task/getLanguageSpec';
import { useUpsertTask } from '@src/hooks/task/post';
import { usePageFunc } from '@src/hooks/utils';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPInputDate } from '@src/units/input/TPInput.date';
import { TPInputRadio } from '@src/units/input/TPInput.radio';
import { TPInputText } from '@src/units/input/TPInput.text';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { amountObject, dateToKST } from '../dashboard/utils/render';
import { JobRegisterStyle } from './JobRegister.style';
import { LanguageTaskMaker, onMakeProps } from './LanguageTaskMaker';
import { LanguageTaskMakerItem } from './LanguageTaskMakerItem';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';

/**
 * 프로젝트 상세 > 작업 등록 페이지
 * route : /register/:id
 * auth : PML,PM
 * @returns {JSX.Element} <JobRegisterPage />
 */

function JobRegisterPage(props: any) {
  const queryclient = useQueryClient();
  const location = useLocation();
  const { makerItemsRef } = useContext(context);

  const regex = /^(http|https):\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/;
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  // let { id: projectId = sessionStorage.getItem('projectId') } = useParams();
  let { id: projectId, jobDetailId } = useParams();
  const { search, pathname, state } = useLocation();
  const params = new URLSearchParams(search);
  // let copyId = params.get('copyId');

  const pageMode = /\d+\/(?<mode>\w+)\/\d+/.exec(pathname)?.groups?.mode;
  // console.debug({ pathname, state, projectId, jobDetailId, pageMode });

  // if (!projectId) projectId = window.history.state?.projectId;
  // if (!copyId) copyId = window.history.state?.taskId;

  // const { languageSpecList } = useGetLanguageSpecList(); // 서버에서 불러온 언어 목록
  // const { taskAmountRate, taskAmountText, getDateTime } = usePageFunc();

  const { openHandle: openCalendar, Component: Calendar } = useSingleCalendar();
  const { openHandle: alertHandle } = useAlert();
  const { postTask, putTask } = useUpsertTask();
  // const { taskDetail, refetch } = useGetTask(String(copyId));
  const { taskDetail, refetch } = useGetTask(
    pageMode === 'register' ? '' : String(jobDetailId)
  );
  const { myInfo } = useMyInfo();
  const isPML = myInfo?.roles.includes('PML');
  const [modifyPML, setModifyPML] = useState<boolean>(false);

  //#region state
  const [startDateTime, setStartDateTime] = useState<Date | undefined>(
    undefined
  );
  const [taskType, setTaskType] = useState<string>('TRANSLATION');
  const [taskTitle, setTaskTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [linkList, setLinkList] = useState<string[]>([]);
  const [linkValid, setLinkValid] = useState<boolean>(false);
  const [languageTaskList, setLanguageTaskList] = useState<any[]>([]);
  //#endregion

  const isValid =
    taskTitle.length > 0 && taskTitle.length <= 100 && Boolean(startDateTime);

  const isItemsValid =
    languageTaskList.length > 0 &&
    languageTaskList.every((e) => e.disabled === false);

  const isRegistButtonDisabled = !isValid || !isItemsValid;

  useEffect(() => setLink(''), [linkList]);
  useEffect(() => {
    // 권한체크
    if (
      taskDetail?.taskStatus === 'DELIVERY_COMPLETE' &&
      pageMode === 'modify'
    ) {
      setModifyPML(true);
    }

    // if (copyId) {
    if (pageMode === 'copy' || pageMode === 'modify') {
      // console.debug('taskDetail', taskDetail);
      setTaskType(taskDetail?.taskType ?? 'TRANSLATION');
      setTaskTitle(taskDetail?.taskTitle ?? '');
      setDescription(taskDetail?.description ?? '');
      setLinkList(taskDetail?.linkList ?? []);
      setStartDateTime(
        taskDetail?.startDateTime && new Date(taskDetail?.startDateTime)
      );
      if (Array.isArray(taskDetail?.languageTaskList))
        setLanguageTaskList(
          taskDetail?.languageTaskList?.map((el: any) => {
            const _rid = crypto.randomUUID();
            return {
              // startDateTime: startDateTime && new Date(startDateTime),
              // openCalendar,
              // onDelete: onDelete.bind(null, _rid),
              // onMakeDisabled,
              ...el,
              _rid,
              pageMode, // copy 모드에서 속성 추가
              languageTaskTitle: el.languageTaskTitle,
              isBoracatUseOrNot: el.isBoracatUseOrNot,
              fileUse: el.isBoracatUseOrNot ? 'in' : 'out',
              tmMatch: el.tmMatch,
              startLanguage: el.startLanguage,
              destinationLanguage: el.destinationLanguage,
              languageTaskAmountList: el.languageTaskAmountList,
              grossAmount: el.grossAmount,
              netAmount: el.netAmount,
              endDateTime: el.endDateTime
                ? new Date(el.endDateTime)
                : undefined,
              hopeSupplyDateTime: el.hopeSupplyDateTime
                ? new Date(el.hopeSupplyDateTime)
                : undefined
            };
          }) ?? []
        );
    }
    // }, [taskDetail, copyId]);
  }, [taskDetail, jobDetailId]);

  //#region 함수 모음
  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        const { state } = window.history;
        if (state?.projectId && state?.taskId) {
          window.history.replaceState({}, '');
          props?.setOpenedJobRegisterPage?.(false);
        } else {
          navigate(-1);
        }
      }
    });
  };

  // 추가 버튼 클릭 시
  const onMake = ({
    isBoracatUseOrNot,
    fileUse,
    tmMatch,
    taskAmount,
    totalAmount,
    startLanguage,
    destinationLanguages,
    endDateTime,
    hopeSupplyDateTime
  }: onMakeProps) => {
    const s = destinationLanguages.map((destinationLanguage) => {
      return {
        _rid: crypto.randomUUID(),
        languageTaskTitle: taskTitle,
        fileUse,
        isBoracatUseOrNot,
        tmMatch: tmMatch,
        startLanguage,
        destinationLanguage,
        grossAmount: totalAmount.totalGrossAmount,
        netAmount: totalAmount.totalNetAmount,
        endDateTime,
        hopeSupplyDateTime,
        languageTaskAmount: amountObject(taskAmount)
      };
    });

    setLanguageTaskList((o) => [...o, ...s]);
  };

  const onDelete = (_rid: string) => {
    setLanguageTaskList((o) => o.filter((el) => el._rid !== _rid));
  };

  const onMakeDisabled = (_rid: string, disabled: boolean) => {
    setLanguageTaskList((o) => {
      const index = o.findIndex((el) => el._rid === _rid);
      o[index] = { ...o[index], disabled };
      return [...o];
    });
  };

  const onInputLink = () => {
    const getLink = link.trim();
    if (linkList.length < 10 && getLink.length)
      setLinkList(_.uniq([...linkList, getLink]));
    else setLinkValid(true);
  };

  const onSubmit = (data: any) => {
    const payload: any = {
      projectId,
      taskType,
      taskTitle,
      startDateTime: dateToKST(startDateTime),
      languageTaskList: languageTaskList.map((el) => {
        const obj = makerItemsRef.current[el._rid];
        return {
          // ...el,
          languageTaskId: el.languageTaskId,
          tmMatch: obj.tmMatch,
          grossAmount:
            taskType === 'LQA'
              ? obj.totalAmount.totalGrossAmount
              : obj.isBoracatUseOrNot
              ? 0
              : obj.totalAmount.totalGrossAmount,
          netAmount:
            taskType === 'LQA'
              ? obj.totalAmount.totalNetAmount
              : obj.isBoracatUseOrNot
              ? 0
              : obj.totalAmount.totalNetAmount,
          endDateTime: dateToKST(obj.endDateTime),
          hopeSupplyDateTime: dateToKST(obj.hopeSupplyDateTime),
          languageTaskAmount: amountObject(
            obj.taskAmount,
            obj.isBoracatUseOrNot
          ),
          languageTaskTitle: obj.languageTaskTitle,
          isBoracatUseOrNot: obj.isBoracatUseOrNot,
          startLanguage: obj.startLanguage,
          destinationLanguage: obj.destinationLanguage
        };
      }),
      description,
      linkList
    };

    if (pageMode === 'modify') {
      payload['taskId'] = jobDetailId;
      delete payload.projectId;
    }

    const isTest = false;

    if (isTest) {
    } else {
      alertHandle({
        title: PAGE_TITLE,
        text: `입력한 내용으로 ${PAGE_TITLE}하시겠습니까?`,
        cancelLabel: '취소',
        ok: () => {
          if (pageMode === 'modify') {
            putTask(payload).then(() => {
              const { state } = window.history;
              if (state?.projectId && state?.taskId) {
                window.history.replaceState({}, '');
                props?.setOpenedJobRegisterPage?.(false);
              } else {
                navigate(-1);
              }
            });
          } else {
            postTask(payload).then(() => {
              const { state } = window.history;
              if (state?.projectId && state?.taskId) {
                window.history.replaceState({}, '');
                props?.setOpenedJobRegisterPage?.(false);
              } else {
                navigate(-1);
              }
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    refetch();
    return () => {
      makerItemsRef.current = {}; // 초기화
    };
  }, []);

  const PAGE_TITLE =
    pageMode === 'copy'
      ? '작업 복사'
      : pageMode === 'modify'
      ? '작업 수정'
      : '작업 등록';

  const PAGE_BUTTON_LABEL =
    pageMode === 'copy' ? '복사' : pageMode === 'modify' ? '수정' : '등록';

  return (
    <div css={[JobRegisterStyle, alertWrap]}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <h2 className="alertTitle">{PAGE_TITLE}</h2>
      <div ref={scrollRef} className="modal--div--register">
        <h3 className="alertTitle">상세정보</h3>
        <div className="task-details">
          {/* 라디오 박스 */}
          <div>
            <TPInputRadio
              value={taskType}
              label={'유형'}
              required={true}
              name={'category'}
              options={categories}
              changeRadio={(e: any) => setTaskType(e.target.value)}
              disabled={modifyPML}
            />
          </div>
          {/* 100글자 이상일 경우 'lengthError' 클래스 추가로 애러 표출 */}
          <div
            className={cx('lengthCheck', {
              lengthError: taskTitle.length > 100
            })}
          >
            <span>
              <s>{taskTitle.length}</s>/100
            </span>
            <TPInputText
              label={'작업명'}
              name={'title'}
              value={taskTitle}
              changeText={(e: any) => setTaskTitle(e.target.value)}
              required={true}
              placeholder={'작업명을 100자 이내 입력해 주세요.'}
              disabled={modifyPML}
            />
          </div>
          <div className="dates-start">
            <div>
              <TPInputDate
                name={'startDateTime'}
                label={'시작일'}
                date={startDateTime}
                placeholder={'날짜를 입력해 주세요.'}
                openHandle={() =>
                  openCalendar('start', startDateTime, setStartDateTime)
                }
                required
                disabled={modifyPML}
              />
            </div>
          </div>
          {/* 500글자 이상인 경우 lengthError 클래스 추가로 에러 표출*/}
          <div
            className={`lengthCheck  ${
              description.length > 500 && 'lengthError'
            }`}
          >
            <span>
              <s>{description.length}</s>/500
            </span>
            <TPInputText
              name={'description'}
              value={description}
              label={'설명'}
              charType={'area'}
              changeText={(e: any) => setDescription(e.target.value)}
              placeholder={'추가 설명을 500자 이내 입력해 주세요.'}
            />
          </div>
          <div className={'linkInputAndList'}>
            <h3 className="name">링크</h3>
            {linkValid && (
              <span className="valid">링크는 10개까지 등록이 가능합니다.</span>
            )}
            <div className="input--wrap">
              <input
                value={link}
                placeholder="'URL'을 입력해 주세요."
                onKeyDown={(e: any) => {
                  if (!link.replace(/ /g, '').length) return;
                  if (e.keyCode === 13 && regex.test(link)) onInputLink();
                }}
                onChange={(e) => setLink(e.target.value)}
              />
              <TPButtonBasic
                variant="contained"
                label="추가"
                onClick={onInputLink}
                disabled={!regex.test(link)}
                disableElevation
                // onClick={onSubmit}
              />
              <div className="link--count">
                <span>{linkList.length}</span>
                <span>/10</span>
              </div>
            </div>
            <ul className="link--list">
              {_.map(linkList, (el, index) => (
                <li key={`${el}${index}`}>
                  <span>{el}</span>
                  <IconCircleClose
                    className="remove--button"
                    onClick={() => {
                      setLinkList(_.without(linkList, el));
                      setLinkValid(false);
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="task-title">언어 작업</div>
        {!modifyPML && (
          <LanguageTaskMaker
            {...{
              startDateTime,
              openCalendar,
              taskType,
              onMake
            }}
          />
        )}
        <div>
          {languageTaskList.map(({ _rid, ...props }) => {
            return (
              <LanguageTaskMakerItem
                key={_rid}
                taskType={taskType}
                startDateTime={startDateTime}
                openCalendar={openCalendar}
                onDelete={onDelete.bind(null, _rid)}
                onMakeDisabled={onMakeDisabled.bind(null, _rid)}
                taskListLength={languageTaskList.length}
                _rid={_rid}
                {...props}
                isPML={isPML}
              />
            );
          })}
        </div>
      </div>

      <Box height={'32px'} />
      <Box display={'flex'} justifyContent={'space-between'} gap={2}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label={PAGE_BUTTON_LABEL}
            fullWidth
            disabled={isRegistButtonDisabled}
            onClick={onSubmit}
            size={'large'}
          />
        </Box>
      </Box>
      <Calendar />
    </div>
  );
}

export default JobRegisterPage;
