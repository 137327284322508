import { useQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/query-core';
import api from '@api/Api';
import { languageTaskKeys } from '@hooks/queryKeyFactory';
import { ILanguageTaskData } from '@src/common/config/ILanguageTask';
import { useEffect, useState } from 'react';
import { useMyInfo } from '../myInfo/getMyInfo';

/**
 * /languageTask/{languageTaskId}
 * @param languageTaskId 언어 작업 아이디
 * @returns {{ detailData, error }}
 */
export const useGetListByDay = () => {
  const { myInfo, isLoading } = useMyInfo();

  const [filter, setFilter] = useState<any>({
    keyword: '',
    languagePairList: [],
    languageTaskStatus: [],
    projectManagerUserIdList: [],
    taskType: [],
    workUserIdList: []
  });
  const [type, setType] = useState<'task' | 'mytask'>('task');
  const [label, setLabel] = useState('');

  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof languageTaskKeys)['listByDay']>
  >): Promise<any> => {
    const [, , label] = queryKey;

    return (
      await api.post('languageTask/listByDay', {
        label,
        ...filter,
        languagePairList:
          filter?.languagePairList?.length > 0
            ? filter.languagePairList
            : myInfo?.languagePairList ?? [] // [주의]: 언어쌍 필터가 없으면 내가 가진 언어쌍으로 필터링. tasktab페이지에서만 적용되는 룰.
      })
    ).data;
  };

  const {
    error,
    data: listByDayData,
    isSuccess,
    isLoading: isListByDayLoading,
    refetch
  } = useQuery([...languageTaskKeys.listByDay(label, type, filter)], getData, {
    enabled: true
  });

  useEffect(() => {
    if (!isLoading && label !== '' && Array.isArray(myInfo?.languagePairList)) {
      // console.log(filter);
      refetch();
    }
  }, [label, filter]);

  return {
    listByDayData,
    isSuccess,
    isListByDayLoading,
    setLabel,
    setFilter,
    error
  };
};
