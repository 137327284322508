import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { globalKeys } from '../queryKeyFactory';
import {
  IconBulRight,
  IconStar,
  IconStarLine,
  IconSearch,
  IconSearchCancel
} from '@resources/icon';
import { ClickAwayListener } from '@mui/material';
import { useGetTaskAssignWorker } from '../languageTask/getAssignWorker';
import _ from 'lodash';
import { useFavorites } from '../favorites';
import { useEffect, useState } from 'react';
import { IUser } from '@src/common/config/ICode';
import { getLanguageLabelsByCode } from '@common/config/Code';
import { usePageFunc } from '@hooks/utils';
import { css, cx } from '@emotion/css';
import { createPortal } from 'react-dom';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
/**
 * useTaskerSearchBookMark hook
 * @description 작업자 배정하기 모달
 */
export const useTaskerSearchBookMark = () => {
  //#region global state
  const taskerSearchData = useGlobalValue(
    [...globalKeys.taskerSearchBookMark],
    {
      open: false
    }
  );
  const taskerSearchFetcher = useSetGlobalState([
    ...globalKeys.taskerSearchBookMark
  ]);
  const { open, setData, rowIdx, workerIdx } = taskerSearchData;
  //#endregion

  //#region handler
  const openHandle = (
    setData: (select: IUser, rowIdx: number, workerIdx: number) => void,
    rowIdx: number,
    workerIdx: number
  ) => {
    taskerSearchFetcher({
      ...taskerSearchData,
      open: true,
      setData,
      rowIdx,
      workerIdx
    });
  };

  const closeHandle = () => {
    taskerSearchFetcher({ ...taskerSearchData, open: false });
  };

  //배정안함
  const confirmHandle = (select: IUser) => {
    setData(select, rowIdx, workerIdx);
    taskerSearchFetcher({ ...taskerSearchData, open: false });
  };
  //#endregion

  //#region component
  const Component = () => {
    const [select, setSelect] = useState<number>(0);
    const { assignWorkerData, searchWorkerData, keyword, setKeyword } =
      useGetTaskAssignWorker();
    const { favoritesList, onChangeFavorites } = useFavorites();
    const { sortingLanguagePairList } = usePageFunc();

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [searchState, setSearchState] = useState(false);
    const [searchList, setSearchList] = useState(false);

    const changeText = (e: any) => {
      setKeyword(e?.target?.value ?? '');
      if (e?.target?.value) {
        setSearchList(true);
      } else {
        setSearchList(false);
      }
    };

    return createPortal(
      <ClickAwayListener onClickAway={closeHandle}>
        <div className={cssComponent}>
          <div className="tabs">
            {!searchState && (
              <>
                {' '}
                <div
                  className={cx('tab', { active: tabIndex === 0 })}
                  onClick={() => setTabIndex(0)}
                >
                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
                    <path
                      d="M12.9382 14.7692L7.99559 12.2436L3.04963 14.7626L3.99747 9.42085L0 5.63468L5.52839 4.85896L8.00377 0L10.4727 4.8623L16 5.64547L11.9975 9.42624L12.9382 14.7692Z"
                      fill="#FFC700"
                    />
                  </svg>
                  즐겨찾기
                </div>
                <div className="divider" />
                <div
                  className={cx('tab', { active: tabIndex === 1 })}
                  onClick={() => setTabIndex(1)}
                >
                  전체
                </div>
              </>
            )}
            <button
              onClick={() => {
                setSearchState(!searchState);
                setTabIndex(2);
              }}
            >
              <IconSearch
                className="button"
                width={16}
                height={16}
                stroke="#000"
                fill="#000"
              />
            </button>
          </div>
          {searchState && (
            <div className="searchContainer">
              <TPInputSearchNew
                className={css`
                  position: absolute;
                  top: -35px;
                  left: 14px;
                  right: 14px;
                  border: 1px solid rgba(155, 81, 224, 1);
                  border-radius: 2px;
                  .button {
                    left: 10px;
                    width: 16px;
                  }
                  input {
                    color: #727272;
                    padding: 0 30px 0 30px;
                    border: none;
                    &:hover {
                      border: none;
                    }
                  }
                `}
                type="text"
                // autoFocus={true}
                value={keyword}
                placeholder="검색"
                onChange={changeText}
                clickSearch={() => console.debug('clickSearch')}
              />
              <button
                className="searchCancel"
                onClick={() => {
                  setSearchState(!searchState);
                  setTabIndex(1);
                  changeText('');
                }}
              >
                <IconSearchCancel />
              </button>
            </div>
          )}

          {/* <TPSeparation margin="12px 0 12px 0" color="#F3F3F4" /> */}
          {favoritesList.length && tabIndex === 0 ? (
            <ul className="bookmark--list">
              {_.map(_.sortBy(assignWorkerData, 'name'), (el, index) => {
                return (
                  _.find(favoritesList, (id) => id === el.userId) && (
                    <li
                      className={el.userId === select ? 'active' : ''}
                      onClick={() => {
                        setSelect(el.userId);
                        const user = assignWorkerData.find(
                          (e) => el.userId === e.userId
                        );
                        // 작업자 선택

                        if (user) confirmHandle(user);
                      }}
                      key={`bookmarkList${index}`}
                    >
                      <div className="img--avatar--name">
                        <IconStar
                          className="book--mark"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeFavorites(favoritesList, el.userId);
                          }}
                          width={16}
                        />
                        <img src={el.avatarImgUrl} alt="" />
                        <div className="name">{el.name}</div>
                      </div>
                      <div className="language--pair--list">
                        {_.map(
                          sortingLanguagePairList(el.languagePairList),
                          (lang, index) => (
                            <div
                              className="language--pair"
                              key={`favoritesList${index}`}
                            >
                              <span>
                                {getLanguageLabelsByCode([
                                  lang.startLanguage
                                ])[0]
                                  ? getLanguageLabelsByCode([
                                      lang.startLanguage
                                    ])[0].label
                                  : lang.startLanguage}
                              </span>
                              <IconBulRight width={7} height={18} />
                              <span>
                                {getLanguageLabelsByCode([
                                  lang.destinationLanguage
                                ])[0]
                                  ? getLanguageLabelsByCode([
                                      lang.destinationLanguage
                                    ])[0].label
                                  : lang.destinationLanguage}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </li>
                  )
                );
              })}
            </ul>
          ) : (
            <></>
          )}

          {tabIndex === 1 && !searchState && (
            <ul className="tasker--list">
              {_.map(_.sortBy(assignWorkerData, 'name'), (el, index) => {
                return (
                  <li
                    className={el.userId === select ? 'active' : ''}
                    onClick={() => {
                      setSelect(el.userId);
                      const user = assignWorkerData.find(
                        (e) => el.userId === e.userId
                      );
                      if (user) confirmHandle(user);
                    }}
                    key={`taskerList${index}`}
                  >
                    <div className="img--avatar--name">
                      {_.find(favoritesList, (id) => id === el.userId) ? (
                        <IconStar
                          className="book--mark"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeFavorites(favoritesList, el.userId);
                          }}
                          width={16}
                        />
                      ) : (
                        <IconStarLine
                          className="book--mark"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeFavorites(favoritesList, el.userId);
                          }}
                          width={16}
                        />
                      )}
                      <img src={el.avatarImgUrl} alt="" />
                      <div className="name">{el.name}</div>
                    </div>
                    <div className="language--pair--list">
                      {_.map(
                        sortingLanguagePairList(el.languagePairList),
                        (lang, index) => (
                          <div
                            className="language--pair"
                            key={`pairList${index}`}
                          >
                            <span>
                              {getLanguageLabelsByCode([lang.startLanguage])[0]
                                ? getLanguageLabelsByCode([
                                    lang.startLanguage
                                  ])[0].label
                                : lang.startLanguage}
                            </span>
                            <IconBulRight width={7} height={18} />
                            <span>
                              {getLanguageLabelsByCode([
                                lang.destinationLanguage
                              ])[0]
                                ? getLanguageLabelsByCode([
                                    lang.destinationLanguage
                                  ])[0].label
                                : lang.destinationLanguage}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {tabIndex === 2 && searchList && (
            <ul className="search-list">
              {_.map(_.sortBy(searchWorkerData, 'name'), (el, index) => {
                return (
                  <li
                    className={el.userId === select ? 'active' : ''}
                    onClick={() => {
                      setSelect(el.userId);
                      const user = searchWorkerData.find(
                        (e) => el.userId === e.userId
                      );
                      if (user) confirmHandle(user);
                    }}
                    key={`taskerList${index}`}
                  >
                    <div className="img--avatar--name">
                      {_.find(favoritesList, (id) => id === el.userId) ? (
                        <IconStar
                          className="book--mark"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeFavorites(favoritesList, el.userId);
                          }}
                          width={16}
                        />
                      ) : (
                        <IconStarLine
                          className="book--mark"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChangeFavorites(favoritesList, el.userId);
                          }}
                          width={16}
                        />
                      )}
                      <img src={el.avatarImgUrl} alt="" />
                      <div className="name">{el.name}</div>
                    </div>
                    <div className="language--pair--list">
                      {_.map(
                        sortingLanguagePairList(el.languagePairList),
                        (lang, index) => (
                          <div
                            className="language--pair"
                            key={`pairList${index}`}
                          >
                            <span>
                              {getLanguageLabelsByCode([lang.startLanguage])[0]
                                ? getLanguageLabelsByCode([
                                    lang.startLanguage
                                  ])[0].label
                                : lang.startLanguage}
                            </span>
                            <IconBulRight width={7} height={18} />
                            <span>
                              {getLanguageLabelsByCode([
                                lang.destinationLanguage
                              ])[0]
                                ? getLanguageLabelsByCode([
                                    lang.destinationLanguage
                                  ])[0].label
                                : lang.destinationLanguage}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ClickAwayListener>,
      document.body
    );
  };

  return {
    closeHandle,
    openHandle,
    Component,
    open
  };
};

const cssComponent = css`
  width: 344px;
  z-index: 9999;
  font-family: 'IBM Plex Sans', sans-serif;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  box-shadow: -2px -2px 4px 0px #00000014;

  .modalCloseButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 4px 14px 0;
    height: 44px;
    .divider {
      width: 1px;
      height: 14px;
      background-color: #ececec;
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      position: relative;
      height: 100%;
      font-size: 14px;
      color: #aaaaaa;
      cursor: pointer;
      svg {
        opacity: 30%;
      }
      &.active {
        color: #242424;
        font-weight: 600;
        svg {
          opacity: 100%;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: -1px;
          right: -1px;
          height: 2px;
          background-color: rgba(36, 36, 36, 1);
        }
      }
    }
    > button {
      margin-left: auto;
      height: 16px;
    }
  }

  .searchContainer {
    position: relative;
    width: 100%;
    .searchCancel {
      position: absolute;
      top: -26px;
      right: 25px;
    }
  }
  .bookmark--text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    padding-left: 14px;
    text-align: left;
    color: #9a9cb5;
    margin-top: 20px;
    &.empty {
      font-size: 12px;
      margin: 18px 0 20px;
    }
  }

  .bookmark--list,
  .tasker--list,
  .search-list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px 0;
    height: calc(100% - 44px);
    background-color: rgba(250, 250, 250, 1);
    > li {
      padding: 4px 14px;
      width: 100%;
      &.active {
        background-color: #f9f4fe;
      }
    }
  }
  .search-list {
    background: #fff;
  }
  .select--manager--list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 30px 0 30px;
    justify-content: flex-start;
    gap: 8px;
    max-height: 250px;
    min-width: 380px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 7px;
      height: 10px;
      margin: 50px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
      margin-left: 10px;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }
  }
  .manager--list {
    max-height: 250px;
    margin-left: 6px;
    > li {
      position: relative;
      &:before {
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        border: 2px solid #d5d3e4;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        top: 15px;
        background: #fff;
      }
      &.active {
        background-color: #f9f4fe;
        &:before {
          display: block;
          width: 16px;
          height: 16px;
          content: '';
          border: 4px solid #fff;
          border-radius: 50%;
          position: absolute;
          right: 10px;
          top: 15px;
          background: #9b51e0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  ul {
    overflow-y: auto;
    margin-right: -20px;
    padding-right: 20px;
    ::-webkit-scrollbar {
      width: 7px;
      height: 10px;
      margin: 50px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d5d3e4;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
      margin-left: 10px;
    }

    margin-right: -26px;
    padding-right: 26px;
    ::-webkit-scrollbar {
      width: 6px;
    }
    li {
      display: flex;
      flex-direction: column;
      width: 320px;
      padding: 4px 0;
      :hover {
        background-color: #f9f4fe;
        cursor: pointer;
      }
      .img--avatar--name {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        .book--mark {
          margin-right: 8px;
          /* position: absolute;
          right: 10px; */
        }
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 12px;
        }
        .name {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.25px;
          text-align: center;
          color: #242424;
        }
      }
      .language--pair--list {
        color: #727272;
        margin-left: 42px;
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        .language--pair {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          width: fit-content;
          padding: 0 16px;
          border-radius: 8px;
          color: #646782;
          font-size: 14px;
        }
      }
    }
  }

  .button--container {
    display: flex;
    justify-content: center;
    gap: 18px;
    margin-top: 40px;
    width: 100%;
  }
  .assignCountry {
    max-height: 200px;
    padding-right: 44px;
  }
`;
