import api from '@api/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { searchFilter } from '@hooks/queryKeyFactory';
import { useToast } from '@hooks/modal/useToast';
import { Wrap } from '@hooks/searchFilter/index.style';
import { IFilterData, pageType } from '@src/common/config/ICode';

/**
 * /searchFilter?pageType=${pageType}
 * @description 페이지 리스트 필터 정보 조회
 * @param {string} pageType
 * @returns {{ setLanguageSubTaskId, subTaskObj, isSuccess}}
 */
export const useGetFilter = (pageType: pageType, label?: string) => {
  const { openHandle: toastHandle } = useToast();
  const queryClient = useQueryClient();

  const getData = async (): Promise<IFilterData> =>
    (await api.getPms(`/searchFilter?pageType=${pageType}`)).data;

  const postData = async (filterData: any) => {
    return await api.postPms(`/searchFilter`, { pageType, filterData });
  };

  const deleteData = async () => {
    return await api.deletePms(`/searchFilter?pageType=${pageType}`);
  };

  const {
    data = { pageType, filterData: null },
    isLoading,
    isSuccess
  } = useQuery([...searchFilter[`${pageType}`]], getData);

  const { filterData } = data;

  const { mutate: postFilter } = useMutation({
    mutationFn: postData,
    onSuccess: (variables) => {
      queryClient.invalidateQueries([...searchFilter[`${pageType}`]]);
      toastHandle({
        text: `현재 ${label ? label : '필터링'}이 저장되었습니다.`,
        severity: 'success'
      });
    },
    onError: (error) => {
      toastHandle({
        text: `현재 ${label ? label : '필터링'}이 저장에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });

  const { mutate: deleteFilter } = useMutation({
    mutationFn: deleteData,
    onSuccess: (variables) => {
      // queryClient.setQueryData([...searchFilter[`${pageType}`]], () => ({
      //   filterData: variables
      // })); // UI 필터링 초기화 및 새로고침
      queryClient.invalidateQueries([...searchFilter[`${pageType}`]]); // [필터링 저장 버튼] 상태만 초기화
      toastHandle({
        text: `${label ? label : '필터링'}이 초기화되었습니다.`,
        severity: 'success'
      });
    },
    onError: (error) => {
      toastHandle({
        text: `현재 ${label ? label : '필터링'} 초기화에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });

  const onClick = (getFilterData: any) => {
    if (filterData !== null) {
      deleteFilter();
      if (getFilterData.setResetState) {
        getFilterData.setResetState();
      }
      localStorage.removeItem('isFilter');
    } else {
      // console.debug('postFilter', getFilterData);
      postFilter({ ...getFilterData });
    }
  };

  const Component = ({
    getFilterData
  }: {
    getFilterData: any;
  }): JSX.Element => (
    <Wrap
      className="filter-save-reset-button"
      onClick={() => onClick(getFilterData)}
      isThereSavedInfo={filterData !== null}
    >
      {filterData !== null
        ? `${label ? label : '필터링'} 저장 초기화`
        : `현재 ${label ? label : '필터링'} 저장`}
    </Wrap>
  );

  return { isLoading, isSuccess, filterData, Component };
};
