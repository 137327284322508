import { Button } from '@mui/material';
import { css } from '@emotion/react';
import { Color } from '@common/styles/color';

export const TaskSplitStyle = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 20px 30px;
  width: 756px;
  max-height: calc(100% - 40px);
  background-color: #fff;
  text-align: center;
  .alertClose {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .segment--wrap {
    padding: 0 16px;
  }
  .title {
    margin-bottom: 55px;
  }
  .warning--message {
    font-size: 12px;
    color: #e6311a;
  }
  .filebox {
    margin-bottom: 12px;
    padding: 10px 16px;
    background-color: #fafafa;
    > .filebox--title {
      display: flex;
      align-items: center;
      gap: 8px;
      > .filebox--status {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #727272;
      }
      > .filebox--language {
        display: flex;
        gap: 4px;
        font-weight: 600;
      }
    }
    > .filebox--content {
      text-align: left;
      color: #727272;
    }
  }
  .modal--div--wrapper {
    padding: 8px 0;
    text-align: left;
    > strong {
      font-weight: 400;
      > .required {
        margin-left: 3px;
        color: #e6311a;
      }
    }
    .fileInfo {
      display: flex;
      gap: 12px;
      align-items: center;
      span {
        padding: 0 4px;
        height: 19px;
        font-weight: 500;
        font-size: 12px;
        color: #727272;
        background-color: #f3f3f3;
        border-radius: 2px;
      }
      p {
        flex: 1;
      }
    }
    .modal--div--checkbox {
      display: flex;
      justify-content: flex-start;
      gap: 28px;
      > .disabled {
        opacity: 0.5;
      }
    }
    .split--option {
      display: flex;
      gap: 12px;
      > input {
        width: 63px;
        height: 40px;
        border: 1px solid #ececec;
        border-radius: 2px;
        text-align: center;
        box-sizing: border-box;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type='number'] {
          -moz-appearance: textfield;
        }
        &:disabled {
          color: #aaaaaa;
        }
      }
      > button {
        width: 82px;
        height: 40px;
        line-height: 42px;
        color: #aaaaaa;
        border: 1px solid #cbcbcb;
        border-radius: 2px;
        &.active {
          color: #242424;
        }
      }
    }
    .file--length {
      display: flex;
      gap: 10px;
      > p {
        position: relative;
        &:first-of-type {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -6px;
            transform: translateY(-50%);
            width: 2px;
            height: 2px;
            background-color: #cbcbcb;
            border-radius: 50%;
          }
        }
        > span {
          margin-right: 3px;
          color: #727272;
        }
      }
    }
  }
  .modal--div--box {
    margin-bottom: 16px;
    width: 100%;

    .reset--wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 8px;
      .warning--message {
        margin-right: auto;
      }
      > .re--split {
        margin-right: 10px;
        font-size: 12px;
        color: #eb760a;
      }
      > button {
        padding: 0 14px;
        height: 32px;
        line-height: 33px;
        border: 1px solid #ececec;
        border-radius: 2px;
        box-sizing: border-box;
      }
    }
    .segment--table--wrapper {
      overflow: auto;
      max-height: 300px;
      border-top: 1px solid #ececec;
      &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
        margin: 50px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(213, 211, 228);
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
        margin-left: 10px;
      }
      .segment--table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        > thead {
          position: sticky;
          top: -1px;
        }
        th {
          padding: 8px 0 6px;
          font-weight: 400;
          color: #727272;
          background-color: #fff;
        }
        td {
          padding: 6px 0;
          > .range--input--wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            > input {
              width: 91px;
              height: 40px;
              border: 1px solid #ececec;
              border-radius: 2px;
              text-align: center;
              box-sizing: border-box;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              &[type='number'] {
                -moz-appearance: textfield;
              }
              &:disabled {
                color: #aaaaaa;
              }
            }
          }
          .warning--message {
            margin-top: 4px;
            padding-left: 3px;
            height: 16px;
            line-height: 16px;
            text-align: left;
          }
        }
      }
    }
  }
`;
