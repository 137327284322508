import { ILanguageTask } from '@src/common/config/IProjectDetail';

export const authKeys = {
  emailValidation: ['/auth', '/email-validation'] as const,
  authentication: ['/auth', 'authentication'] as const,
  authorizationGoogle: ['/auth', '/authorization/google'] as const,
  authorizationPrivacyAgree: ['/auth', '/authorization/privacyAgree'] as const,
  authorizationGoogleUserInfo: [
    'https://www.googleapis.com/oauth2/v2/userinfo'
  ] as const,
  passwordReset: ['/auth', '/password-reset'] as const
};

export const languageKeys = ['/language'] as const;

export const myWorkKeys = {
  default: ['/myWork'] as const,
  get: (languageSubTaskId: string) =>
    [...myWorkKeys.default, languageSubTaskId] as const,
  post: ['/myWork', 'post'] as const,
  count: ['/myWork', '/count'] as const,
  detail: ['/myWork', '/detail'] as const,
  lists: ['/myWork', '/list'] as const,
  list: (filters: {
    languagePairList: ILanguageTask[];
    taskTitle: string;
    myWorkFilter: { [key: string]: any[] };
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => [...myWorkKeys.lists, filters] as const
};

export const languageTaskKeys = {
  get: (languageTaskId: string) => ['/languageTask', languageTaskId] as const,
  assignInfo: (languageTaskId: string) =>
    ['/languageTask', '/assignInfo', languageTaskId] as const,
  assignWorker: ['/languageTask', '/assignWorker'] as const,
  searchWorker: ['/languageTask', '/assignWorker', 'keyword'] as const,
  count: (language: string) => ['/languageTask', '/count', language] as const,
  languageByCount: ['/myWork', '/languageByCount'] as const,
  workerList: ['languageTask', '/managerList'] as const,
  lists: ['/languageTask', '/list'] as const,
  list: (filters: {
    languagePairList: ILanguageTask[];
    taskTitle: string;
    taskFilter: { [key: string]: any[] };
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => [...languageTaskKeys.lists, filters] as const,
  listByDays: ['/languageTask', '/list'] as const,
  listByDay: (label: string, type: string, filter: any) =>
    [...languageTaskKeys.listByDays, label, type, filter] as const,
  tabCount(filter: any) {
    return ['/languageTask', '/tabCount', filter] as const;
  }
};

export const taskManagerKeys = {
  projectSimpleAllList: ['/project', '/projectListByUpload'] as const,
  list: (filter: { projectId: string; keyword: string }) =>
    ['/languageTask', '/taskManagerList', filter] as const
};

export const statisticsProjectKeys = {
  statisticsProjectList: [
    '/dashboard/partperformance',
    '/dashboard/workerperformance'
  ] as const
};

export const statisticsCustomerKeys = {
  statisticsCustomerList: ['/dashboard/workretention'] as const
};

export const myInfoKeys = {
  get: ['/myInfo'] as const
};

//todo
//#region 주석범위 내부만 작업 완료
export const projectKeys = {
  count: ['/project', '/count'] as const,
  pmList: ['/project', '/pmList'] as const,
  allPmList: ['/project', '/allPmList'] as const,
  assign: ['/project', '/assign'] as const,
  status: ['/project', '/status'] as const,
  lists: ['/project', '/list'] as const,
  //filters Interface 정의 필요
  list: (filters: {
    projectStatus: string[];
    dateType: string;
    projectAssignerUserIdList: string[];
    projectManagerUserIdList: string[];
    dateFilter: { [key: string]: any };
    projectTitle: string;
  }) => [...projectKeys.lists, filters] as const
};

export const projectDetailKeys = {
  default: ['/projectDetail'] as const,
  info: (id: string) => [...projectDetailKeys.default, id] as const,
  list: (filter: {
    projectId: string;
    taskTitle: string;
    taskFilter: { [key: string]: any[] };
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => [...projectDetailKeys.default, filter] as const,
  workerList: (id: string) => ['/projectDetail', '/workerList', id] as const
};
//#endregion

export const taskKeys = {
  get: (id: string) => ['/task', 'get', id] as const,
  post: ['/task', 'post'] as const,
  put: ['/task', 'put'] as const,
  putStatus: ['/task', '/status'] as const,
  languageSpecList: ['/task', '/languageSpecList'] as const,
  delete: (id: any) => ['/task', 'delete', id] as const,
  status: (id: any) => ['/task', '/status', id] as const
};

export const searchFilter = {
  DASHBOARD: ['/searchFilter', '?pageType=DASHBOARD'] as const,
  PROJECT: ['/searchFilter', '?pageType=PROJECT'] as const,
  PROJECT_DETAIL: ['/searchFilter', '?pageType=PROJECT_DETAIL'] as const,
  TASK: ['/searchFilter', '?pageType=TASK'] as const,
  TASK_TAB: ['/searchFilter', '?pageType=TASK_TAB'] as const,
  MY_WORK: ['/searchFilter', '?pageType=MY_WORK'] as const,
  MY_WORK_TAB: ['/searchFilter', '?pageType=MY_WORK_TAB'] as const,
  ALL_TASK_SEARCH: ['/searchFilter', '?pageType=ALL_TASK_SEARCH'] as const,
  ALL_TASK_LIST: ['/searchFilter', '?pageType=ALL_TASK_LIST'] as const,
  COMPANY_PER_PROJECT: [
    '/searchFilter',
    '?pageType=COMPANY_PER_PROJECT'
  ] as const,
  SATURATION_WORKER: ['/searchFilter', '?pageType=SATURATION_WORKER'] as const,
  SATURATION_WORD: ['/searchFilter', '?pageType=SATURATION_WORD'] as const,
  WORD_AMOUNT_WORD: ['/searchFilter', '?pageType=WORD_AMOUNT_WORD'] as const,
  WORD_AMOUNT_WORKER: [
    '/searchFilter',
    '?pageType=WORD_AMOUNT_WORKER'
  ] as const,
  PART_PERFORMANCE: ['/searchFilter', '?pageType=PART_PERFORMANCE'] as const,
  WORKER_PERFORMANCE: [
    '/searchFilter',
    '?pageType=WORKER_PERFORMANCE'
  ] as const,
  WORKER_RESOURCE: ['/searchFilter', '?pageType=WORKER_RESOURCE'] as const,
  TASK_RETENTION: ['/searchFilter', '?pageType=TASK_RETENTION'] as const
};

export const favoritesKey = {
  default: ['/favorites'] as const
};

export const dashboardKeys = {
  allList: (filters: {
    keyword: string;
    taskStatus: string[];
    taskType: string[];
    startLanguageList: string[];
    destinationLanguageList: string[];
    dateType: string;
    startDateTime?: Date;
    endDateTime?: Date;
    searchDateType: string;
    searchStartDateTime: string | Date;
    searchEndDateTime: string | Date;
    projectManagerUserIdList: string[];
    workUserIdList: string[];
  }) => ['/task', '/allList', filters] as const,
  pmList: ['/task', '/managerList'] as const,
  count: (filters?: any) => ['/task', '/dashboard', filters] as const
};

export const companyPerKeys = {
  wordGraph: ['/companyPerProjectWordAmountGraph'] as const,
  taskGraph: ['/companyPerProjectTaskCountGraph'] as const,
  list: (filters: {
    keyword: string;
    projectStatusList: string[];
    projectContractTypeList: string[];
    pmUserList: string[];
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => ['/companyPerProjectList', filters] as const,
  pmList: ['/statistics', '/pmList'] as const
};

export const saturationKeys = {
  get postDegree() {
    return ['/workerPerSaturationDegree'];
  },
  get getDegree() {
    return ['/wordPerSaturationDegree'];
  },
  get getAllAssignWorker() {
    return ['/allAssignWorker'];
  },
  get holiday() {
    return ['/workerPerHoliday'];
  }
};
export const calenderKeys = {
  get holiday() {
    return ['/holidays'];
  }
};

export const wordAmountKeys = {
  workerlist: (filters: {
    languagePairList: ILanguageTask[];
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => ['/workerPerWordAmount', filters] as const,
  languagelist: (filters: {
    languagePairList: ILanguageTask[];
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => ['/languagePerWordAmount', filters] as const
};

export const fileManagementKeys = {
  list: ['/file/list'] as const
};
