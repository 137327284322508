import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { TypeCategory } from '@src/common/config/Code';
import RowStatusBadge from '@src/pages/components/RowStatusBadge';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  renderDateTime,
  renderLanguages,
  renderOption,
  renderUsers
} from '../utils/render';
import ModalType from '@src/common/config/ModalType';
import Row3LanguageTaskList from './Row3LanguageTaskList';
import { useUpsertTask } from '@src/hooks/task/post';
import { useAlert } from '@src/hooks/modal/useAlert';
import RowStatusBadge2 from '@src/pages/components/RowStatusBadge2';
import { css } from '@emotion/css';

// 2
export function Row2TaskItem({
  label,
  filterData,
  item, // taskData
  setOpenedJobModifyPage,
  setOpenedJobRegisterPage,
  setOpenedJobDetailPage,
  filterExposure,
  isFilter
}: any) {
  const [open, setOpen] = useState(false);
  const to = useNavigate();
  const { putTaskStatus } = useUpsertTask();
  const { openHandle: alertHandle } = useAlert();

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    to(`/dashboard/${ModalType.ASSIGNALERT_MODAL}/${Number(item.taskId)}`, {
      state: {}
    });
  };

  return (
    <tbody>
      <Row2TaskItemWrap
        className={cx({ 'row-task-item': !open })}
        data-task-id={item?.taskId}
      >
        <td data-task-status={item.taskStatus}>
          <div>
            <RowStatusBadge
              hide
              statusCode={item.taskStatus}
              {...(item?.subDepthYn
                ? {
                    open,
                    setOpen
                  }
                : {})}
            >
              {item?.subDepthYn &&
                item?.taskStatus !== 'STOP' &&
                item?.taskStatus !== 'DELIVERY_COMPLETE' && (
                  <>
                    {/* {item?.taskStatus === 'STOP' && <>작업중</>} */}
                    {item?.taskStatus === 'COMPLETE' && (
                      <div
                        className="item"
                        onClick={() => {
                          const payload = {
                            taskId: item.taskId,
                            taskStatus: 'DELIVERY_COMPLETE'
                          };
                          alertHandle({
                            title: '상태 변경',
                            text: '상태를 변경하시겠습니까?',
                            cancelLabel: '취소',
                            ok: () => {
                              putTaskStatus(payload);
                            }
                          });
                        }}
                      >
                        납품완료
                      </div>
                    )}
                    {item?.taskStatus !== 'COMPLETE' && (
                      <div
                        className="item"
                        onClick={() => {
                          const payload = {
                            taskId: item.taskId,
                            taskStatus: 'STOP'
                          };
                          alertHandle({
                            title: '상태 변경',
                            text: '상태를 변경하시겠습니까?',
                            cancelLabel: '취소',
                            ok: () => {
                              putTaskStatus(payload);
                            }
                          });
                        }}
                      >
                        중지
                      </div>
                    )}
                    {/* BORACAT-697 [작업 완료]일 경우 [중지, 납품 완료] 에서 [납품 완료]로 변경 */}
                  </>
                )}
            </RowStatusBadge>
            <RowStatusBadge2
              status={item.taskStatus}
              rowData={item}
              id={item.taskId}
              statusChangeMap={{
                NEW: ['STOP'],
                COMPLETE_ASSIGN: ['STOP'],
                PROGRESS: ['STOP'],
                COMPLETE: ['DELIVERY_COMPLETE'], // BORACAT-697
                STOP: []
              }}
              style={{
                cursor: 'pointer'
              }}
            />
          </div>
        </td>
        <td
          className="title wrap"
          title={item.taskTitle}
          data-task-key={item.taskKey}
          onClick={() => {
            const { projectId, taskId } = item;
            window.history.replaceState({ projectId, taskId }, '');
            setOpenedJobDetailPage(true);
          }}
        >
          {item.taskTitle}
        </td>
        <td className="wrap">{item.companyName}</td>
        <td
          className="wrap"
          style={{
            cursor: 'pointer'
          }}
          onClick={() => {
            to(`/project/${item.projectId}`);
          }}
        >
          {item.projectTitle}
        </td>
        <td>
          <div className="taskType">
            {TypeCategory[item.taskType] as string}
          </div>
        </td>
        <td>{renderLanguages(item.startLanguageList)}</td>
        <td>{renderLanguages(item.destinationLanguageList)}</td>
        <td>
          {Array.isArray(item.totalWorkerList) &&
          item.totalWorkerList.length > 0 &&
          item.taskStatus !== 'NEW' ? (
            <TPAvatarGroupNew userList={item.totalWorkerList} />
          ) : (
            <button className={cssButton} onClick={clickHandler}>
              배정요청
            </button>
          )}
        </td>
        <td>{renderDateTime(item.startDateTime, false)}</td>
        <td>
          {renderDateTime(
            item.endDateTime,
            item.taskStatus !== 'DELIVERY_COMPLETE'
          )}
        </td>
        <td>
          {item.taskType === 'LQA'
            ? `${Math.floor(Number(item.totalGrossAmount) / 250)}시간 ${
                Number(item.totalGrossAmount) % 250 > 0 ? '30' : '00'
              }분`
            : `${item.totalGrossAmount}`}
        </td>
        <td>
          {item.taskType === 'LQA'
            ? `${Math.floor(Number(item.totalNetAmount) / 250)}시간 ${
                Number(item.totalNetAmount) % 250 > 0 ? '30' : '00'
              }분`
            : `${item.totalNetAmount}`}
        </td>
        <td>
          {renderDateTime(
            item.hopeSupplyDateTime,
            item.taskStatus !== 'DELIVERY_COMPLETE'
          )}
        </td>
        <td className="pm-list">
          {renderUsers(item.mainProjectManager)}
          {renderUsers(item.subProjectManager)}
        </td>
        <td
          style={{
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          {renderOption({
            ...item, // projectId, taskId, taskStatus
            setOpenedJobModifyPage,
            setOpenedJobRegisterPage,
            setOpenedJobDetailPage
          })}
        </td>
      </Row2TaskItemWrap>
      {open && (
        <Row3LanguageTaskList
          {...{
            taskType: item.taskType,
            filterData,
            item,
            filterExposure,
            isFilter
          }}
        />
      )}
    </tbody>
  );
}

const Row2TaskItemWrap = styled.tr`
  &:hover {
    background-color: red;
  }

  .deeps-control {
    cursor: pointer;
    & > svg {
      transform: translate(6px, 0px) rotate(-90deg);
      &.open {
        transform: translate(6px, 0px) rotate(0deg);
      }
    }
  }
  td[data-task-status] {
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      .item:hover {
        background-color: #f5f5f5;
      }

      & > *:first-of-type {
        padding-left: 14px;
        padding-right: 0;
      }
      & > *:last-of-type {
        padding-left: 0;
        padding-right: 14px;
      }
    }
  }

  td[data-task-key] {
    cursor: pointer;
    text-align: left;
    vertical-align: middle;
  }

  td.title {
    text-align: left;
    vertical-align: middle;
  }

  .wrap {
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
  }

  .taskType {
    width: auto;
    display: inline-block;
    padding: 4px 2px;
    border-radius: 2px;
  }

  & + [class*='Row3LanguageTaskItemWrap'] td {
    .deeps-line {
      &::before {
        content: '';
        width: calc(50% + 4px);
        height: calc(50% + 9px);
        /* border-left: 1px solid #d5d3e4; */
        /* border-bottom: 1px solid #d5d3e4; */
        /* border-radius: 0 0 0 6px; */
        display: block;
        z-index: 0;
        position: absolute;
        left: 50%;
        top: calc(0% + -6px);
      }
    }
  }
`;

const cssButton = css`
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: transparent;
  color: #9b51e0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.25px;
  text-align: center;
  width: 100%;
  height: 100%;
`;
