import React, { useEffect, useState } from 'react';
import api from '@api/Api';
import {
  IWorkData,
  IWorkMonthGraphData,
  IWorkQuarterGraphData,
  IWorkYearGraphData,
  languagePair
} from './WorkRetention.page';
import { IcoMenuChevronDown } from '@src/resources/icon';
import { cx } from '@emotion/css';
import RetentionCompareChart from './RetentionCompareChart';
import { useMutation } from '@tanstack/react-query';

interface ICompareData {
  companyId: number | null;
  projectId: number | null;
  languagePairList: languagePair[];
}

interface IYearAmount {
  year: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
}

interface IGrowthRate {
  jan: number | string;
  feb: number | string;
  mar: number | string;
  apr: number | string;
  may: number | string;
  jun: number | string;
  jul: number | string;
  aug: number | string;
  sep: number | string;
  oct: number | string;
  nov: number | string;
  dec: number | string;
}

export interface ICompanyRetentionData {
  companyId: number;
  companyName: string;
  projectId: number | null;
  projectTitle: string | null;
  thisYearAmount: IYearAmount;
  previousYearAmount: IYearAmount;
  momGrowthRate: IGrowthRate;
  yoyForSameMonthGrowthRate: IGrowthRate;
}

const WorkTable = ({
  retentionData,
  retentionDateType,
  languagePairList
}: {
  retentionData: IWorkData;
  retentionDateType: string;
  languagePairList: languagePair[];
}) => {
  const [tableData, setTableData] = useState<
    IWorkMonthGraphData[] | IWorkYearGraphData[] | IWorkQuarterGraphData[]
  >();
  const [openStates, setOpenStates] = useState<{ [key: string]: boolean }>({});
  const [compareData, setCompareData] = useState<ICompanyRetentionData>();
  const toggleRow = (rowId: string) => {
    setOpenStates((prev) => {
      // 모든 상태를 false로 초기화한 새로운 객체 생성
      const allClosed = Object.keys(prev).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as Record<string, boolean>);

      // 선택된 행만 토글
      return {
        ...allClosed,
        [rowId]: !prev[rowId]
      };
    });
  };

  //colgroup 렌더링
  const renderColGroup = () => {
    const baseCol = <col style={{ width: '20%' }} />;
    const colCounts = {
      MONTH: 13, // 1개 기본 + 12개월
      YEAR: 6, // 1개 기본 + 5년
      QUARTER: 10 // 1개 기본 + 9분기
    };
    const count = colCounts[retentionDateType as keyof typeof colCounts] || 0;
    return (
      <colgroup>
        {baseCol}
        {[...Array(count - 1)].map((_, index) => (
          <col key={index} />
        ))}
      </colgroup>
    );
  };

  //thead 렌더링
  const renderHeader = () => {
    const header = [];
    const firstColumnText =
      tableData?.[0]?.companyName !== null &&
      tableData?.[0]?.projectTitle === null
        ? '고객사'
        : '프로젝트';
    if (retentionDateType === 'MONTH') {
      header.push(
        firstColumnText,
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
      );
    } else if (retentionDateType === 'YEAR') {
      const currentYear = new Date().getFullYear();
      const yearList = Array.from(
        { length: 5 },
        (_, i) => currentYear - i
      ).reverse();
      header.push(firstColumnText, ...yearList);
    } else if (retentionDateType === 'QUARTER') {
      const currentYear = new Date().getFullYear();
      const quarters = [];
      // 최근 9분기 데이터 생성
      for (let i = 0; i < 9; i++) {
        const year = currentYear - Math.floor(i / 4);
        const quarter = 4 - (i % 4);
        // 해당 연도의 첫 분기(Q4)일 때만 연도 표시
        const quarterText =
          quarter === 4 ? `${year} Q${quarter}` : `Q${quarter}`;
        quarters.push(quarterText);
      }

      header.push(firstColumnText, ...quarters.reverse());
    }
    return (
      <tr>
        {header.map((text, index) => (
          <th key={index}>{text}</th>
        ))}
      </tr>
    );
  };

  //tbody 렌더링
  const renderBody = () => {
    if (retentionDateType === 'MONTH') {
      const monthData = tableData as IWorkMonthGraphData[];
      return monthData
        ?.sort((a, b) => {
          return (a.companyName || a.projectTitle || '').localeCompare(
            b.companyName || b.projectTitle || '',
            'ko-KR'
          );
        })
        .map((item, rowIndex) => {
          const rowId = `${item.companyName || item.projectTitle}-${rowIndex}`;
          return [
            <tr key={rowIndex} className={cx({ active: openStates[rowId] })}>
              <td className="text--left">
                <div className="collapse-wrap">
                  <button
                    className="collapse-btn"
                    onClick={() =>
                      toggleGraph(
                        rowId,
                        languagePairList,
                        item.companyId,
                        item.projectId
                      )
                    }
                  >
                    <IcoMenuChevronDown fill="#727272" />
                  </button>
                  {item.companyName || item.projectTitle}
                </div>
              </td>
              <td>{item.jan?.toLocaleString() ?? '-'}</td>
              <td>{item.feb?.toLocaleString() ?? '-'}</td>
              <td>{item.mar?.toLocaleString() ?? '-'}</td>
              <td>{item.apr?.toLocaleString() ?? '-'}</td>
              <td>{item.may?.toLocaleString() ?? '-'}</td>
              <td>{item.jun?.toLocaleString() ?? '-'}</td>
              <td>{item.jul?.toLocaleString() ?? '-'}</td>
              <td>{item.aug?.toLocaleString() ?? '-'}</td>
              <td>{item.sep?.toLocaleString() ?? '-'}</td>
              <td>{item.oct?.toLocaleString() ?? '-'}</td>
              <td>{item.nov?.toLocaleString() ?? '-'}</td>
              <td>{item.dec?.toLocaleString() ?? '-'}</td>
            </tr>,
            openStates[rowId] && (
              <tr key={`${rowIndex}-expanded`} className="expanded-row">
                <td colSpan={13} className="compare--td">
                  {compareData && (
                    <RetentionCompareChart compareData={compareData} />
                  )}
                </td>
              </tr>
            )
          ];
        });
    } else if (retentionDateType === 'YEAR') {
      const yearData = tableData as IWorkYearGraphData[];
      return yearData
        ?.sort((a, b) => {
          return (a.companyName || a.projectTitle || '').localeCompare(
            b.companyName || b.projectTitle || '',
            'ko-KR'
          );
        })
        .map((item, rowIndex) => {
          const rowId = `${item.companyName || item.projectTitle}-${rowIndex}`;
          return [
            <tr key={rowIndex} className={cx({ active: openStates[rowId] })}>
              <td className="text--left">
                <div className="collapse-wrap">
                  <button
                    className="collapse-btn"
                    onClick={() =>
                      toggleGraph(
                        rowId,
                        languagePairList,
                        item.companyId,
                        item.projectId
                      )
                    }
                  >
                    <IcoMenuChevronDown fill="#727272" />
                  </button>
                  {item.companyName || item.projectTitle}
                </div>
              </td>
              <td>{item.fourYearsAgo?.toLocaleString() ?? '-'}</td>
              <td>{item.threeYearsAgo?.toLocaleString() ?? '-'}</td>
              <td>{item.yearBeforeLast?.toLocaleString() ?? '-'}</td>
              <td>{item.lastYear?.toLocaleString() ?? '-'}</td>
              <td>{item.thisYear?.toLocaleString() ?? '-'}</td>
            </tr>,
            openStates[rowId] && (
              <tr key={`${rowIndex}-expanded`} className="expanded-row">
                <td colSpan={6} className="compare--td">
                  {compareData && (
                    <RetentionCompareChart compareData={compareData} />
                  )}
                </td>
              </tr>
            )
          ];
        });
    } else if (retentionDateType === 'QUARTER') {
      const quarterData = tableData as IWorkQuarterGraphData[];
      return quarterData
        ?.sort((a, b) => {
          return (a.companyName || a.projectTitle || '').localeCompare(
            b.companyName || b.projectTitle || '',
            'ko-KR'
          );
        })
        .map((item, rowIndex) => {
          const rowId = `${item.companyName || item.projectTitle}-${rowIndex}`;
          const quarters =
            item.quarterList?.flatMap((year) => [
              year.firstQuarter,
              year.secondQuarter,
              year.thirdQuarter,
              year.fourthQuarter
            ]) || [];
          const lastNineQuarters = quarters.slice(-9);
          return [
            <tr key={rowIndex} className={cx({ active: openStates[rowId] })}>
              <td className="text--left">
                <div className="collapse-wrap">
                  <button
                    className="collapse-btn"
                    onClick={() =>
                      toggleGraph(
                        rowId,
                        languagePairList,
                        item.companyId,
                        item.projectId
                      )
                    }
                  >
                    <IcoMenuChevronDown fill="#727272" />
                  </button>
                  {item.companyName || item.projectTitle}
                </div>
              </td>
              {lastNineQuarters.map((value, index) => (
                <td key={index}>{value?.toLocaleString() ?? '-'}</td>
              ))}
            </tr>,
            openStates[rowId] && (
              <tr key={`${rowIndex}-expanded`} className="expanded-row">
                <td colSpan={10} className="compare--td">
                  {compareData && (
                    <RetentionCompareChart compareData={compareData} />
                  )}
                </td>
              </tr>
            )
          ];
        });
    }
  };

  const { mutate: compareDataMutation } = useMutation({
    mutationFn: (payload: ICompareData) =>
      api.postPms(`/retention/compare`, payload),
    onSuccess: (res) => {
      setCompareData(res.data);
    },
    onError: (error) => {
      console.error('비교 데이터 조회 실패:', error);
    }
  });

  const toggleGraph = (
    rowId: string,
    languagePairList: languagePair[],
    companyId: number | null,
    projectId: number | null
  ) => {
    toggleRow(rowId);
    compareDataMutation({ companyId, projectId, languagePairList });
  };

  useEffect(() => {
    if (retentionDateType === 'MONTH') {
      setTableData(retentionData.monthlyRetentionList);
    } else if (retentionDateType === 'YEAR') {
      setTableData(retentionData.yearlyRetentionList);
    } else if (retentionDateType === 'QUARTER') {
      setTableData(retentionData.quarterlyRetentionList);
    }
    setOpenStates({});
  }, [retentionData, retentionDateType]);

  return (
    <div className="table--wrap">
      <table>
        {renderColGroup()}
        <thead>{renderHeader()}</thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

export default WorkTable;
