import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { addYears, format } from 'date-fns';
import { DayPicker, DateFormatter } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { CalendarWrap, CalendarStyle } from '@hooks/modal/Calendar.style';
import { IconModalClose, IconWatch, IconInputCheck } from '@resources/icon';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { globalKeys } from '../queryKeyFactory';
import _ from 'lodash';
import { ISingleCalendarState } from '@src/common/config/ICode';
import TPButtonClose from '@src/units/button/TPButton.Close';
import useTimeInput from '../datetime/useTimeInput';

/**
 * 달력 caption format (년. 월)
 * @type {(month: Date) => JSX.Element}
 * @param month
 */
const formatCaption: DateFormatter = (month) => (
  <>
    {`${month.getFullYear()}. ${month.getMonth() + 1 <= 9 ? 0 : ''}${
      month.getMonth() + 1
    }`}
  </>
);

/**
 * 달력 요일 format (Mo, Tu, We, Th, Fr, Sa, Su)
 * @type {(weekday: Date) => JSX.Element}
 * @param weekday
 */
const formatWeekdayName: DateFormatter = (weekday) => (
  <>{format(weekday, 'eeeeee')}</>
);

/**
 * datePicker hook
 * @type {(props?: ISingleCalendarState) => {resultDate: Date; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useSelectCalendar = (props?: ISingleCalendarState) => {
  //#region props
  const {
    fromLimit,
    toLimit,
    timeOptions = []
  } = props?.dateLimit ?? {
    fromLimit: addYears(new Date(), -1),
    toLimit: addYears(new Date(), 1),
    timeOptions: [] // 기본값 설정
  };
  //#endregion

  //#region global state
  const calenderData = useGlobalValue([...globalKeys.singleCalendar], {
    open: false,
    dateType: '',
    date: undefined,
    setDate: () => {}
  });

  const { date, setDate } = calenderData;

  const modalFetcher = useSetGlobalState([...globalKeys.singleCalendar]);
  //#endregion

  const openHandle = (
    dateType: string,
    date: Date | undefined,
    setDate: (el?: Date) => void
  ) => modalFetcher({ ...calenderData, open: true, date, dateType, setDate });

  const closeHandle = () => {
    modalFetcher({ calenderData, open: false });
  };

  //#region handler
  const clickConfirm = (date: Date | undefined, time: string) => {
    let dateStr = date ? format(new Date(date), 'yyyy-MM-dd') : '';
    setDate(new Date(`${dateStr} ${time}`));
    closeHandle();
  };
  //#endregion

  const Component = (): JSX.Element => {
    const nowDate = date ? new Date(date) : new Date();
    const [hour, minute] = _.map(
      [nowDate.getHours(), nowDate.getMinutes()],
      (time) => (time < 10 ? `0${time}` : time.toString())
    );
    const defaultTime = date ? `${hour}:${minute}` : '';
    const timeInputRef = useRef<HTMLInputElement>(null);
    const { value: time } = useTimeInput({
      defaultTime,
      timeInputRef
    });
    const [selectDate, setSelectDate] = useState<Date | undefined>(nowDate);
    const [selectState, setSelectState] = useState(false);
    const [selectTime, setSelectTime] = useState(timeOptions[0]);
    const [dateState, setDateState] = useState(true);

    useEffect(() => {
      if (selectDate) {
        const combinedDate = new Date(
          `${selectDate.toISOString().split('T')[0]}T${selectTime}`
        );
        const newDate = new Date();
        const isValidDate = newDate <= combinedDate;
        setDateState(isValidDate);
      } else {
        setDateState(false);
      }
    }, [selectDate, selectTime]);

    return (
      calenderData.open && (
        <div css={CalendarWrap} className={`single`}>
          <div className="top">
            <TPButtonClose className="close-button" onClick={closeHandle} />
          </div>
          {/* <div className="middle">
            <DayPicker
              formatters={{ formatCaption, formatWeekdayName }}
              css={CalendarStyle}
              mode={'single'}
              numberOfMonths={1}
              selected={selectDate}
              weekStartsOn={0}
              onSelect={setSelectDate}
              fromDate={fromLimit ? new Date(fromLimit) : undefined}
              toDate={toLimit ? new Date(toLimit) : undefined}
            />
          </div> */}
          <div className="middle">
            <DayPicker
              formatters={{ formatCaption, formatWeekdayName }}
              css={CalendarStyle}
              mode={'single'}
              numberOfMonths={1}
              selected={selectDate}
              weekStartsOn={0}
              onSelect={(date) => {
                if (date) {
                  const utcDate = new Date(
                    Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate()
                    )
                  );
                  // 이미 선택된 날짜를 다시 클릭하면 해제
                  if (
                    selectDate &&
                    selectDate.getTime() === utcDate.getTime()
                  ) {
                    setSelectDate(undefined);
                  } else {
                    setSelectDate(utcDate);
                  }
                } else {
                  setSelectDate(undefined); // 날짜 선택을 해제할 때
                }
              }}
              fromDate={
                fromLimit
                  ? new Date(
                      Date.UTC(
                        new Date(fromLimit).getFullYear(),
                        new Date(fromLimit).getMonth(),
                        new Date(fromLimit).getDate()
                      )
                    )
                  : undefined
              }
              toDate={
                toLimit
                  ? new Date(
                      Date.UTC(
                        new Date(toLimit).getFullYear(),
                        new Date(toLimit).getMonth(),
                        new Date(toLimit).getDate()
                      )
                    )
                  : undefined
              }
            />
          </div>
          <div className="bottom">
            {!dateState && (
              <p className="timeValidation leftSide">
                *선택한 시간이 자났습니다.
              </p>
            )}
            <label className="select-Deadline-Time">
              <IconWatch className="IconWatch" />
              <p
                className="selectTitle"
                onClick={() => setSelectState(!selectState)}
              >
                {selectTime}
              </p>
              {selectState && (
                <ul className="selectList">
                  {timeOptions.map((item: string, index: number) => (
                    <li
                      key={index}
                      onClick={() => {
                        setSelectTime(item);
                        setSelectState(false);
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </label>
          </div>
          <div className="buttonWrap">
            <TPButtonBasic
              fullWidth
              label="취소"
              variant="outlined"
              onClick={closeHandle}
            />
            <TPButtonBasic
              fullWidth
              disabled={!selectDate || !dateState}
              label="확인"
              variant="contained"
              onClick={() => {
                clickConfirm(selectDate, selectTime);
              }}
            />
          </div>
        </div>
      )
    );
  };

  return {
    closeHandle,
    openHandle,
    Component
  };
};
