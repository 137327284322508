import React, { useEffect, useState } from 'react';
import { IWorkData, IWorkQuarterGraphData } from './WorkRetention.page';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Props as LegendContentProps } from 'recharts/types/component/DefaultLegendContent';

type QuarterData = {
  baseYear: number;
  firstQuarter: number;
  secondQuarter: number;
  thirdQuarter: number;
  fourthQuarter: number;
};

interface ITransformedData {
  name: string;
  [key: string]: number | string | undefined;
}

const WorkMonthGraph = ({ retentionData }: { retentionData: IWorkData }) => {
  const [graphData, setGraphData] = useState<IWorkQuarterGraphData[]>();
  const [transformedData, setTransformedData] = useState<ITransformedData[]>();

  const renderLegend = (props: LegendContentProps) => {
    const { payload } = props;
    return (
      <ul className="legend--list">
        {payload?.map((entry, index) => (
          <li key={`item-${index}`}>
            <span style={{ backgroundColor: entry.color }}></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    const sortedGraphData = [
      ...(retentionData.quarterlyRetentionList || [])
    ].sort((a, b) => {
      const aName = (a.companyName || a.projectTitle || '').toLowerCase();
      const bName = (b.companyName || b.projectTitle || '').toLowerCase();
      return aName.localeCompare(bName, ['ko-KR', 'en-US']);
    });
    setGraphData(sortedGraphData);
  }, [retentionData]);

  useEffect(() => {
    if (!graphData) return;

    // 먼저 모든 분기 데이터의 기준이 되는 기간을 생성
    const allQuarters = graphData.reduce((quarters: string[], item) => {
      item.quarterList.forEach((q) => {
        const baseQuarters = [
          `${q.baseYear}<br/>Q1`,
          `${q.baseYear}<br/>Q2`,
          `${q.baseYear}<br/>Q3`,
          `${q.baseYear}<br/>Q4`
        ];
        baseQuarters.forEach((quarter) => {
          if (!quarters.includes(quarter)) {
            quarters.push(quarter);
          }
        });
      });
      return quarters.sort();
    }, []);

    // 각 회사/프로젝트별 데이터 매핑
    const transformedData = allQuarters
      .map((quarterLabel) => {
        const [year, quarter] = quarterLabel.split('<br/>');
        const quarterMap: Record<string, keyof QuarterData> = {
          Q1: 'firstQuarter',
          Q2: 'secondQuarter',
          Q3: 'thirdQuarter',
          Q4: 'fourthQuarter'
        } as const;
        const result: ITransformedData = { name: quarterLabel };
        let hasValidData = false;

        graphData.forEach((item) => {
          const companyName = item.companyName || item.projectTitle;
          if (companyName) {
            const quarterData = item.quarterList.find(
              (q) => q.baseYear === parseInt(year)
            );
            const value =
              quarterData?.[quarterMap[quarter as keyof typeof quarterMap]];
            if (value !== null && value !== undefined) {
              result[companyName] = value;
              hasValidData = true;
            }
          }
        });

        return hasValidData ? result : null;
      })
      .filter((item): item is ITransformedData => item !== null);

    setTransformedData(transformedData);
  }, [graphData]);

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={transformedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="1" vertical={false} />
          <XAxis
            dataKey="name"
            type="category"
            tickFormatter={(value) => value.replace('<br/>', '\n')}
            height={60}
            tick={(props) => {
              const { x, y, payload, index } = props;
              const [year, quarter] = payload.value.split('<br/>');
              const prevItem = index > 0 ? transformedData?.[index - 1] : null;
              const prevYear = prevItem?.name.split('<br/>')[0];

              // 년도는 이전 데이터와 년도가 다를 때만 표시
              const shouldShowYear = year !== prevYear;

              return (
                <g transform={`translate(${x},${y})`}>
                  <text x={0} y={0} dy={12} textAnchor="middle">
                    {quarter}
                  </text>
                  {shouldShowYear && (
                    <text x={0} y={20} dy={8} textAnchor="middle">
                      {year}
                    </text>
                  )}
                </g>
              );
            }}
          />

          <Tooltip
            formatter={(value: string, name: string) => [value, name]}
            labelFormatter={(label) => label.replace('<br/>', ' ')}
          />
          <Legend iconType="circle" iconSize={10} content={renderLegend} />
          {graphData?.map((item, index) => (
            <Line
              type="linear"
              dataKey={(item.companyName || item.projectTitle) as string}
              stroke={`hsl(${index * 100}, 50%, 50%)`}
              strokeWidth={2}
              key={item.companyName || item.projectTitle}
              animationBegin={1}
              animationDuration={1}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WorkMonthGraph;
