import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconArrowRightBlack } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box } from '@mui/material';
import { usePostFile } from '@hooks/file/postFile';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FileUploadStyle } from './FileUpload.style';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPInputCheckBoxBasic } from '@src/units/input/TPInput.checkBoxBasic';
import { renderRowStatus } from '@src/pages/dashboard/utils/render';
import { LANGUAGE } from '@src/common/config/Code';
import { useGetTaskInfo } from '@hooks/file/getTaskInfo';

const FileUploadPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { fileId } = useParams();
  const { data } = useGetTaskInfo(Number(fileId));
  const location = useLocation();
  const { uploadHandle, reUploadFile, closeModal } = usePostFile();
  const [selectedFile, setSelectedFile] = useState<string | Blob>('');
  const selelctList = [
    { id: 1, type: 'xlsx' },
    { id: 2, type: 'txt' }
  ];
  const columnList = [
    { id: 1, type: 'TAB', text: 'Tab' },
    { id: 2, type: 'COMMA', text: 'Comma' }
  ];
  const rowList = [
    { id: 1, type: 'WINDOWS', text: 'Windows(\\r\\n)' },
    { id: 2, type: 'MAC', text: 'Unix/Mac(\\n)' }
  ];
  const [columnOption, setColumnOption] = useState(columnList[0].type);
  const [rowOption, setRowOption] = useState(rowList[0].type);
  const [fileSelect, setFileSelect] = useState(selelctList[0].type);
  const [fileSelectOpen, setFileSelectOpen] = useState(false);
  const [fileSelectErr, setFileSelectErr] = useState(true);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (file && file.name.endsWith(`.${fileSelect}`)) {
      setFileSelectErr(true);
      setSelectedFile(file);
    } else {
      if (file === undefined) {
        setFileSelectErr(true);
      } else {
        setFileSelectErr(false);
      }
      setSelectedFile('');
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('taskFile', selectedFile);
    if (location.pathname.includes('reUpload')) {
      reUploadFile({ fileId, rowOption, columnOption, formData });
    } else {
      uploadHandle({ fileId, rowOption, columnOption, formData });
    }
  };

  useEffect(() => {
    if (fileSelect === selelctList[0].type) {
      setColumnOption(columnList[0].type);
      setRowOption(rowList[0].type);
    }
  }, [fileSelect]);

  return (
    <div css={FileUploadStyle}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <div className="title">
        <TPHeadlineBasic decoration type="h2">
          작업 파일 등록
        </TPHeadlineBasic>
      </div>
      <div className="filebox">
        <div className="filebox--title">
          <span className="filebox--status">
            {renderRowStatus(data?.status)}
          </span>
          <p className="filebox--language">
            <span>
              {LANGUAGE.filter((v) => v.code === data?.startLanguage).map(
                (lang) => lang.label
              )}
            </span>
            <span className="arrow">
              <IconArrowRightBlack />
            </span>
            <span>
              {LANGUAGE.filter((v) => v.code === data?.destinationLanguage).map(
                (lang) => lang.label
              )}
            </span>
          </p>
        </div>
        <div className="filebox--content">{data?.taskTitle}</div>
      </div>
      <div ref={scrollRef} className="modal--div--box">
        <strong>
          작업 파일<span>*</span>
        </strong>
        <div className="input--text--wrap">
          <div className="select--wrap">
            <div className="select--wrap--box">
              <button onClick={() => setFileSelectOpen(true)}>
                {fileSelect}
              </button>
              {fileSelectOpen && (
                <ul>
                  {selelctList.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => {
                        setFileSelectOpen(false);
                        if (item.type !== fileSelect) {
                          setSelectedFile('');
                          setFileSelect(item.type);
                          setFileSelectErr(true);
                        }
                      }}
                    >
                      {item.type}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div
              className={`file--name ${
                (selectedFile as File) ? '' : 'file--none'
              }`}
            >
              <p>{(selectedFile as File)?.name ?? '파일을 업로드 해주세요.'}</p>
              {!fileSelectErr && (
                <span className="file--err">{`${fileSelect} 파일만 업로드 가능합니다.`}</span>
              )}
            </div>
          </div>
          <label>
            {location.pathname.includes('reUpload') ? '재업로드' : '업로드'}
            <input
              hidden
              accept={`.${fileSelect}`}
              multiple
              type="file"
              onChange={handleFileChange}
            />
          </label>
        </div>
        {fileSelect === selelctList[1].type && (
          <>
            <strong>
              열 구분자<span>*</span>
            </strong>
            <div className="check-box-wrap">
              <ul>
                {columnList.map((item) => (
                  <li key={item.id}>
                    <TPInputCheckBoxBasic
                      option={item.text}
                      optionKey={item.type}
                      selectOptions={[columnOption]}
                      onClick={(option) => setColumnOption(option)}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <strong>
              행 구분자<span>*</span>
            </strong>
            <div className="check-box-wrap">
              <ul>
                {rowList.map((item) => (
                  <li key={item.id}>
                    <TPInputCheckBoxBasic
                      option={item.text}
                      optionKey={item.type}
                      selectOptions={[rowOption]}
                      onClick={(option) => setRowOption(option)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={3}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="등록하기"
            fullWidth
            disabled={!selectedFile || !fileSelectErr}
            onClick={handleUpload}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default FileUploadPage;
