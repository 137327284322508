import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Wrap } from './PerformanceStats.style';
import { useGetFilter } from '@src/hooks/searchFilter';
import { TPSelectCheckSearch } from '@src/units/select/TPSelect.checkSearch';
import { renderLanguageByCode } from './utils/render';
import { IcoMenuChevronDown, IconArrowRightBlackB } from '@src/resources/icon';
import TPButtonClose from '@src/units/button/TPButton.Close';
import TPSelectLangPairPML from '@src/units/select/TPSelect.langPairPML';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@src/hooks/modal/useRangeCalendar';
import { useGetProjectList } from '@src/hooks/statistics/common/getProjectList';
import { useLanguageAll } from '@src/hooks/statistics/common/getLanguageAll';
import { usePmsFilter } from '@src/hooks/statistics/common/usePmsFilter';
import { usePartPerformance } from '@src/hooks/statistics/postPartPerformance';
import Footer from '@src/layouts/footer/Footer';
import { LANGUAGE } from '@src/common/config/Code';

interface languagePair {
  startLanguage: string;
  destinationLanguage: string;
}

interface IPartPerformancePage {
  projectIdList: string[];
  languagePairList: languagePair[];
  startDateTime: Date;
  endDateTime: Date;
}

interface languagePairAmountList {
  dailyAmount: number;
  dailyAmountPerWorker: number;
  destinationLanguage: string;
  outsourceAmount: number;
  outsourceCheckerAmount: number;
  outsourceProportion: number;
  outsourceTranslateAmount: number;
  projectList: {
    amountProportion: number;
    dailyAmount: number;
    projectTitle: string;
    totalAmount: number;
  }[];
  startLanguage: string;
  totalAmount: number;
  totalAmountPerWorker: number;
}

interface IPartPerformanceData {
  languagePairAmountList: languagePairAmountList[];
  latestUpdateDateTime: string;
}

const PartPerformancePage = () => {
  const [projectListFilter, setProjectListFilter] = useState<
    { code: string; label: string }[]
  >([]);
  const { filterData, Component: FilterButton } =
    useGetFilter('PART_PERFORMANCE');
  const { projectList } = useGetProjectList();
  const { data: languageAll } = useLanguageAll();
  const {
    dashboardFilter,
    dashboardFilterProxy,
    languagePairList,
    setLanguagePairList,
    searchDateFilter,
    setSearchDateFilter,
    dateFilter,
    setDateFilter
  } = usePmsFilter(filterData);
  const { postFilter, responseData, loading } =
    usePartPerformance('PART_PERFORMANCE');
  const [performanceData, setPerformanceData] =
    useState<IPartPerformanceData>();
  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'searchDateRange'
  });
  const [filtersHeight, setFiltersHeight] = useState<number>(0);

  const handlePostData = ({
    projectIdList,
    languagePairList,
    startDateTime,
    endDateTime
  }: IPartPerformancePage) => {
    if (projectIdList[0] === 'ALL') {
      projectIdList = projectList.map((item: { code: string }) =>
        parseInt(item.code)
      );
    }
    const startTime = format(new Date(startDateTime), 'yyyy-MM-dd');
    const endTime = format(new Date(endDateTime), 'yyyy-MM-dd');
    const result = {
      projectIdList,
      languagePairList,
      startDateTime: startTime,
      endDateTime: endTime
    };
    postFilter(result);
  };

  useEffect(() => {
    if (projectList.length !== projectListFilter.length) {
      setProjectListFilter(
        projectList.filter((item: { code: string }) => item?.code !== '')
      );
    }
  }, [projectList]);

  useEffect(() => {
    if (responseData) {
      setPerformanceData(responseData);
    }
  }, [responseData]);

  useEffect(() => {
    const filtersElement = document.querySelector('.filters');
    if (filtersElement && languagePairList) {
      const totalHeight =
        filtersElement.clientHeight +
        parseInt(window.getComputedStyle(filtersElement).marginTop);
      setFiltersHeight(totalHeight + 56); // 56은 헤더 높이 + 2px는 언어페어 여백
    }
  }, [languagePairList]);

  useEffect(() => {
    const tableWraps = document.querySelectorAll('.table--wrap');
    tableWraps.forEach((tableWrap) => {
      const table = tableWrap.querySelector('table');
      if (table) {
        const isOverflowing = tableWrap.clientHeight < table.scrollHeight;
        if (isOverflowing) {
          table.querySelector('thead')?.classList.add('overflowing');
        } else {
          table.querySelector('thead')?.classList.remove('overflowing');
        }
      }
    });
  }, [performanceData]);

  return (
    <section css={Wrap}>
      <div className="filters">
        <div className="fullLookup--title">통계</div>
        <div className="fullLookup--list">
          <div className="name">
            <span>파트별 퍼포먼스</span>
          </div>
          <div className="filter--list">
            <div className="left">
              <TPSelectCheckSearch
                label="프로젝트"
                useOptionLabel
                optionList={[
                  { label: '전체', code: 'ALL' },
                  ...projectListFilter.filter((item: any) => item?.code !== '')
                ]}
                selectOptions={dashboardFilter.projectIdList}
                changeSelect={dashboardFilterProxy.setProjectList}
              />
              <TPSelectLangPairPML
                badgeContent={languagePairList.length}
                languagePairList={languagePairList}
                languageAll={languageAll}
                onChange={(e: any) => {
                  setLanguagePairList((currentList) => {
                    const isExisting = currentList.some(
                      (pair) =>
                        pair.startLanguage === e.startLanguage &&
                        pair.destinationLanguage === e.destinationLanguage
                    );
                    if (!isExisting) {
                      return [...currentList, e];
                    }
                    return currentList;
                  });
                }}
              />
              <div
                onClick={() => {
                  openHandle('search', searchDateFilter, (newDateFilter) => {
                    setSearchDateFilter(newDateFilter);
                    setDateFilter({
                      startDateTime: new Date(newDateFilter.startDateTime),
                      endDateTime: new Date(newDateFilter.endDateTime)
                    });
                  });
                }}
              >
                <TPButtonActionCalendar
                  calendarType={'normal'}
                  content={
                    searchDateFilter.startDateTime &&
                    searchDateFilter.endDateTime
                      ? `기간 ${format(
                          new Date(searchDateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )}${
                          new Date(searchDateFilter.startDateTime).getTime() ===
                          new Date(searchDateFilter.endDateTime).getTime()
                            ? ''
                            : ' - ' +
                              format(
                                new Date(searchDateFilter.endDateTime),
                                'yyyy.MM.dd'
                              )
                        }`
                      : ''
                  }
                />
              </div>
              <button
                className={`btn--search ${
                  languagePairList.length > 0 &&
                  searchDateFilter.startDateTime &&
                  searchDateFilter.endDateTime
                    ? ''
                    : 'disabled'
                }`}
                onClick={() => {
                  if (
                    languagePairList.length > 0 &&
                    searchDateFilter.startDateTime &&
                    searchDateFilter.endDateTime
                  ) {
                    handlePostData({
                      projectIdList: dashboardFilter.projectIdList,
                      languagePairList,
                      startDateTime: searchDateFilter.startDateTime,
                      endDateTime: searchDateFilter.endDateTime
                    });
                  }
                }}
              >
                조회
              </button>
              <FilterButton
                getFilterData={{
                  dashboardFilter: (() => {
                    const { keyword, ...other } = dashboardFilter;
                    return {
                      ...other,
                      projectIdList: dashboardFilter.projectIdList
                    };
                  })(),
                  languagePairList: languagePairList.map((pair) => ({
                    startLanguage: pair.startLanguage,
                    destinationLanguage: pair.destinationLanguage
                  })),
                  searchDateFilter: searchDateFilter,
                  dateFilter: dateFilter
                }}
              />
            </div>
            {(!dashboardFilter.projectIdList.includes('ALL') ||
              languagePairList.length > 0 ||
              format(new Date(searchDateFilter.startDateTime), 'yyyy.MM.dd') !==
                format(new Date(), 'yyyy.MM.dd') ||
              format(new Date(searchDateFilter.endDateTime), 'yyyy.MM.dd') !==
                format(new Date(), 'yyyy.MM.dd')) && (
              <div className="right">
                <button
                  className="btn--reset"
                  onClick={() => {
                    dashboardFilterProxy.clearFilter();
                    setPerformanceData(undefined);
                  }}
                >
                  초기화
                </button>
              </div>
            )}
          </div>
          <div className="filter--result">
            {Array.isArray(languagePairList) &&
              languagePairList.length > 0 &&
              languagePairList.map((item: any, i: number) => {
                return (
                  <div className="result--item" key={i}>
                    <div>{renderLanguageByCode(item.startLanguage)}</div>
                    <span className="arrow">
                      <IcoMenuChevronDown
                        fill="#727272"
                        style={{ rotate: '-90deg' }}
                      />
                    </span>
                    <div>{renderLanguageByCode(item.destinationLanguage)}</div>
                    <span className="close">
                      <TPButtonClose
                        onClick={() => {
                          setLanguagePairList((o) => {
                            return o.filter((v) => {
                              return !(
                                v.startLanguage === item.startLanguage &&
                                v.destinationLanguage ===
                                  item.destinationLanguage
                              );
                            });
                          });
                        }}
                      />
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div
        className="content--wrap"
        style={{ height: `calc(100vh - ${filtersHeight}px)` }}
      >
        {performanceData ? (
          <div className="content--box">
            {/* <div>{performanceData.latestUpdateDateTime}</div> */}
            {performanceData.languagePairAmountList.map(
              (item: languagePairAmountList, index: number) => {
                return (
                  <div className="part--performance" key={index}>
                    {index === 0 && (
                      <p className="part--performance--date">
                        {format(
                          new Date(performanceData.latestUpdateDateTime),
                          'yyyy.MM.dd HH:mm'
                        )}
                        <span>기준</span>
                      </p>
                    )}
                    <div className="part--performance--title">
                      <span>
                        {LANGUAGE.filter(
                          (v) => v.code === item.startLanguage
                        ).map((lang) => lang.label)}
                      </span>
                      <IconArrowRightBlackB />
                      <span>
                        {LANGUAGE.filter(
                          (v) => v.code === item.destinationLanguage
                        ).map((lang) => lang.label)}
                      </span>
                    </div>
                    <div className="layout--amount">
                      <ul className="amount--list">
                        <li>
                          <span>총 작업 분량</span>
                          <strong>{item.totalAmount.toLocaleString()}</strong>
                        </li>
                        <li>
                          <span>일 평균 작업 분량</span>
                          <strong>{item.dailyAmount.toLocaleString()}</strong>
                        </li>
                        <li>
                          <span>1인당 총 작업 분량</span>
                          <strong>
                            {item.totalAmountPerWorker.toLocaleString()}
                          </strong>
                        </li>
                        <li>
                          <span>1인당 일 평균 작업 분량</span>
                          <strong>
                            {item.dailyAmountPerWorker.toLocaleString()}
                          </strong>
                        </li>
                      </ul>
                      <ul className="amount--list">
                        <li>
                          <span>외주 총 작업 분량</span>
                          <strong>
                            {item.outsourceAmount.toLocaleString()}
                          </strong>
                        </li>
                        <li>
                          <span>외주 번역 작업 분량</span>
                          <strong>
                            {item.outsourceTranslateAmount.toLocaleString()}
                          </strong>
                        </li>
                        <li>
                          <span>외주 검수 작업 분량</span>
                          <strong>
                            {item.outsourceCheckerAmount.toLocaleString()}
                          </strong>
                        </li>
                        <li>
                          <span>외주 비율</span>
                          <strong>
                            {isNaN(item.outsourceProportion)
                              ? `-%`
                              : `${item.outsourceProportion.toLocaleString()}%`}
                          </strong>
                        </li>
                      </ul>
                      <div className="table--wrap">
                        <table>
                          <colgroup>
                            <col width="*" />
                            <col width="270px" />
                            <col width="300px" />
                            <col width="130px" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th scope="col">프로젝트</th>
                              <th scope="col">작업 비율</th>
                              <th scope="col">평균 작업 분량</th>
                              <th scope="col">총 작업 분량</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.projectList.map((project, index) => (
                              <tr key={index}>
                                <td className="text--left">
                                  {project.projectTitle}
                                </td>
                                <td>
                                  {isNaN(project.amountProportion)
                                    ? `-%`
                                    : `${project.amountProportion.toLocaleString()}%`}
                                </td>
                                <td>{project.dailyAmount.toLocaleString()}</td>
                                <td>{project.totalAmount.toLocaleString()}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div className="content--none">
            <p>조회된 데이터가 없습니다.</p>
            <p>
              <strong>조회 조건을 설정</strong>한 후 <strong>조회 </strong>
              버튼을 눌러 파트별 퍼포먼스를 확인할 수 있습니다.
            </p>
          </div>
        )}
        <Footer />
      </div>

      <Calendar />
    </section>
  );
};

export default PartPerformancePage;
