import { cx } from '@emotion/css';
import { Box } from '@mui/material';
import { IcoMenuChevronDown } from '@resources/icon';
import { useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { TPInputSelect } from '../input/TPInput.select';
import { FilterInput, FilterWrap, Gap, SelectList } from './TPSelect.Style';

interface ISelectOneProps {
  [key: string]: any;
  label: string;
  optionList: { code: string; label: string; text?: string }[];
  selectOption?: string;
  unable?: boolean;
  fullWidth?: boolean;
  height?: string;
  optionLeftMargin?: string;
  fontSize?: string;
  changeSelect: (state: any) => void;
  icon?: ReactElement;
  iconDown?: ReactElement;
  iconUp?: ReactElement;
}

export const TPSelectGraph = (props: ISelectOneProps) => {
  const {
    label,
    optionList,
    selectOption,
    unable,
    fullWidth,
    height,
    optionLeftMargin,
    fontSize,
    changeSelect,
    ...otherProps
  } = props;
  const [showCheckList, setShowCheckList] = useState(false);
  const currentSelectOption = optionList?.find(
    (el) => el.code === selectOption
  )?.label;

  const mouseEnterHandler = () => {
    if (!unable) setShowCheckList(true);
  };
  const mouseLeaveHandler = () => {
    if (!unable) setShowCheckList(false);
  };

  return (
    <FilterWrap
      {...otherProps}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      fullWidth={fullWidth}
      height={height}
    >
      <FilterInput
        unable={unable}
        optionLeftMargin={optionLeftMargin}
        fontSize={fontSize}
      >
        <div className="label--selectOption">
          <span
            className={`selectOption ${
              currentSelectOption ? null : 'colorGray'
            }`}
          >
            {currentSelectOption ? currentSelectOption : '선택'}
          </span>
        </div>
        <IcoMenuChevronDown className="icon up" />
      </FilterInput>
      <div className="dropdown">
        <Gap />
        <SelectList>
          {optionList.map((item, index) => (
            <Box
              key={index}
              sx={
                selectOption === item.code
                  ? { color: '#9b51e0', width: '100%' }
                  : { width: '100%' }
              }
            >
              <TPInputSelect
                option={item.label}
                optionKey={item.code}
                onClick={(e) => {
                  changeSelect(e);
                  setShowCheckList(false);
                }}
              />
            </Box>
          ))}
        </SelectList>
      </div>
    </FilterWrap>
  );
};
