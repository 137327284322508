import { ReactElement, useState } from 'react';
import { TPInputCheckBox } from '../input/TPInput.checkBox';
import { FilterWrap, SelectList, FilterInput, Gap } from './TPSelect.Style';
import { IcoMenuChevronDownActive, IcoMenuChevronDown } from '@resources/icon';
import { IUser } from '@src/common/config/ICode';
import { useHookFunc } from '@src/hooks/utils';
import _ from 'lodash';
import { cx } from '@emotion/css';
import { useAlert } from '@src/hooks/modal/useAlert';
import { useToast } from '@src/hooks/modal/useToast';

interface IWorkerStatus {
  userId: number;
  userImgUrl: string;
  userName: string;
}

interface ISelectCheckWorkerProps {
  label: string | ReactElement;
  workerList: IWorkerStatus[];
  selectOptions: (string | number)[];
  showAvatar?: boolean;
  changeSelect: (state: any) => void;
  maxSelections?: number;
}

export const TPSelectCheckWorker = (props: ISelectCheckWorkerProps) => {
  const {
    label,
    workerList,
    selectOptions,
    showAvatar,
    changeSelect,
    maxSelections = Infinity
  } = props;
  const { openHandle: toastHandle } = useToast();
  const getWorkerList = _.chain(workerList)
    .sortBy('name')
    .sortBy((item) => item.userId !== 0)
    .value();

  let showSelectedValue: any = selectOptions.filter(
    (e) => e !== '0' && e !== '' && e !== null && e !== undefined
  );

  if (showSelectedValue.length === 0) {
    showSelectedValue = '';
  } else {
    showSelectedValue = showSelectedValue.length;
  }

  const setSelect = (option: string) => {
    const currentSelectedCount = selectOptions.filter(
      (e) => e !== '0' && e !== '' && e !== null && e !== undefined
    ).length;

    if (selectOptions.includes(option)) {
      changeSelect(option);
    } else if (currentSelectedCount < maxSelections) {
      changeSelect(option);
    } else {
      toastHandle({
        text: `최대 ${maxSelections}명까지만 선택할 수 있습니다.`,
        severity: 'error'
      });
    }
  };

  return (
    <FilterWrap>
      <FilterInput
        className={cx('filter-input', {
          'filter--active': Boolean(showSelectedValue)
        })}
      >
        <div className="label--checked">
          <span className="label">{label}</span>
          <span className="checked">{showSelectedValue}</span>
        </div>
        <IcoMenuChevronDown className="icon" />
      </FilterInput>
      <div className="dropdown">
        <Gap />
        <SelectList>
          {getWorkerList?.map((item, index) => {
            return (
              <TPInputCheckBox
                option={item.userName}
                avatarUrl={item.userImgUrl}
                optionKey={String(item.userId)}
                selectOptions={selectOptions}
                onClick={setSelect}
                showAvatar={showAvatar}
                key={index}
              />
            );
          })}
        </SelectList>
      </div>
    </FilterWrap>
  );
};
