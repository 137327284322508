import api from '@api/Api';
import download from '../utils/download';

export interface IProjectExcel {
  projectId?: number;
  taskTitle?: string;
  taskStatus?: string[];
  taskType?: string[];
  dateType?: string;
  startDateTime?: string;
  endDateTime?: string;
  workUserIdList?: number[];
  workUserSortParam?: string;
  startLanguageList?: string[];
  destinationLanguageList?: string[];
}

const usePostExcel = () => {
  const downloadData = async ({
    projectId,
    taskStatus
  }: Partial<IProjectExcel>) => {
    const payload = {
      projectId: projectId ?? 0,
      taskStatus: taskStatus ?? []
    };

    return await api
      .post('/project/excel', payload, { responseType: 'blob' })
      .then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        let filename = 'project.xlsx'; // 기본 파일명

        if (contentDisposition) {
          // Content-Disposition 헤더에서 파일명만 직접 추출
          const startIndex = contentDisposition.indexOf('filename=');
          if (startIndex !== -1) {
            filename = contentDisposition
              .substring(startIndex + 9)
              .replace(/["']/g, '');
            download(res, filename);
          }
        } else {
          download(res, filename);
        }
      })
      .catch((error) => {
        console.error('error', error.response?.data);
        throw error;
      });
  };

  return { downloadData };
};

export default usePostExcel;
