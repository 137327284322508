import { cx } from '@emotion/css';
import { alertWrap } from '@hooks/modal/Modal.style.v2';
import { useAlert } from '@hooks/modal/useAlert';
import { useToast } from '@hooks/modal/useToast';
import { Box } from '@mui/material';
import { useSelectCalendar } from '@src/hooks/modal/useSelectCalendar';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { TPInputDateSelect } from '@src/units/input/TPInput.dateSelect';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssignAlertStyle } from './AssignAlert.style';
import TPButtonCheck from '@src/units/button/TPButton.check';
import { getTaskStatusLabel, STATUS_COLOR } from '@src/common/config/Code';
import { renderLanguageByCode } from '../dashboard/utils/render';
import { ITaskDetailInfo } from '@src/common/config/ITask';
import { IconBulRight } from '@src/resources/icon';
import Api from '@src/api/Api';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';

function AssignAlert(props: any) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle: toastOpen } = useToast();
  let { id, assignId } = useParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { openHandle: openCalendar, Component: Calendar } = useSelectCalendar({
    dateLimit: {
      fromLimit: new Date(),
      timeOptions: ['10:00', '15:00']
    }
  });
  const [startDateTime, setStartDateTime] = useState<Date | undefined>(
    undefined
  );
  const [checkStatus, setCheckStatus] = useState(false);
  const [renderLanguageList, setRenderLanguageList] = useState<
    (() => JSX.Element) | null
  >(null);
  const [reservationStatus, setReservationStatus] = useState(true);
  const currentDatePlusTen = new Date();
  currentDatePlusTen.setHours(10, 0, 0, 0); // 시간을 10:00으로 설정
  const formattedDate = `${currentDatePlusTen.getFullYear()}.${
    currentDatePlusTen.getMonth() + 1 < 10 ? '0' : ''
  }${currentDatePlusTen.getMonth() + 1}.${
    currentDatePlusTen.getDate() < 10 ? '0' : ''
  }${currentDatePlusTen.getDate()} 10:00`;

  const {
    data: taskDetail,
    error,
    status
  } = useQuery(
    ['taskDetail', assignId],
    () => Api.get(`/messageHistory/${assignId}`),
    {
      enabled: !!assignId // assignId가 있을 때만 쿼리 실행
    }
  );

  const mutation = useMutation(
    (newData: any) => Api.post(`/messageHistory/send`, newData),
    {
      onSuccess: (newData) => {
        modalNavigate();
        queryClient.invalidateQueries(['taskDetail', assignId]);
        if (!checkStatus) {
          toastOpen({
            text: '작업자 배정 요청이 되었습니다.',
            severity: 'success'
          });
        } else {
          toastOpen({
            text: '작업자 배정 요청이 예약되었습니다.',
            severity: 'success'
          });
        }
      },
      onError: (error) => {
        modalNavigate();
        console.error('데이터 전송 실패', error);
        if (!checkStatus) {
          toastOpen({
            text: '작업자 배정 요청이 되지 않았습니다.',
            severity: 'error'
          });
        } else {
          toastOpen({
            text: '작업자 배정 요청 예약이 되지 않았습니다.',
            severity: 'error'
          });
        }
      }
    }
  );
  const updateMutation = useMutation(
    (updateData: any) => Api.put(`/messageHistory/cancel`, updateData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['taskDetail', assignId]);
      },
      onError: (error) => {
        console.error('업데이트 실패', error);
      }
    }
  );

  const modalNavigate = () => {
    const { state } = window.history;
    if (state?.projectId && state?.taskId) {
      window.history.replaceState({}, '');
      props?.setOpenedJobRegisterPage?.(false);
    } else {
      navigate(-1);
    }
  };

  const closeModal = () => {
    alertHandle({
      title: '작업자 배정 요청 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        modalNavigate();
      }
    });
  };

  const submitModal = () => {
    alertHandle({
      title: '작업자 배정 요청 확인',
      text: '작업자 배정을 요청하겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        onSubmit();
      }
    });
  };

  const reservationCancelModal = () => {
    alertHandle({
      title: '예약 전송 취소 확인',
      text: '예약된 작업자 배정 요청 알림이 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        reservationCancel();
      }
    });
  };

  const onSubmit = () => {
    let dateTime;
    const kstOffset = 9 * 60 * 60 * 1000; // 9시간을 밀리초로 변환
    if (startDateTime) {
      dateTime = new Date(startDateTime.getTime() + kstOffset).toISOString();
    }
    mutation.mutate({
      taskId: assignId,
      reservationStatus: checkStatus,
      reservationDateTime: checkStatus ? dateTime : null
    });
  };

  const reservationCancel = async () => {
    const response = await updateMutation.mutateAsync({
      taskId: assignId
    });
    return response;
  };

  useEffect(() => {
    setReservationStatus(taskDetail && taskDetail.data.reservationStatus);
    setRenderLanguageList(() => () => (
      <ul className="languageList">
        {taskDetail?.data?.languageTaskList?.map(
          (item: ITaskDetailInfo, index: number) => {
            let color =
              STATUS_COLOR[
                item.languageTaskStatus as keyof typeof STATUS_COLOR
              ] ?? 'transparent';
            let label = getTaskStatusLabel(item.languageTaskStatus ?? '');
            return (
              <li key={index}>
                <div className="languageTitle">
                  <div
                    className={cx({
                      bar: true,
                      [item.languageTaskStatus ?? 'non']: true
                    })}
                  >
                    <span style={{ backgroundColor: color }} />
                    <p className="label">{label}</p>
                  </div>
                  <div className="languageCode">
                    {renderLanguageByCode(item.startLanguage)}
                    <IconBulRight />
                    {renderLanguageByCode(item.destinationLanguage)}
                  </div>
                </div>
                <p>{item.languageTaskTitle}</p>
              </li>
            );
          }
        )}
      </ul>
    ));
  }, [taskDetail]);

  return (
    <div css={[AssignAlertStyle, alertWrap]}>
      <TPButtonClose className="alertClose" onClick={closeModal} />
      <h2 className="alertTitle">작업자 배정 요청</h2>
      <div ref={scrollRef} className="modal--div--register">
        {reservationStatus && (
          <div className="buttonBox">
            <button className="normal" onClick={reservationCancelModal}>
              예약 취소
            </button>
          </div>
        )}
        <div className="assignDateBox">
          <div className="dateCheck">
            <span className={`${reservationStatus && 'disabled'}`}>
              <TPButtonCheck
                status={
                  checkStatus || reservationStatus ? 'selected' : 'normal'
                }
                onClick={() => {
                  if (!reservationStatus) {
                    setCheckStatus(!checkStatus);
                  }
                }}
              />
              <label>예약 전송</label>
            </span>
            <p>신규 상태인 언어 작업에 한해서 요청 알림이 갑니다.</p>
          </div>
          <div className={`selectBox ${!checkStatus ? 'disabled' : ''}`}>
            <TPInputDateSelect
              date={startDateTime}
              placeholder={
                !reservationStatus
                  ? startDateTime
                    ? formattedDate
                    : '날짜를 입력해 주세요.'
                  : taskDetail?.data?.reservationDateTime
                  ? new Date(taskDetail.data.reservationDateTime)
                      .toLocaleString('ko-KR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                      })
                      .replace(/(\d{4})-(\d{2})-(\d{2})/, '$1.$2.$3')
                  : ''
              }
              openHandle={() => {
                if (!reservationStatus && checkStatus) {
                  openCalendar('start', startDateTime, setStartDateTime);
                }
              }}
              required
              reservationStatus={reservationStatus}
            />
            {!startDateTime && checkStatus && (
              <p className="dateReminder">*전송 일시를 입력해 주세요</p>
            )}
          </div>
        </div>
        <h3 className="alertTitle">언어 작업</h3>
        {/* <div className="languageList"> */}
        {renderLanguageList ? renderLanguageList() : null}
        {/* </div> */}
      </div>

      <Box height={'32px'} />
      <Box display={'flex'} justifyContent={'space-between'} gap={2}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label={'요청하기'}
            fullWidth
            onClick={submitModal}
            size={'large'}
            disabled={
              checkStatus || reservationStatus
                ? startDateTime
                  ? false
                  : true
                : false
            }
          />
        </Box>
      </Box>
      <Calendar />
    </div>
  );
}

export default AssignAlert;
