import { css } from '@emotion/react';
import { cssResetButton } from '../dashboard/utils/css';

export const ProjectWrap = css`
  font-family: 'IBM Plex Sans', sans-serif;
  letter-spacing: -1px;
  text-align: left;
  min-width: max-content;
  .info-top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    .info {
      .sub-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #727272;
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: #242424;
      }
    }
  }

  h2 {
    max-width: 40%;
    word-break: break-all;
    word-wrap: break-word;
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .project--dl--info {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    > div {
      display: flex;
      align-items: center;
      dt {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.25px;
        text-align: left;
        margin-right: 4px;
        color: #aaa;
      }
      dd {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-align: left;
        color: #727272;
      }
      .dDay {
        margin-left: 8px;
        .MuiChip-root {
          font-family: 'IBM Plex Sans', sans-serif;
          font-size: 11px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0px;
          text-align: left;
          border-radius: 16px;
        }
      }
    }
  }
  .content {
    margin-top: -40px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #f9f9f9;
  }

  .calendar-range {
    margin-bottom: 20px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 14px;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: -0.25px;
    color: #727272;
    gap: 8px;
  }

  button.file-manager {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    text-decoration: underline;
  }
  button.regist {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: center;
    background-color: #9b51e0;
    color: #ececec;
    padding: 5px 14px;
    border-radius: 2px;
    margin-left: 16px;
  }
  button.normal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    height: 32px;
    white-space: nowrap;
    color: #9b51e0;
    border-radius: 2px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: center;
    color: #242424;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #cbcbcb;
  }
`;
