import styled from '@emotion/styled';

export const TaskAmountInputs = ({
  taskAmount,
  tmMatch,
  inputText
}: {
  taskAmount: { [key: string]: any };
  tmMatch: boolean;
  inputText: { [key: string]: any };
}) => {
  const inputArr: any[] = [];
  Object.keys(taskAmount).forEach((key) => {
    inputArr.push(
      <div
        className={`inputTableCell ${
          !tmMatch && !['percentRep', 'noMatch'].includes(key) ? 'dim' : ''
        }`}
        key={key}
        id={key}
      >
        <div>{inputText[key]}</div>
        {taskAmount[key]?.toLocaleString?.('en-US') ?? ''}
      </div>
    );
  });

  return <Wrap>{inputArr.map((El) => El)}</Wrap>;
};

const Wrap = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: space-between;

  .inputTableCell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-items: center;

    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;

    /* background-color: red; //disable => #f6f6fa; */
    font-size: 14px;
    border-radius: 2px;

    color: #727272;
    &.dim {
      color: #aaaaaa;
    }
    div {
      text-align: left;
    }
    input {
      width: 100%;
      height: 40px;
      border: 1px solid #ececec;
      text-align: center;
      outline: none;
      color: #727272;
    }
  }
`;
