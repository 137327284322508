import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

export const TaskAmountInputsNew = ({
  tmMatch,
  taskAmountList,
  taskKey,
  inputText,
  isDisabled = false,
  changeInput,
  resetTrigger
}: {
  tmMatch: boolean;
  taskAmountList: { [key: string]: any };
  inputText: { [key: string]: any };
  taskKey: string;
  isDisabled?: boolean;
  changeInput: (
    taskAmount: { [key: string]: any },
    key: string,
    AmountListKey: string
  ) => void;
  resetTrigger: boolean;
}) => {
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>(
    Object.keys(taskAmountList).reduce(
      (acc, key) => ({
        ...acc,
        [key]: taskAmountList[key]?.toLocaleString('en-US') ?? '0'
      }),
      {}
    )
  );

  useEffect(() => {
    const newInputValues = Object.keys(taskAmountList).reduce(
      (acc, key) => ({
        ...acc,
        [key]: taskAmountList[key]?.toLocaleString('en-US') ?? '0'
      }),
      {}
    );
    setInputValues((prevValues) => ({
      ...prevValues,
      ...newInputValues
    }));
  }, [taskAmountList]);

  useEffect(() => {
    const newInputValues = Object.keys(taskAmountList).reduce(
      (acc, key) => ({
        ...acc,
        [key]: taskAmountList[key]?.toLocaleString('en-US') ?? '0'
      }),
      {}
    );
    setInputValues(newInputValues);
  }, [resetTrigger]);

  const handleInputChange = (key: string, value: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [key]: value
    }));
    const numericValue = Number(value.replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      changeInput({ ...taskAmountList, [key]: numericValue }, taskKey, key);
    }
  };

  const inputArr: any[] = [];
  Object.keys(taskAmountList).forEach((key) => {
    const disabled =
      (!tmMatch && !['percentRep', 'noMatch'].includes(key)) || isDisabled;
    inputArr.push(
      <div className="inputTableCell" key={key}>
        <div>{inputText[key]}</div>
        <input
          type="text"
          disabled={disabled}
          value={inputValues[key] ? inputValues[key] : '0'}
          placeholder={'0'}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[0-9,]*$/.test(value)) {
              handleInputChange(key, value);
            }
          }}
        />
      </div>
    );
  });

  return <Wrap>{inputArr.map((El) => El)}</Wrap>;
};

const Wrap = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: space-between;

  .inputTableCell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    font-size: 14px;
    border-radius: 2px;

    div {
      color: #727272;
      text-align: left;
    }
    input {
      width: 100%;
      height: 40px;
      border: 1px solid #ececec;
      text-align: center;
      outline: none;
      color: #727272;
      :disabled {
        background-color: #fafafa;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;
