import api from '@api/Api';

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  statisticsProjectKeys,
  taskManagerKeys
} from '@src/hooks/queryKeyFactory';

export function useGetProjectList() {
  // 프로젝트 리스트 GET /project/simpleAllList
  const { data: statisticsProjectList = [] } = useQuery(
    statisticsProjectKeys.statisticsProjectList,
    async function () {
      return await api
        .getPms('/searchFilter/selection/project')
        .then((res) => res.data);
    },
    {
      suspense: false
    }
  );

  // 프로젝트 선택박스옵션 리스트
  const projectList = (() => {
    if (Array.isArray(statisticsProjectList.projectList)) {
      return statisticsProjectList.projectList.map((e: any) => ({
        code: String(e.projectId),
        label: e.projectTitle
      }));
    } else {
      return [];
    }
  })();

  return {
    projectList
  };
}
