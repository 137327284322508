import Api from '@api/Api';
import { useMyInfo } from '@hooks/myInfo/getMyInfo';
import { context } from '@src/Context';
import { useAlarmsApi } from '@src/pages/Alarm';
import { useContext, useEffect, useRef } from 'react';

const { REACT_APP_BORASTREAM_URL = 'https://dev-stream.boracat.io' } =
  process.env;

export const useNotice = () => {
  const { sseRef, setUnReadMessage, setHasNewAlarm, timeRef } =
    useContext(context);
  const { myInfo } = useMyInfo();
  const connectSSE = (id: string | number) => {
    const source = new EventSource(
      `${REACT_APP_BORASTREAM_URL}/sse/${myInfo.id}`
    );
    sseRef.current = source;
    // console.debug('SSE connect', id, sseRef.current);

    source.onopen = () => {
      // console.debug('SSE open');
      timeRef.current = Date.now();
    };

    source.onmessage = (e: MessageEvent) => {
      let { data } = e;
      try {
        data = JSON.parse(data);
        // console.debug('SSE', data);
        if (data?.type === 'INIT_CONNECT') {
          callAlertCheck(myInfo.id);
        } else if (data?.type === 'LPM_PRIVATE') {
          if (data?.text === 'TRUE') {
            setHasNewAlarm(true);
          } else if (data?.text === 'FALSE') {
            setHasNewAlarm(false);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    source.onerror = (error) => {
      // console.debug('SSE error', error, Date.now() - timeRef.current);

      source.onopen = null;
      source.onmessage = null;
      source.onerror = null;
      source.close();

      connectSSE(myInfo.id);
      setTimeout(() => {
        callInitConnection();
      }, 500);
    };
  };

  const callAlertCheck = (id: string | number) => {
    return Api.getAlarm(
      `${REACT_APP_BORASTREAM_URL}/message/alertCheck/${myInfo.id}`
    );
  };

  const callInitConnection = async () =>
    Api.getAlarm(`${REACT_APP_BORASTREAM_URL}/sse/initConnection/${myInfo.id}`);

  useEffect(() => {
    if (myInfo?.id && sseRef.current === null) {
      connectSSE(myInfo.id);
      setTimeout(() => {
        callInitConnection();
      }, 500);
    }
    return () => {
      if (sseRef.current) {
        console.debug('SSE close');
        sseRef.current.onopen = null;
        sseRef.current.onmessage = null;
        sseRef.current.onerror = null;
        sseRef.current.close();
        sseRef.current = null;
      }
    };
  }, [myInfo]);

  return {
    setUnReadMessage,
    callAlertCheck,
    callInitConnection,
    sseRef,
    connectSSE
  };
};
