import api from '@api/Api';
import {
  LanguageCodeType,
  WidgetCode,
  sortByTaskStatus
} from '@src/common/config/Code';
import { IProjectDetailListRes } from '@src/common/config/IProjectDetail';
import { QueryFunctionContext } from '@tanstack/query-core';
import { useQuery } from '@tanstack/react-query';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { projectDetailKeys } from '../queryKeyFactory';
import { useHookFunc, usePageFunc } from '../utils';
import { IProjectExcel } from '../project/postExcel';

export const initialDataMap = {
  get taskTitle() {
    return '';
  },
  get taskFilter() {
    return {
      startLanguageList: ['ALL'],
      destinationLanguageList: ['ALL'],
      workUserIdList: ['0'],
      taskType: ['ALL'],
      taskStatus: ['NEW', 'COMPLETE_ASSIGN', 'PROGRESS', 'COMPLETE']
    };
  },
  get dateType() {
    return 'ALL';
  },
  get dateFilter() {
    return {
      startDateTime: undefined,
      endDateTime: undefined
    };
  }
};

export const useGetProjectDetailList = ({
  projectId,
  filterData
}: {
  projectId: string;
  filterData: any;
}) => {
  const { arraySet } = useHookFunc();
  const { getDateTime } = usePageFunc();

  const [taskTitle, setTaskTitle] = useState<string>(initialDataMap.taskTitle);
  const [taskFilter, setTaskFilter] = useState<{ [key: string]: string[] }>(
    initialDataMap.taskFilter
  );
  const [dateType, setDateType] = useState<string>(initialDataMap.dateType);
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>(initialDataMap.dateFilter);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  const [isExcel, setIsExcel] = useState<IProjectExcel>({});

  useEffect(() => {
    if (!filterData) return;
    setTaskTitle(filterData?.taskTitle ?? initialDataMap.taskTitle);
    setTaskFilter(filterData?.taskFilter ?? initialDataMap.taskFilter);
    setDateType(filterData?.dateType ?? initialDataMap.dateType);
    setDateFilter(filterData?.dateFilter ?? initialDataMap.dateFilter);
  }, [filterData]);

  //#region setState
  const setState = {
    setTasker: (e: any) => {
      const workUserIdList =
        e === '0'
          ? initialDataMap.taskFilter.workUserIdList
          : _.filter(
              arraySet(taskFilter.workUserIdList, e),
              (item) => item !== '0'
            );
      setTaskFilter({
        ...taskFilter,
        workUserIdList: workUserIdList.length ? workUserIdList : ['0']
      });
    },
    setTaskType: (e: any) => {
      const taskType =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.taskType, e),
              (item) => item !== 'ALL'
            );
      setTaskFilter({
        ...taskFilter,
        taskType: taskType.length ? taskType : ['ALL']
      });
    },
    setTaskStatus: (e: any) => {
      const taskStatus =
        e === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.taskStatus, e),
              (item) => item !== 'ALL'
            );
      setTaskFilter({
        ...taskFilter,
        taskStatus: taskStatus.length ? taskStatus : ['ALL']
      });
    },
    setStartLanguageList: (value: string) => {
      const startLanguageList =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.startLanguageList, value),
              (item) => item !== 'ALL'
            );
      setTaskFilter((state) => ({
        ...state,
        startLanguageList: startLanguageList.length
          ? (startLanguageList as LanguageCodeType[])
          : ['ALL']
      }));
    },
    setDestinationLanguageList: (value: string) => {
      const destinationLanguageList =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(taskFilter.destinationLanguageList, value),
              (item) => item !== 'ALL'
            );
      setTaskFilter((state) => ({
        ...state,
        destinationLanguageList: destinationLanguageList.length
          ? (destinationLanguageList as LanguageCodeType[])
          : ['ALL']
      }));
    },
    setTaskTitle,
    setDateType,
    setDateFilter,
    setResetState: () => {
      setTaskTitle(initialDataMap.taskTitle);
      setTaskFilter(initialDataMap.taskFilter);
      setDateType(initialDataMap.dateType);
      setDateFilter(initialDataMap.dateFilter);
    },
    setIsSort
  };
  //#endregion

  //#region payload set
  const setPayload = ({
    projectId,
    taskTitle,
    taskFilter,
    dateType,
    dateFilter
  }: {
    projectId: string;
    taskTitle: string;
    taskFilter: { [key: string]: any[] };
    dateType: string;
    dateFilter: { [key: string]: any };
  }) => {
    const { startDateTime, endDateTime } = dateFilter;
    const taskFilterArr: { [key: string]: any[] } = {};

    Object.keys(taskFilter).forEach((key) => {
      _.map(
        taskFilter[key],
        (el) =>
          !['ALL', '0'].includes(el) && (taskFilterArr[key] = taskFilter[key])
      );
    });

    return {
      projectId,
      ...(taskTitle && { taskTitle }),
      ...taskFilterArr,
      ...(dateType !== 'ALL' &&
        startDateTime &&
        endDateTime && {
          dateType,
          startDateTime: getDateTime({
            date: new Date(startDateTime),
            filter: 'start'
          }),
          endDateTime: getDateTime({
            date: new Date(endDateTime),
            filter: 'end'
          })
        })
    };
  };
  //#endregion

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof projectDetailKeys)['list']>
  >): Promise<IProjectDetailListRes> => {
    const [, filters] = queryKey;
    const getPayload = setPayload(filters);

    setIsExcel(getPayload);
    setIsFilter(
      filters.dateType !== 'ALL' ||
        [...initialDataMap.taskFilter.taskStatus].sort().join() !==
          filters.taskFilter?.taskStatus?.sort()?.join() ||
        [...initialDataMap.taskFilter.workUserIdList].join() !==
          filters.taskFilter?.workUserIdList?.join() ||
        [...initialDataMap.taskFilter.taskType].join() !==
          filters.taskFilter?.taskType?.join() ||
        [...initialDataMap.taskFilter.startLanguageList].join() !==
          filters.taskFilter?.startLanguageList?.join() ||
        [...initialDataMap.taskFilter.destinationLanguageList].join() !==
          filters.taskFilter?.destinationLanguageList?.join()
    );

    return await api
      .post('/project', { ...getPayload })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const {
    isLoading,
    isError,
    data = {
      projectDetail: {
        companyName: '',
        companyIconImgUrl: '',
        projectTitle: '',
        totalGrossAmount: 0,
        totalNetAmount: 0,
        startDateTime: '',
        endDateTime: '',
        mainProjectManagerName: '',
        mainProjectManagerStatus: '',
        subProjectManagerName: '',
        subProjectManagerStatus: '',
        contractType: 'CASE',
        projectAssignerList: []
      },
      taskList: []
    }
  } = useQuery(
    [
      ...projectDetailKeys.list({
        projectId,
        taskTitle,
        taskFilter,
        dateType,
        dateFilter
      })
    ],
    getData,
    {
      refetchOnMount: true,
      select: (data) => {
        return {
          projectDetail: data.projectDetail,
          taskList: isSort.code.length
            ? isSort.code === 'taskStatus'
              ? [...data.taskList].sort((a, b) =>
                  sortByTaskStatus(a, b, isSort.isAsc)
                )
              : [
                  ..._.orderBy(
                    data.taskList,
                    [isSort.code],
                    [isSort.isAsc ? 'asc' : 'desc']
                  )
                ]
            : _.orderBy(data.taskList, ['endDateTime'], ['asc']) // 기획: 디폴트 정렬 마감일 오름차순
        };
      }
    }
  );

  const { projectDetail, taskList } = data;

  //#endregion

  //#region 상단 count
  const dashBoardItemList = WidgetCode.widgetDetail.map((code) => ({
    ...code,
    count: Number(
      _.size(_.filter(taskList, (v: any) => v.taskStatus === code.icon))
    )
  }));
  //#endregion

  //#region 프로젝트 d-day
  let dDay = -1;
  let shouldDisplayDDay = false;
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(projectDetail.endDateTime)) {
    // const current = parseISO('2023-11-03T09:15'); // TEST용 현재시간
    const current = new Date();
    const end = parseISO(projectDetail.endDateTime);
    dDay = differenceInCalendarDays(end, current);
    shouldDisplayDDay =
      (7 >= dDay && dDay >= 1) ||
      (dDay === 0 && current.getTime() <= end.getTime());

    // console.debug('TEST', {
    //   endRaw: projectDetail.endDateTime, // 서버에서 받은 한국시간(ISO표기법으로 헷갈릴 수 있음)
    //   endISO: end.toISOString(), // 진정한 ISO시간
    //   currentISO: current.toISOString(),
    //   dDay,
    //   shouldDisplayDDay
    // });
  }
  //#endregion

  return {
    isLoading,
    isError,
    projectDetail,
    taskList,
    dashBoardItemList,
    taskTitle,
    taskFilter,
    dateType,
    dateFilter,
    isFilter,
    isSort,
    setState,
    dDay,
    shouldDisplayDDay,
    isExcel
  };
};
