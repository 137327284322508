import { css, cx } from '@emotion/css';
import { IcoMenuChevronDown, IconSearch } from '@resources/icon';
import { useHookFunc } from '@src/hooks/utils';
import _ from 'lodash';
import { ReactElement, useMemo, useState } from 'react';
import { TPInputCheckBox } from '../input/TPInput.checkBox';
import { FilterInput, FilterWrap, Gap, SelectList } from './TPSelect.Style';
import { useToast } from '@src/hooks/modal/useToast';

interface ISelectCheckedProps {
  label: string | ReactElement;
  optionList: readonly {
    code: string;
    label: string;
    text?: string;
  }[];
  useOptionLabel?: boolean;
  useFilterStatus?: { [key: string]: string | [string, string] };
  selectOptions: string[];
  changeSelect: (state: any) => void;
  icon?: ReactElement;
  iconDown?: ReactElement;
  iconUp?: ReactElement;
  multiple?: boolean;
  maxSelections?: number;
  isDimmed?: boolean;
}

export const TPSelectCheckSearch = (props: ISelectCheckedProps) => {
  const { arraySet } = useHookFunc();
  const {
    label,
    useOptionLabel,
    optionList,
    selectOptions,
    changeSelect,
    maxSelections = Infinity,
    isDimmed
  } = props;
  const { openHandle: toastHandle } = useToast();
  const [searchTerm, setSearchTerm] = useState('');
  const showSelectedValue = useMemo(() => {
    let v: any = selectOptions.filter(
      (e) =>
        e !== 'ALL' && e !== '0' && e !== '' && e !== null && e !== undefined
    ).length;
    if (v === 0) v = '';
    return v;
  }, [selectOptions]);

  const filteredOptions = useMemo(() => {
    if (searchTerm) {
      // 검색어가 있을 때 "ALL" 제외
      return optionList.filter(
        (option) =>
          option.code !== 'ALL' &&
          (option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
            option.text?.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    } else {
      // 검색어가 없을 때는 모든 옵션 포함
      return optionList;
    }
  }, [optionList, searchTerm]);

  const setSelect = (option: string) => {
    const setOption = _.sortBy(
      _.filter(
        arraySet(selectOptions, option),
        (el) => !['0', 'ALL'].includes(el)
      )
    );
    const defaultOption = _.sortBy(
      _.filter(
        optionList.map((v) => v.code),
        (el) => !['0', 'ALL'].includes(el)
      )
    );
    const hasAllOption = optionList.some((item) => item.code === 'ALL');
    const currentSelectedCount = selectOptions.filter(
      (e) => e !== '0' && e !== '' && e !== null && e !== undefined
    ).length;

    if (hasAllOption) {
      // 옵션에 "ALL"이 있을 때
      if (_.isEqual(setOption, defaultOption)) {
        changeSelect('ALL');
      } else {
        changeSelect(option);
      }
    } else {
      // 옵션에 "ALL"이 없을 때
      if (
        selectOptions.includes(option) ||
        currentSelectedCount < maxSelections
      ) {
        changeSelect(option);
      } else {
        toastHandle({
          text: `최대 ${maxSelections}개까지만 선택할 수 있습니다.`,
          severity: 'error'
        });
      }
    }
  };

  return (
    <FilterWrap className={cx({ dimmed: isDimmed })}>
      <FilterInput
        className={cx('filter-input', {
          'filter--active': Boolean(showSelectedValue)
        })}
      >
        <div className="label--checked">
          <span className="label">{label}</span>
          <span className="checked">{showSelectedValue}</span>
        </div>

        <div>
          <IcoMenuChevronDown className="icon up" />
        </div>
      </FilterInput>
      <div className="dropdown">
        <Gap />

        <SelectList>
          <div className="search-input-wrap">
            <IconSearch
              className="button"
              width={20}
              height={20}
              stroke="#000"
              fill="#000"
            />
            <input // 검색 입력 필드 추가
              type="text"
              placeholder="검색"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
          {filteredOptions.map((item, index) => {
            return props.multiple === undefined || props.multiple === true ? (
              <TPInputCheckBox
                option={useOptionLabel ? item.label : item.text ?? ''}
                optionKey={item.code}
                selectOptions={selectOptions}
                onClick={setSelect}
                key={index}
              />
            ) : (
              <div
                key={index}
                onClick={() => {
                  setSelect(item.code);
                }}
                className={css`
                  cursor: pointer;
                  text-align: left;
                  width: 100%;
                  padding: 0.2rem 1rem;
                  &:hover {
                    background-color: #f7f7f7;
                  }
                `}
              >
                {useOptionLabel ? item.label : item.text ?? ''}
              </div>
            );
          })}
        </SelectList>
      </div>
    </FilterWrap>
  );
};
