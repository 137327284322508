import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { IWorkerList } from '@src/common/config/ICode';
import { dashboardKeys } from '@hooks/queryKeyFactory';
import { useHookFunc } from '@hooks/utils';

/**
 * /task/managerList
 * @description 전체 작업 조회
 * @returns {{ taskerList }}
 */
export const useGetWorkerList = () => {
  const getData = async (): Promise<IWorkerList> => {
    const response = await api.getPms(`/statistics/worker-list`);
    return response.data;
  };

  const { data } = useQuery(['getWorkerList'], getData);

  return { data };
};
