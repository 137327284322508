import { css } from '@emotion/react';
import { cssResetButton } from './utils/css';

export const Wrap = css`
  padding-top: 56px;
  /* height: 100%; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  /* height: calc(100vh - 56px); */

  .filters {
    background-color: #fff;
    margin-top: 21px;
    padding: 0 40px;
  }

  .fullLookup--title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -1px;
    text-align: left;

    color: #9b51e0;
  }

  .fullLookup--lookup--period {
    margin-bottom: 40px;
  }
  .fullLookup--lookup--period,
  .fullLookup--list {
    > .name {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -1px;
      text-align: left;

      display: flex;
      align-items: center;
      gap: 4px;
    }
    > .input--list {
      height: 64px;
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }
    > .filter--list {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        & > * {
          width: max-content;
        }
      }
      .btn--search {
        margin-left: 10px;
        padding: 0 14px;
        height: 32px;
        color: #ececec;
        background: #9b51e0;
        border-radius: 2px;
        &.disabled {
          color: #fff;
          background: #cbcbcb;
          cursor: not-allowed;
        }
      }
      .btn--reset {
        padding: 0 14px;
        height: 32px;
        line-height: 32px;
        color: #242424;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
      }
    }
    > .filter--result {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .result--item {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 5px 14px;
        background-color: #fafafa;
        border-radius: 2px;
        .arrow {
          width: 6px;
          height: 8px;
          line-height: 0;
        }
        .close {
          margin-left: 2px;
        }
      }
    }
  }
  .main--button--refresh {
    margin-bottom: 16px;
    border: 1px solid #cbcbcb;
    padding: 5px 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;

    &:hover {
      color: #9b51e0;
    }
  }
  .language--span--refresh {
    height: 2.8rem;
    top: 1rem;

    display: flex;
    align-items: flex-end;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  .project--dl--info {
    display: flex;
    flex-basis: calc(100% - 200px);
    justify-content: center;
    align-items: center;
    font-weight: 500;
    dd {
      font-size: 0.875rem;
      > button {
        margin-right: 8px;
      }
    }
  }
  .mask {
    position: fixed;
    background-color: #0006;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 1100;
    &.open {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      [class*='modal--div--'] {
        max-height: initial;
      }
    }
  }
  .filter-save-reset-button {
    margin-left: 2px;
    width: calc(100vw - 40px * 2);
  }
  .content-list {
    /* width: calc(100vw - 40px * 2); */
    height: calc(100vh - 195px);
    overflow: auto;
    background-color: #fafafa;
    .row-1-category-list {
      padding: 0 40px 90px;
    }
  }
`;
