import { css } from '@emotion/react';
import { Color } from '@common/styles/color';

export const FileUpdateStyle = css`
  font-family: 'IBM Plex Sans', sans-serif;
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  .task--info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 20px;
    padding: 10px 16px;
    background-color: #fafafa;
    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      padding-left: 4px;
      > .task--status {
        position: relative;
        padding-left: 17px;
        font-size: 14px;
        color: #727272;
        > span {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 1px;
        }
      }
      > p {
        color: #242424;
      }
    }
    > p {
      text-align: left;
      color: #727272;
    }
  }
  .sub--task--wrap {
    margin-bottom: 8px;
    .titlebox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > strong {
        margin-right: 8px;
        font-size: 16px;
        color: #242424;
      }
      > p {
        margin-right: auto;
        font-size: 12px;
        color: #eb760a;
      }
      > span {
        color: #727272;
      }
    }
  }
  .file--checkbox--wrap {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 20px;
    max-height: 400px;
    overflow-y: auto;
    > div {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      padding: 10px 16px;
      min-height: 68px;
      background-color: #fafafa;
      box-sizing: border-box;
      .file--checkbox--item {
        > .file--checkbox--item--left {
          display: flex;
          align-items: center;
          gap: 4px;
          .disabled {
            opacity: 0.5;
          }
          .task--status {
            position: relative;
            padding-left: 17px;
            font-size: 14px;
            color: #727272;
            > span {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              border-radius: 1px;
            }
          }
        }
        > .file--checkbox--item--right {
          display: inline-flex;
          align-items: center;
          gap: 4px;
          flex-wrap: wrap;
          padding-left: 25px;
          text-align: left;
          color: #727272;
          & > svg,
          & > div {
            display: inline-block;
          }
        }
      }
      .file--title {
        display: flex;
        align-content: center;
        margin-left: auto;
        width: 340px;
        text-align: left;
        line-height: 1.3;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .file--status {
        margin-left: 16px;
      }
    }
  }
  .file--upload--wrap {
    text-align: left;
    > strong {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
      > span {
        margin-left: 2px;
        color: #e6311a;
      }
    }
    > ul {
      display: flex;
      gap: 25px;
    }
  }
`;
