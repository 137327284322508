import { css } from '@emotion/react';
import { cssResetButton } from './utils/css';

export const Wrap = css`
  padding-top: 56px;
  /* height: 100%; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  /* height: calc(100vh - 56px); */

  .filters {
    background-color: #fff;
    margin-top: 21px;
    padding: 0 40px;
  }

  .fullLookup--title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -1px;
    text-align: left;

    color: #9b51e0;
  }

  .fullLookup--lookup--period {
    margin-bottom: 40px;
  }
  .fullLookup--lookup--period,
  .fullLookup--list {
    > .name {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -1px;
      text-align: left;

      display: flex;
      align-items: center;
      gap: 4px;
    }
    > .input--list {
      height: 64px;
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }
    > .filter--list {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        & > * {
          width: max-content;
        }
      }
      .btn--search {
        margin-left: 10px;
        padding: 0 14px;
        height: 32px;
        color: #ececec;
        background: #9b51e0;
        border-radius: 2px;
        &.disabled {
          color: #fff;
          background: #cbcbcb;
          cursor: not-allowed;
        }
      }
      .btn--reset {
        padding: 0 14px;
        height: 32px;
        line-height: 32px;
        color: #242424;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
      }
    }
    > .filter--result {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding-bottom: 24px;
      .result--item {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 5px 14px;
        background-color: #fafafa;
        border-radius: 2px;
        .arrow {
          width: 6px;
          height: 8px;
          line-height: 0;
        }
        .close {
          margin-left: 2px;
        }
      }
    }
  }
  .content--wrap {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    overflow-y: auto;
    > .content--box {
      position: relative;
      padding: 20px 30px;
    }
    > .content--none {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #727272;
      > p {
        line-height: 150%;
      }
    }
    .part--performance {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      > .part--performance--date {
        position: absolute;
        top: 18px;
        right: 40px;
        color: #727272;
        > span {
          margin-left: 2px;
        }
      }
      > .part--performance--title {
        display: flex;
        align-items: center;
        gap: 9px;
        font-size: 18px;
        font-weight: 700;
        color: #242424;
        > svg {
          position: relative;
          top: -2px;
        }
      }
      .layout--amount {
        display: grid;
        grid-template-columns: 357px 1fr;
        gap: 16px;
        > .table--wrap {
          grid-row: 1 / span 2;
          grid-column: 2 / span 1;
          height: 360px;
        }
      }
    }
    .worker--performance {
      &:not(:last-child) {
        margin-bottom: 44px;
      }
      > .worker--performance--date {
        position: absolute;
        top: 18px;
        right: 40px;
        color: #727272;
        > span {
          margin-left: 2px;
        }
      }
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;
        .img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          font-size: 18px;
          color: #242424;
        }
      }
      .worker--tab {
        display: flex;
        gap: 20px;
        margin-bottom: 12px;
        > li {
          display: flex;
          align-items: center;
          gap: 2px;
          position: relative;
          padding-bottom: 8px;
          color: #727272;
          cursor: pointer;
          &::after {
            display: none;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #242424;
          }
          &.active {
            font-weight: 600;
            color: #242424;
            &::after {
              display: block;
            }
          }
          svg {
            position: relative;
            top: -1px;
          }
        }
      }
      .worker--data {
        display: grid;
        grid-template-columns: 462px 357px 1fr;
        gap: 16px;
        > .graph--wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 28px;
          height: 239px;
          background-color: #fff;
          border: 1px solid #ececec;
          border-radius: 2px;
          .graph--box {
            width: 200px;
            height: 200px;
            .no-data {
              display: block;
              width: 100%;
              height: 100%;
              text-indent: -9999px;
              background-color: #ececec;
              border-radius: 100%;
            }
          }
          .graph--list {
            display: flex;
            flex-direction: column;
            gap: 16px;
            min-width: 147px;
            > li {
              > strong {
                position: relative;
                padding-left: 16px;
                font-weight: 400;
                color: #242424;
                &::before {
                  content: '';
                  position: absolute;
                  top: 3px;
                  left: 0;
                  width: 10px;
                  height: 10px;
                  border-radius: 1px;
                }
                &.translator::before {
                  background-color: #00c2d9;
                }
                &.firstChecker::before {
                  background-color: #ff58a5;
                }
                &.secondChecker::before {
                  background-color: #ad50bf;
                }
              }
              > p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px;
                color: #727272;
              }
            }
          }
        }
        > .graph--amount {
          height: 239px;
          > .amount--list {
            height: 100%;
            > li {
              color: #727272;
              > strong {
                font-weight: 600;
                color: #242424;
                > span {
                  margin-left: 3px;
                  font-weight: 400;
                  &.positive {
                    color: #0072b2;
                  }
                  &.negative {
                    color: #d55e00;
                  }
                }
              }
            }
          }
        }
        > .table--wrap {
          height: 239px;
        }
      }
      .data--none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        color: #727272;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
      }
    }
    .resource--wrap {
      .worker--resource--date {
        margin-bottom: 5px;
        text-align: right;
        color: #727272;
        > span {
          margin-left: 2px;
        }
      }
      .resource--list {
        .table--wrap {
          tr {
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
          td {
            padding: 0 18px;
            height: 44px;
            color: #727272;
          }
        }
        .user--info {
          display: flex;
          align-items: center;
          gap: 4px;
          .img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .workload--percentage {
          display: flex;
          align-items: center;
          gap: 8px;
          .bar {
            position: relative;
            top: -2px;
            max-width: 500px;
            height: 3px;
            border-radius: 5px;
            &.BUFFER {
              background-color: #009e73;
            }
            &.SMOOTH {
              background-color: #56b4e9;
            }
            &.OPTIMAL {
              background-color: #9b51e0;
            }
            &.OVERLOAD {
              background-color: #ff5c00;
            }
          }
          .bar--value {
            span {
              font-size: 10px;
            }
            &.BUFFER {
              color: #009e73;
            }
            &.SMOOTH {
              color: #56b4e9;
            }
            &.OPTIMAL {
              color: #9b51e0;
            }
            &.OVERLOAD {
              color: #ff5c00;
            }
          }
        }
        .BUFFER {
          color: #009e73;
        }
        .SMOOTH {
          color: #56b4e9;
        }
        .OPTIMAL {
          color: #9b51e0;
        }
        .OVERLOAD {
          color: #ff5c00;
        }
      }
    }
    .amount--list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 28px;
      background-color: #fff;
      border: 1px solid #ececec;
      border-radius: 2px;
      > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
          color: #727272;
        }
        > strong {
          font-weight: 600;
          color: #242424;
        }
      }
    }
    .table--wrap {
      height: 100%;
      background-color: #fff;
      border: 1px solid #ececec;
      border-radius: 2px;
      overflow-y: auto;
      table {
        width: 100%;
        border-collapse: collapse;
        .overflowing {
          box-shadow: 0 0px 60px 0px rgba(0, 0, 0, 0.1);
        }
        thead {
          position: sticky;
          top: 0;
        }
        th {
          padding: 0 28px;
          height: 34px;
          font-weight: 500;
          text-align: center;
          color: #242424;
          background-color: #fff;
          border-bottom: 1px solid #ececec;
        }
        td {
          padding: 0 28px;
          height: 34px;
          font-weight: 400;
          text-align: center;
          color: #242424;
          border-bottom: 1px solid #ececec;
        }
        .text--right {
          text-align: right;
        }
        .text--left {
          text-align: left;
        }
        .collapse-wrap {
          display: flex;
          align-items: center;
        }
        .collapse-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          width: 18px;
          height: 18px;
          border: 1px solid #ececec;
          border-radius: 100%;
        }
      }
      &::-webkit-scrollbar {
        width: 4px; /* 스크롤바의 너비 */
      }

      &::-webkit-scrollbar-thumb {
        background: #ececec; /* 스크롤바의 색상 */
        border-radius: 50px;
      }

      /* &::-webkit-scrollbar-track {
        background: transparent;
      } */
    }
    /************************ 업무 리텐션 (S) ************************/
    .work--retention {
      .content--title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        strong {
          font-size: 18px;
          color: #242424;
        }
        p {
          font-size: 14px;
          color: #727272;
        }
      }
      .content--graph {
        position: relative;
        margin-bottom: 36px;
        padding: 70px 10px 36px;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
        .select--wrap {
          position: absolute;
          top: 10px;
          right: 20px;
          display: flex;
        }
        .legend--list {
          display: flex;
          justify-content: center;
          gap: 8px;
          padding-top: 10px;
          li {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 5px 10px 4px;
            border: 1px solid #ececec;
            border-radius: 10px;
            span {
              position: relative;
              top: -1px;
              width: 8px;
              height: 8px;
              border-radius: 100%;
            }
          }
        }
        > .content--none {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #727272;
          > p {
            line-height: 150%;
          }
        }
      }
      .content--table {
        table {
          tr {
            &.active {
              background-color: rgba(155, 81, 224, 0.06);
              .collapse-btn {
                transform: rotate(-90deg);
              }
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
            td {
              padding: 0 10px;
              color: #727272;
              &:first-of-type {
                padding: 0 10px;
              }
              &.compare--td {
                padding: 0;
              }
            }
          }
        }
      }
      .compare--content {
        background-color: #fafafa;
        .compare--graph--box {
          padding: 16px 28px 24px;
          .compare--title--box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            .compare--title--text {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 18px;
              font-weight: 700;
              color: #242424;
              > div {
                position: relative;
                top: -2px;
              }
            }
            .compare--toggle {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 160px;
              height: 48px;
              background-color: rgba(155, 81, 224, 0.06);
              border-radius: 12px;
              z-index: 1;
              .toggle--button {
                width: 70px;
                line-height: 38px;
                height: 38px;
                font-size: 15px;
                color: #727272;
                &.active {
                  color: #fff;
                }
              }
              .toggle--background {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                display: block;
                width: 70px;
                height: 38px;
                background-color: #1a1636;
                border-radius: 12px;
                z-index: -1;
                transition: all 0.3s ease;
                &.active {
                  left: 80px;
                }
              }
            }
          }
          .compare--graph {
            .legend--list {
              display: flex;
              justify-content: center;
              gap: 8px;
              padding-top: 10px;
              li {
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 5px 10px 4px;
                border: 1px solid #ececec;
                border-radius: 10px;
                span {
                  position: relative;
                  top: -1px;
                  width: 8px;
                  height: 8px;
                  border-radius: 100%;
                }
              }
            }
          }
        }
        .compare--table--box {
          table {
            th {
              background-color: #fafafa;
              border-top: 1px solid #ececec;
            }
            td {
              &:nth-of-type(1) {
                padding-left: 36px;
              }
            }
          }
        }
      }
    }
    /************************ 업무 리텐션 (E) ************************/
    > footer {
      margin-top: auto;
    }
  }
`;
