/* eslint-disable react-hooks/rules-of-hooks */
import { ReactComponent as IcoGoogleNew } from '@resources/icon/ico-google-new.svg';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { useAuthorizationGoogle } from '@src/hooks/auth/postAuthGoogle';
import { useEffect, useRef } from 'react';
import Config from '@src/common/config/Config';
import '../Login.style';

export default function GoogleLoginBtn() {
  const { postAuthGoogle } = useAuthorizationGoogle();
  //const googleSignInButton = useRef(null);
  const googleRef = useRef<HTMLDivElement>(null);

  const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  // useScript({
  //   url: 'https://accounts.google.com/gsi/client',
  //   onload: () => {
  //     window.google.accounts.id.initialize({
  //       client_id: Config.googleClientId,
  //       callback: (res: any) => {
  //         const { email } = parseJwt(res.credential);
  //         postAuthGoogle({ email, tokenId: res.credential });
  //       },
  //       auto_select: true
  //     });
  //   }
  // });

  useEffect(() => {
    const script = document.createElement('script');
    // script.src = 'https://accounts.google.com/gsi/client';
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);

    script.onload = () => {
      const { google } = window as any;
      const redirectUrl = `/login`;

      google.accounts.id.initialize({
        client_id: Config.googleClientId,
        callback: async (res: any) => {
          const { email } = await parseJwt(res.credential);
          await postAuthGoogle({ email, tokenId: res.credential });
        },
        auto_select: false,
        login_uri: redirectUrl
      });
      google.accounts.id.renderButton(googleRef.current, {
        theme: 'outline',
        size: 'large',
        width: '508px'
      });
    };
  }, []);

  // const onClickGooglelogin = () => {
  //   window.google.accounts.id.prompt();
  // };

  return (
    <>
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          id="google-login-button"
          ref={googleRef}
          style={{
            opacity: 0,
            position: 'absolute',
            zIndex: 100,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
          }}
        />
        {/* <div></div> */}
      </div>
      {/* <TPButtonBasic
        label="구글로 계속하기"
        fullWidth
        variant="outlined"
        size="large"
        startIcon={<IcoGoogleNew />}
        // onClick={onClickGooglelogin}
      /> */}
      <button className="googleLoginBtn">
        <IcoGoogleNew />
        구글로 계속하기
      </button>
    </>
  );
}
