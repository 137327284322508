import { IconAlarmUrl, IconPlusRadiusUrl } from '@resources/icon';
import { Box } from '@mui/material';
import { css } from '@emotion/react';
import { Color } from '@common/styles/color';
import { IconArrowDownOffUrl, IconSheetHiddenUrl } from '@src/resources/icon';

export const TaskCreateStyle = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 20px 30px;
  width: 756px;
  max-height: calc(100% - 40px);
  background-color: #fff;
  text-align: center;
  .alertClose {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .lengthCheck {
    position: relative;
    & > span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0.75rem;
      s {
        text-decoration: none;
      }
    }
    &.lengthError {
      span s {
        color: red;
      }
      textarea {
        border-color: red;
        &:focus-visible {
          outline: 1px solid red;
        }
      }
      fieldset {
        border-color: red;
      }
    }
  }
  .segment--wrap {
    padding: 0 16px;
  }
  .title {
    margin-bottom: 55px;
  }
  .filebox {
    margin-bottom: 12px;
    padding: 10px 16px;
    background-color: #fafafa;
    > .filebox--title {
      display: flex;
      align-items: center;
      gap: 8px;
      > .filebox--status {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #727272;
      }
      > .filebox--language {
        display: flex;
        gap: 4px;
        font-weight: 600;
      }
    }
    > .filebox--content {
      text-align: left;
      color: #727272;
    }
  }

  .modal--div--wrapper {
    position: relative;
    padding: 8px 0;
    text-align: left;
    > strong {
      font-weight: 400;
      span {
        font-size: 12px;
        color: #727272;
      }
    }
    .fileInfo {
      display: flex;
      gap: 12px;
      align-items: center;
      span {
        padding: 0 4px;
        height: 19px;
        font-weight: 500;
        font-size: 12px;
        color: #727272;
        background-color: #f3f3f3;
        border-radius: 2px;
      }
      p {
        flex: 1;
      }
    }
    .sheet--close {
      position: absolute;
      right: 0;
      top: 10px;
      width: 18px;
      height: 18px;
    }
    .sheet--wrap {
      position: relative;
      margin-bottom: 8px;
      &.close {
        display: none;
      }
      > button {
        display: block;
        padding: 0 32px 0 14px;
        min-width: 220px;
        max-width: 100%;
        height: 40px;
        line-height: 40px;
        font-weight: 400;
        text-align: left;
        color: #727272;
        background: url(${IconArrowDownOffUrl}) no-repeat right 14px center;
        border: 1px solid #ececec;
        border-radius: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .sheet--hidden {
          position: relative;
          top: 3px;
          margin-right: 4px;
          width: 16px;
          height: 16px;
          line-height: 16px;
        }
      }
      > ul {
        position: absolute;
        top: 44px;
        left: 0;
        padding: 4px 0;
        min-width: 150px;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
        z-index: 1;
        > li {
          padding: 4px 16px 4px 14px;
          &.hidden {
            padding-left: 34px;
            background: url(${IconSheetHiddenUrl}) no-repeat left 14px top 6px;
          }
          &:hover {
            cursor: pointer;
            text-decoration: underline 1px;
          }
        }
      }
    }
    .modal--div--checkbox {
      display: flex;
      justify-content: flex-start;
      gap: 28px;
      &.close {
        display: none;
      }
    }
  }
  .modal--div--box {
    margin-bottom: 16px;
    width: 100%;
    overflow: auto;
    height: 300px;
    &.close {
      display: none;
    }
    .modal--table--table {
      /* white-space: nowrap; */

      /* table-layout: fixed; */
      /* max-width: 100%; */
      width: 100%;
      border-collapse: collapse;
      border: none;
      &.more {
        width: max-content;
      }
      td {
        padding: 9px 10px;
        min-width: 80px;
        text-align: left;
        /* white-space: normal; */
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
        color: #727272;
        background-color: #fafafa;
        border: 2px solid #fff;
        width: auto;
        word-break: break-all;
        &.title {
          padding: 0px;
          height: 22px;
          width: 100px;
          text-align: center;
        }
        &.one {
          width: 100%;
        }
        &.two {
          width: 50%;
        }
        &.three {
          width: 33.33%;
        }
        &.four {
          width: 25%;
        }
        &.more {
          width: auto;
          max-width: 200px;
          word-break: break-all;
        }
      }
    }

    table,
    th,
    td {
      border: 1px solid #d5d5d5;
    }

    &.non-scroll {
      overflow: visible;
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(213, 211, 228);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgb(255, 255, 255);
      margin-left: 10px;
    }
    .title {
      text-align: left;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .valid {
      color: ${Color.compulsory};
      width: 100%;
      text-align: left;
      font-size: 0.75rem;
      margin-left: 14px;
    }
    .color--red {
      color: ${Color.compulsory};
    }
    .input--text--wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: flex-end;
      > div,
      > p {
        flex-grow: 1;
      }
      button {
        flex-shrink: 0;
        height: 56px;
      }
    }
    .input--text--ci {
      padding: 60px 0 0;
    }
    .input--checkbox--wrap {
      display: flex;
      > div:hover {
        background: transparent;
      }
    }
    .input--checkbox--stockrefine {
      /* display: flex; */
      /* align-items: center; */
      padding: 5px 0;
      > div:hover {
        background: transparent;
      }
    }
  }
  .modal--div--bottom {
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(213, 211, 228);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgb(255, 255, 255);
      margin-left: 10px;
    }
    .title--box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .segment--title {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #242424;
        > button {
          position: relative;
          top: -1px;
          width: 16px;
          height: 16px;
          text-indent: -9999px;
          background: url(${IconPlusRadiusUrl}) no-repeat center center;
        }
      }
      .segment--reset {
        margin-left: auto;
        button {
          width: 67px;
          height: 32px;
          color: #242424;
          border: 1px solid #ececec;
          border-radius: 2px;
          box-sizing: border-box;
        }
      }
    }
    .title {
      display: flex;
      gap: 9px;
      margin-bottom: 4px;
      > div {
        width: 205px;
        color: #727272;
        text-align: left;
        &:nth-of-type(2) {
          margin-left: 13px;
        }
        &.target--info {
          display: flex;
          align-items: center;
          gap: 4px;
          > div {
            position: relative;
            top: -1px;
          }
        }
        > .required {
          margin-left: 3px;
          color: #e6311a;
        }
        > .warning {
          margin-left: 8px;
          font-size: 11px;
          color: #eb760a;
        }
        > button {
          position: relative;
          top: 3px;
          left: 5px;
        }
      }
    }
    .segment--box {
      position: relative;
      margin-bottom: 8px;
      padding: 8px 16px 12px;
      background-color: #fafafa;
      .segment--delete {
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }
    .title--box {
      margin-bottom: 4px;
    }
    .segment--box--content {
      position: relative;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 8px;
      > span {
        margin-top: 10px;
      }
    }
    .option--add {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      > span {
        padding-right: 20px;
        height: 22px;
        line-height: 24px;
        font-weight: 500;
        color: #727272;
        background: url(${IconPlusRadiusUrl}) no-repeat right center;
        cursor: pointer;
      }
    }
    .option--box {
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;
        &.option--title {
          margin: 10px 0 5px;
          color: #727272;
        }
        > span {
          color: #aaaaaa;
        }
        .option--delete {
          width: 16px;
          height: 16px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .selectbox {
      position: relative;
      width: 205px;
      .select--list {
        width: 100%;
        > li.dim {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
      > button {
        padding: 0 12px;
        width: 100%;
        height: 40px;
        text-align: left;
        font-weight: 400;
        color: #727272;
        background: #fff url(${IconArrowDownOffUrl}) no-repeat right 14px center;
        border: 1px solid #ececec;
        border-radius: 2px;
        box-sizing: border-box;
        &.disabled {
          background-color: #fafafa;
          cursor: not-allowed;
        }
      }
      > .select--list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        margin-top: 4px;
        padding: 8px 14px;
        max-height: 105px;
        background-color: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
        z-index: 1;
        overflow-y: auto;
        box-sizing: border-box;
        &.out--select {
          position: fixed;
          margin-top: -144px;
        }

        &::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgb(213, 211, 228);
          border-radius: 20px;
        }
        &::-webkit-scrollbar-track {
          background-color: rgb(255, 255, 255);
          margin-left: 10px;
        }
        > li {
          text-align: left;
          cursor: pointer;
          &.dim {
            /* color: #ececec; */
            opacity: 0.3;
            cursor: not-allowed;
          }
          label {
            width: 100%;
          }
        }
      }
      .warning {
        margin-top: 4px;
        font-size: 10px;
        text-align: left;
        color: #e6311a;
      }
    }
    .hyphen {
      line-height: 40px;
    }
    > button {
      margin-left: auto;
      height: 40px;
      line-height: 44px;
    }
  }

  .Mui-selected {
    color: #d463fc !important;
  }
  .MuiTabs-indicator {
    background-color: #d463fc;
  }
`;
