import api from '@api/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fileManagementKeys } from '@hooks/queryKeyFactory';
import _ from 'lodash';
import { useAlert } from '../modal/useAlert';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../modal/useToast';
import { useState } from 'react';
import { IFileUploadStatus } from '@src/pages/modal/FileUpdate.page';

export const useGetFileUpload = (taskId: number) => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { openHandle: toastOpen } = useToast();
  const navigate = useNavigate();
  const getData = async (taskId: number) => {
    return await api.get(`/task/fileUpload/${taskId}`).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data } = useQuery(['fileUpload', taskId], () => getData(taskId));

  const putData = async (payload: IFileUploadStatus) => {
    return await api.put('/task/fileUpload', { ...payload });
  };

  const { mutate: putStatus } = useMutation({
    mutationFn: putData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/projectDetail']);
      queryClient.invalidateQueries(['fileUpload', taskId]);
      queryClient.invalidateQueries(['DashboardKeys']);
      queryClient.invalidateQueries(['/task']);
      toastOpen({
        text: '작업 파일 등록 상태 변경이 되었습니다.',
        severity: 'success'
      });
      const { state } = window.history;
      if (state?.projectId && state?.taskId) {
        window.history.replaceState({}, '');
      } else {
        navigate(-1);
      }
    },
    onError: () => {
      toastOpen({
        text: '작업 파일 등록 상태 변경이 되지 않았습니다.',
        severity: 'error'
      });
    }
  });

  return {
    data,
    isLoading,
    getData,
    putStatus
  };
};
