import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';

interface TaskInfo {
  languageTaskId: number;
  key: string;
  companyName: string;
  projectTitle: string;
  taskTitle: string;
  startLanguage: string;
  destinationLanguage: string;
  isUpload: boolean;
  isTaskCreated: boolean;
  status: string;
  fileName: string;
  totalSegmentCount: number;
  totalWordCount: number;
}

export const useGetTaskInfo = (
  fileId: number
): { data: TaskInfo | undefined } => {
  //#region api call
  const getData = async (): Promise<TaskInfo> => {
    return await api
      .get(`/project/languageTaskListByUpload/${fileId}`)
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data } = useQuery(['taskInfo', fileId], getData);

  //#endregion

  return { data };
};
