import React, { useEffect, useState } from 'react';
import { IWorkData, IWorkMonthGraphData } from './WorkRetention.page';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Props as LegendContentProps } from 'recharts/types/component/DefaultLegendContent';

interface ITransformedData {
  name: string;
  [key: string]: number | string;
}

const WorkMonthGraph = ({ retentionData }: { retentionData: IWorkData }) => {
  const [graphData, setGraphData] = useState<IWorkMonthGraphData[]>();
  const [transformedData, setTransformedData] = useState<ITransformedData[]>();

  const renderLegend = (props: LegendContentProps) => {
    const { payload } = props;
    return (
      <ul className="legend--list">
        {payload?.map((entry, index) => (
          <li key={`item-${index}`}>
            <span style={{ backgroundColor: entry.color }}></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    const sortedGraphData = [
      ...(retentionData.monthlyRetentionList || [])
    ].sort((a, b) => {
      const aName = (a.companyName || a.projectTitle || '').toLowerCase();
      const bName = (b.companyName || b.projectTitle || '').toLowerCase();
      return aName.localeCompare(bName, ['ko-KR', 'en-US']);
    });
    setGraphData(sortedGraphData);
  }, [retentionData]);

  useEffect(() => {
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec'
    ];

    const filteredData = graphData?.filter(
      (item) => item.companyName || item.projectTitle
    );

    const newTransformedData = months.map((month) => ({
      name: month.toUpperCase(),
      ...Object.fromEntries(
        filteredData?.map((item) => {
          const identifier = item.companyName || item.projectTitle;
          return [
            identifier,
            graphData?.find((data) =>
              item.companyName
                ? data.companyName === item.companyName
                : data.projectTitle === item.projectTitle
            )?.[month as keyof IWorkMonthGraphData] || 0
          ];
        }) ?? []
      )
    }));

    setTransformedData(newTransformedData);
  }, [graphData]);

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={transformedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="1" vertical={false} />
          <XAxis dataKey="name" type="category" />

          <Tooltip />
          <Legend iconType="circle" iconSize={10} content={renderLegend} />
          {graphData?.map((item, index) => (
            <Line
              type="linear"
              dataKey={(item.companyName || item.projectTitle) as string}
              stroke={`hsl(${index * 100}, 50%, 50%)`}
              strokeWidth={2}
              key={item.companyName || item.projectTitle}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WorkMonthGraph;
