import { TPInputCheckBoxWrapBasic } from './TPInput.theme';
import { Box, Checkbox } from '@mui/material';
import { IconChecked, IconUnChecked } from '@resources/icon';
import { TPAvatarBasic } from '../avatar/TPAvatar.Basic';

interface IInputCheckBoxProps {
  option: string;
  checked?: boolean;
  optionKey: string;
  selectOptions?: (string | number)[];
  avatarUrl?: string;
  showAvatar?: boolean;
  onClick: (state: string) => void;
  status?: string;
  segmentList?: { type: string; text: string; checked: boolean };
  disabled?: boolean;
}

export const TPInputCheckBoxBasic = (props: IInputCheckBoxProps) => {
  const {
    option,
    optionKey,
    selectOptions,
    avatarUrl,
    onClick,
    showAvatar,
    status,
    disabled
  } = props;

  const checked = selectOptions?.includes(optionKey);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onClick(optionKey);
  };

  const activeValid = () => {
    let name = option;

    if (status === 'INACTIVE') {
      name = `${option} (비활성화)`;
    } else if (status === 'LEAVE') {
      name = '삭제된 사용자';
    }

    return name;
  };

  return (
    <TPInputCheckBoxWrapBasic
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) return;
        onClick(optionKey);
      }}
    >
      <label>
        <input
          type="checkbox"
          checked={checked}
          value={option}
          onChange={handleChange}
          disabled={disabled}
        />
        <span></span>
        <Box>{activeValid()}</Box>
      </label>
    </TPInputCheckBoxWrapBasic>
  );
};

export const TPInputCheckSingleBoxBasic = (props: IInputCheckBoxProps) => {
  const { option, optionKey, segmentList, onClick } = props;

  const checked = segmentList?.checked;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onClick(optionKey);
  };

  return (
    <TPInputCheckBoxWrapBasic
    // onClick={(e) => {
    //   e.stopPropagation();
    //   onClick(optionKey);
    // }}
    >
      <label>
        <input
          type="checkbox"
          checked={checked}
          value={option}
          onChange={handleChange}
        />
        <span></span>
        <Box>{option}</Box>
      </label>
    </TPInputCheckBoxWrapBasic>
  );
};

export const TPInputCheckMultipleBoxBasic = (props: IInputCheckBoxProps) => {
  const { option, optionKey, onClick, checked } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    onClick(optionKey);
  };

  return (
    <TPInputCheckBoxWrapBasic
    // onClick={(e) => {
    //   e.stopPropagation();
    //   onClick(optionKey);
    // }}
    >
      <label>
        <input
          type="checkbox"
          checked={checked}
          value={option}
          onChange={handleChange}
        />
        <span></span>
        <Box>{option}</Box>
      </label>
    </TPInputCheckBoxWrapBasic>
  );
};
