import { ThemeProvider } from '@mui/material/styles';
import { TPInputTextTheme, TPTextAreaWrap } from './TPInput.theme';
import {
  Box,
  FormLabel,
  TextField,
  TextareaAutosize,
  FormHelperText
} from '@mui/material';
import { ChangeEvent } from 'react';

interface IInputProps {
  value?: string;
  label?: string;
  required?: boolean;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  startAdornment?: any;
  endAdornment?: string;
  fieldType?: string;
  size?: 'medium' | 'small';
  charType?: 'paragraph' | 'title' | 'area' | string;
  mt?: number;
  changeText?: (e: ChangeEvent) => void;
}

export const TPInputText = (props: IInputProps) => {
  const {
    value,
    label,
    required = false,
    name,
    placeholder,
    helperText,
    startAdornment,
    endAdornment,
    disabled = false,
    fieldType = 'input',
    size = 'medium',
    charType = 'paragraph',
    mt,
    changeText = () => {}
  } = props;

  return (
    <ThemeProvider theme={TPInputTextTheme}>
      <div>
        {label ? (
          <Box
            sx={{
              display: 'flex',
              textAlign: 'left',
              mt: mt,
              opacity: disabled ? 0.5 : 1
            }}
          >
            <FormLabel htmlFor={name}>
              {label}
              {required ? (
                <Box component={'sup'} color="#E6311A">
                  *
                </Box>
              ) : null}
            </FormLabel>
          </Box>
        ) : null}
        <Box display={'flex'} flexDirection={'column'} mt={label ? 1 : mt}>
          {charType === 'area' ? (
            <TPTextAreaWrap>
              <TextareaAutosize
                value={value}
                id={name}
                placeholder={placeholder}
                onChange={changeText}
              />
              {helperText ? (
                <FormHelperText>{helperText}</FormHelperText>
              ) : null}
            </TPTextAreaWrap>
          ) : (
            <TextField
              className={
                ['title', 'amount'].includes(charType)
                  ? `input--${charType}`
                  : undefined
              }
              value={value}
              id={name}
              size={size}
              type={fieldType}
              disabled={disabled}
              placeholder={placeholder}
              helperText={helperText}
              onChange={changeText}
              InputProps={
                endAdornment || startAdornment
                  ? {
                      startAdornment: startAdornment,
                      endAdornment: endAdornment
                    }
                  : undefined
              }
            />
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};
