import { useHookFunc } from '@hooks/utils';
import { format, add } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { IPMSFilterData } from '@pages/dashboard/types';
import { ILanguageTask } from '@src/common/config/IProjectDetail';

function compare(a1: any[], a2: any[]): boolean {
  a1 = [...a1];
  a2 = [...a2];
  const rs =
    a1
      .filter((e) => e !== 'ALL' && e !== '0')
      .sort()
      .join() ===
    a2
      .filter((e) => e !== 'ALL' && e !== '0')
      .sort()
      .join();
  return rs;
}

export function usePmsFilter(filterData: any) {
  const { arraySet } = useHookFunc();

  const initialDataMap = {
    workUserIdList: [],
    projectIdList: ['ALL'],
    companyIdList: ['ALL'],
    workloadType: ['ALL'],
    isFilter: false
  };

  const [tempKeyword, setTempKeyword] = useState<string>('');
  const [languagePairList, setLanguagePairList] = useState<ILanguageTask[]>([]);
  const [dashboardFilter, setDashboardFilter] = useState<IPMSFilterData>(
    () => ({ ...initialDataMap, ...filterData?.dashboardFilter })
  );

  const [searchDateFilter, setSearchDateFilter] = useState<{
    [key: string]: Date;
  }>({
    startDateTime: add(new Date(), { weeks: 0 }),
    endDateTime: add(new Date(), { years: 0 })
  });
  const [dateFilter, setDateFilter] = useState<{
    [key: string]: Date | undefined;
  }>({
    startDateTime: undefined,
    endDateTime: undefined
  });

  const dashboardFilterProxy = {
    setProjectList: (value: string) => {
      if (value === 'ALL') {
        // "전체" 선택 시 특별한 처리
        setDashboardFilter((state) => ({
          ...state,
          projectIdList: ['ALL'] // 'ALL'을 사용하여 모든 프로젝트가 선택된 것으로 처리
        }));
      } else {
        const projectList = _.filter(
          arraySet(dashboardFilter.projectIdList, value),
          (item) => item !== '0' && item !== 'ALL'
        );
        setDashboardFilter((state) => ({
          ...state,
          projectIdList: projectList.length ? projectList : ['0']
        }));
      }
    },
    setCustomerList: (value: string) => {
      if (value === 'ALL') {
        // "전체" 선택 시 특별한 처리
        setDashboardFilter((state) => ({
          ...state,
          companyIdList: ['ALL'] // 'ALL'을 사용하여 모든 프로젝트가 선택된 것으로 처리
        }));
      } else {
        const companyList = _.filter(
          arraySet(dashboardFilter.companyIdList, value),
          (item) => item !== '0' && item !== 'ALL'
        );
        setDashboardFilter((state) => ({
          ...state,
          companyIdList: companyList.length ? companyList : ['0']
        }));
      }
    },
    setWorkUserIdList: (value: string) => {
      const workUserIdList =
        value === '0'
          ? ['0']
          : _.filter(
              arraySet(dashboardFilter.workUserIdList, value),
              (item) => item !== '0'
            );
      setDashboardFilter((state) => ({
        ...state,
        workUserIdList: workUserIdList.length ? workUserIdList : ['0']
      }));
    },
    setworkloadType: (value: string) => {
      const workloadStatusType =
        value === 'ALL'
          ? ['ALL']
          : _.filter(
              arraySet(dashboardFilter.workloadType, value),
              (item) => item !== 'ALL'
            );
      setDashboardFilter((state) => ({
        ...state,
        workloadType: workloadStatusType.length ? workloadStatusType : ['ALL']
      }));
    },
    setIsFilter: (value: boolean) => {
      setDashboardFilter((state) => ({
        ...state,
        isFilter: value
      }));
    },
    clearFilter: () => {
      setTempKeyword('');
      setDashboardFilter((state) => ({
        ...state,
        ...initialDataMap
      }));
      setLanguagePairList([]);
      setSearchDateFilter({
        startDateTime: add(new Date(), { weeks: 0 }),
        endDateTime: add(new Date(), { years: 0 })
      });
      setDateFilter({
        startDateTime: undefined,
        endDateTime: undefined
      });
    },
    setLanguagePairList
  };
  //#endregion
  useEffect(() => {
    if (filterData) {
      setTempKeyword(filterData?.dashboardFilter?.keyword ?? '');
      setDashboardFilter((state) => ({
        ...state,
        ...filterData?.dashboardFilter
      }));
      if (filterData?.searchDateFilter)
        setSearchDateFilter(filterData?.searchDateFilter);
      if (filterData?.dateFilter) setDateFilter(filterData?.dateFilter);
    }
    setLanguagePairList((v) => filterData?.languagePairList ?? v ?? []);
  }, [filterData]);

  const isFilter = useMemo(() => {
    return !(
      compare(initialDataMap.workUserIdList, dashboardFilter.workUserIdList) &&
      compare(initialDataMap.projectIdList, dashboardFilter.projectIdList) &&
      dashboardFilter.keyword === ''
    );
  }, [dashboardFilter]);

  return {
    dashboardFilter,
    dashboardFilterProxy,
    languagePairList,
    setLanguagePairList,
    searchDateFilter,
    setSearchDateFilter,
    dateFilter,
    setDateFilter,
    isFilter
  };
}
