import { useQuery } from '@tanstack/react-query';
import api from '@api/Api';

export const useLanguageAll = () => {
  const { data, error, isLoading } = useQuery(['getLanguageAll'], async () => {
    try {
      const response = await api.getPms(`/searchFilter/selection/languageSpec`);
      return response.data;
    } catch (error) {
      console.error('languageSpec 오류 발생:', error);
      throw error;
    }
  });

  return { data, error, isLoading };
};
