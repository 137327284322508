import styled from '@emotion/styled';
import { useAlert as alert } from '@hooks/modal/useAlert';
import { useStateChange as stateChange } from '@hooks/modal/useStateChange';
import {
  Box,
  ClickAwayListener,
  Modal,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme
} from '@mui/material';
import {
  LANGUAGE,
  TypeCategory,
  WORKER_TYPE,
  getLanguageSubTaskStatusTypeLabel,
  getTaskStatusLabelColor,
  getStatusLabel,
  getTaskStatusTypeLabel,
  getTaskStatusLabel,
  getLanguageTaskStatusTypeLabel
} from '@src/common/config/Code';
import { textSlice } from '@src/common/utils/Func';
import { usePutMyWorkStatus } from '@src/hooks/myWork/putStatus';
import { useUpsertTask as handleUpsertTask } from '@src/hooks/task/post';
import JobDetailMyWork from '@src/pages/modal/JobDetailMyWork.page';
import {
  IcoMenuChevronDown,
  IcoTriangle,
  IconDetail,
  IconInfoBlack
} from '@src/resources/icon';
import TPAvatarGroupNew from '@src/units/avatar/TPAvatar.GroupNew';
import { AvatarContainer } from '@src/units/avatar/TPAvatar.GroupNewStyle';
import { StatusBadge } from '@src/units/badge/StatusBadge';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { SimpleTooltip } from '@src/units/tooltip/SimpleTooltip';
import { format, formatDistanceToNow } from 'date-fns';
import { ReactElement, ReactNode, cloneElement, useState } from 'react';
import { useApi4LanguageSubTaskList } from '../hooks/useApi4LanguageSubTaskList';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { ko } from 'date-fns/locale';
import { useToast } from '@src/hooks/modal/useToast';
import ModalType from '@src/common/config/ModalType';

export const LanguageColors = {};

export function renderNewLanguages(data: any) {
  let languages = LANGUAGE.filter((v) => data.includes(v.code));

  switch (languages.length) {
    case 0:
      return null;
    case 1:
      return languages[0].label;
    default:
      return textSlice(languages.map((e) => e.label));
  }
}

export function renderLanguages(data: any) {
  let languages = LANGUAGE.filter((v) => data.includes(v.code));

  switch (languages.length) {
    case 0:
      return null;
    case 1:
      return (
        <div
        // style={{
        //   color: languages[0]?.color ?? '#000000',
        //   backgroundColor: languages[0]?.backgroundColor ?? '#E5E5E5',
        //   height: 52,
        //   margin: '0px 6px',
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center'
        // }}
        >
          {languages[0].label}
        </div>
      );
    default:
      return (
        <div
        // style={{
        //   backgroundColor: '#E5E5E5',
        //   height: 52,
        //   margin: '0px 6px',
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center'
        // }}
        >
          {textSlice(languages.map((e) => e.label))}
        </div>
      );
  }
}

export function renderLanguageByCode(data: string) {
  // const lang = LANGUAGE.filter((el) => el.code && data.includes(el.code))?.[0]?.label;
  const lang = LANGUAGE.find((el) => el.code === data);
  const label = lang ? lang.label : data;
  return <div data-code={data}>{label}</div>;
}

export function renderLanguageByCodes(data: string[]) {
  const langs = LANGUAGE.filter((el) => el.code && data.includes(el.code));
  if (langs.length === 1)
    return (
      <div
        style={{
          padding: '4px 16px',
          borderRadius: 2
        }}
      >
        {langs[0].label}
      </div>
    );
  return (
    <div>
      <SimpleTooltip message={langs.join()}>다국어</SimpleTooltip>
    </div>
  );
}

export function renderUsers(data: any) {
  return <TPAvatarGroupNew userList={data} />;
}

export function renderAlarmDateTime(localDate: Date) {
  const dateTimeString = format(localDate, 'yyyy.MM.dd (E) HH:mm', {
    locale: ko
  });

  const distanceToNow = formatDistanceToNow(localDate, {
    addSuffix: true,
    includeSeconds: true,
    locale: ko
  }).replace(/(약|미만) /, '');

  return `${dateTimeString} (${distanceToNow})`;
}

export function renderDateNewTime(data: any, requiresStyle: boolean = true) {
  if (data) {
    const date = new Date(data);
    const style =
      requiresStyle &&
      format(date, 'yyyyMMdd hhmm') <= format(new Date(), 'yyyyMMdd HH:mm')
        ? { color: 'red' }
        : { color: '#727272' };

    return (
      <div className="info-item div-center" style={style}>
        {format(date, 'yy.MM.dd HH:mm').toLowerCase()}
      </div>
    );
  }
  return '';
}

export function renderDateTime(data: any, requiresStyle: boolean = true) {
  if (data) {
    const date = new Date(data);
    const style =
      requiresStyle &&
      format(date, 'yyyyMMdd hhmm') <= format(new Date(), 'yyyyMMdd HH:mm')
        ? { color: 'red' }
        : { color: '#727272' };

    return (
      <div style={style}>{format(date, 'yy.MM.dd HH:mm').toLowerCase()}</div>
    );
  }
  return '';
}

export function renderDateTimeNoWrap(data: any, requiresStyle: boolean = true) {
  if (data) {
    const date = new Date(data);
    const style =
      requiresStyle &&
      format(date, 'yyyyMMdd') <= format(new Date(), 'yyyyMMdd')
        ? { color: 'red' }
        : {};

    return (
      <div style={style}>
        {format(new Date(data), 'yyyy.MM.dd HH:mm').toLowerCase()}
      </div>
    );
  }
  return '';
}

export function renderTreeButton({ open, setOpen, style }: any) {
  return (
    <TreeButtonWrap
      style={style}
      onClick={() => {
        if (typeof setOpen === 'function') setOpen((v: any) => !v);
      }}
    >
      {open === undefined ? (
        <></>
      ) : open === true ? (
        <div>
          <IcoMenuChevronDown style={{ fill: '#aaa' }} />
        </div>
      ) : (
        <div>
          <IcoMenuChevronDown
            style={{ transform: 'rotate(180deg)', fill: '#aaa' }}
          />
        </div>
      )}
    </TreeButtonWrap>
  );
}
const TreeButtonWrap = styled.span`
  & > div {
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export function renderTaskStatus(data: any, onClick?: any) {
  return data ? (
    <StatusBadge
      statusCode={data}
      onClick={onClick}
      style={{
        userSelect: 'none',
        cursor: 'pointer',
        position: 'relative'
      }}
    />
  ) : (
    <></>
  );
}

export function renderSmallTaskStatus(data: any) {
  return data ? (
    <StatusBadge
      statusCode={data}
      // forceText={label}
      padding={`2px 8px`}
      width={'48px'}
      borderRadius={'0 8px'}
      height={'24px'}
    />
  ) : (
    <></>
  );
}

export function renderTaskType(data: any) {
  return data ? (TypeCategory[data] as string) : '';
}

export function renderLanguageTaskStatus(data: any) {
  return <StatusBadge statusCode={data ?? 'NEW'} />;
}

export function renderProgressingWork(data: any) {
  return WORKER_TYPE.find((v) => v.code === data)?.label;
}

export function renderAvatar(user: any) {
  if (user.grayScale === undefined) user.grayScale = '0%';
  if (user.brightness === undefined) user.brightness = 1;
  if (user.status === 'INACTIVE') {
    user.name = `${user.name} (비활성화)`;
    user.grayScale = '100%';
    user.brightness = 0.7;
  } else if (user.status === 'LEAVE') {
    user.name = `${user.name} (탈퇴)`;
    user.grayScale = '100%';
    user.brightness = 0.7;
  }

  return (
    <AvatarContainer index={0}>
      <SimpleTooltip message={user?.name ?? ''} position="center">
        <div className="avatar--div--wrap">
          {user?.avatarImgUrl ? (
            <img
              src={user?.avatarImgUrl ?? ''}
              alt={''}
              style={{
                filter: `grayscale(${user?.grayScale ?? '0%'}) brightness(${
                  user?.brightness ?? 0
                })`
              }}
            />
          ) : null}
        </div>
      </SimpleTooltip>
    </AvatarContainer>
  );
}

export function renderWorkerType(data: any) {
  return WORKER_TYPE.find((e) => e.code === data)?.label ?? data;
}

export function renderOption(data: any) {
  const { myInfo } = useMyInfo();
  const isPML = myInfo?.roles.includes('PML');
  const isPM = myInfo?.roles.includes('PM');
  const navigate = useNavigate();
  const { deleteTask, putTaskStatus } = handleUpsertTask();
  const {
    projectId,
    taskId,
    taskStatus,
    setOpenedJobModifyPage,
    setOpenedJobRegisterPage
  } = data;

  const { openHandle: openAlert } = alert();
  const { openHandle: openStateChange } = stateChange();

  let menusArr: {
    clickMenu: () => void;
    label: string;
    to?: string;
    state?: {};
  }[] = [];

  const menuItemWithCopyTask = {
    clickMenu: () => {
      navigate(`/dashboard/${projectId}/copy/${taskId}`);
      // window.history.replaceState(
      //   { projectId, taskId, taskDetailId: taskId },
      //   ''
      // );
      // setOpenedJobRegisterPage((v: boolean) => !v);
    },
    state: {
      display: true,
      createOk: false,
      modalData: {
        title: '작업등록',
        text: '',
        okLabel: '등록하기',
        cancelLabel: '취소하기',
        copy: true,
        taskId: taskId
      }
    },
    label: '복사하기'
  } as const;

  const menuItemModifyTask = {
    clickMenu: () => {
      navigate(`/dashboard/${projectId}/modify/${taskId}`);
      // window.history.replaceState({ taskId }, '');
      // setOpenedJobModifyPage((v: boolean) => !v);
    },
    state: {
      display: true,
      modalData: {
        text: '',
        okLabel: '수정하기',
        cancelLabel: '취소하기'
      }
    },
    label: '수정하기'
  } as const;

  const menuItemDeleteTask = {
    clickMenu: () => {
      openAlert({
        title: '작업 삭제',
        text: '한 번 삭제된 데이터는 복구가 불가능합니다.',
        ok: () => deleteTask(taskId)
      });
    },
    label: '삭제하기'
  } as const;

  const menuItemFileManage = {
    clickMenu: () => {},
    to: `/dashboard/${projectId}/${ModalType.JOBFILE_PAGE}/${taskId}`,
    state: {
      display: true,
      modalData: {
        text: '',
        okLabel: '파일관리',
        cancelLabel: '취소하기',
        taskId: taskId
      }
    },
    label: '파일관리'
  };

  const menuItemWithModifyTaskStatus = {
    clickMenu: () => {
      // console.debug(taskId, taskStatus);
      openStateChange({
        type: 'project',
        data: { taskId, status: taskStatus },
        text: '0',
        putStatus: putTaskStatus
      });
    },
    label: '상태 변경하기'
  } as const;

  switch (taskStatus) {
    case 'NEW':
    case 'PROGRESS':
    case 'COMPLETE_ASSIGN':
      menusArr = [
        menuItemWithCopyTask,
        menuItemModifyTask,
        menuItemDeleteTask
        // menuItemWithModifyTaskStatus
      ];
      if (
        (isPML || isPM) &&
        (taskStatus === 'NEW' || taskStatus === 'COMPLETE_ASSIGN')
      ) {
        menusArr.push(menuItemFileManage);
      }
      break;
    case 'COMPLETE':
      menusArr = [menuItemWithCopyTask, menuItemModifyTask];
      break;
    case 'STOP':
      menusArr = [menuItemWithCopyTask];
      break;
    case 'DELIVERY_COMPLETE':
      if (isPML) {
        menusArr = [menuItemWithCopyTask, menuItemModifyTask];
      } else {
        menusArr = [menuItemWithCopyTask];
      }
      break;
    default:
      break;
  }
  return menusArr.length > 0 ? (
    <TPPopoverMenu
      popoverMenus={menusArr}
      clickPopoverButton={(e: any) => {
        e.stopPropagation();
      }}
    >
      <TPButtonAction icon={<IconDetail />} size="small" />
    </TPPopoverMenu>
  ) : null;
}

export function renderRowStatus(code: any) {
  return (
    <>
      <div
        className="color"
        style={{
          width: 10,
          height: 10,
          backgroundColor: getTaskStatusLabelColor(code)
        }}
      ></div>
      <div
        className="label"
        style={{
          whiteSpace: 'nowrap'
        }}
      >
        {getTaskStatusTypeLabel(code)}
      </div>
    </>
  );
}

export function renderLanguageSubTaskStatus(code: any) {
  return (
    <>
      <div
        className="color"
        style={{
          width: 10,
          height: 10,
          backgroundColor: getTaskStatusLabelColor(code)
        }}
      ></div>
      <div
        className="label"
        style={{
          whiteSpace: 'nowrap'
        }}
      >
        {getLanguageSubTaskStatusTypeLabel(code)}
      </div>
    </>
  );
}

export function renderRowStatusBar(
  code: any,
  width: number = 3,
  height: number = 46
) {
  return (
    <>
      <div
        className="color"
        style={{
          width,
          height,
          backgroundColor: getTaskStatusLabelColor(code)
        }}
      ></div>
    </>
  );
}

export function MyWorkRowStatusButton({
  taskStatus,
  workerType,
  workerId, // 여기서 백으로부터 전달받은 workerId는 사람이 아닌 해당 work의 id로 되어 있다.
  workerList,
  fileRegisterStatus = false,
  refetch
}: {
  taskStatus: any;
  workerType: any;
  workerId?: any;
  workerList?: any;
  fileRegisterStatus?: boolean;
  refetch?: any;
}) {
  const { myInfo } = useMyInfo();
  const { putStatus, toastOpen } = usePutMyWorkStatus();
  const [open, setOpen] = useState(false);

  // 상태 변경 가능 여부
  let isControl: boolean = true;
  if (Array.isArray(workerList)) {
    const currentRow = workerList.find((e) => e.workerId === workerId);
    const currentUserList = currentRow?.workerList ?? [];
    const currentUserIndex = currentUserList.findIndex(
      (e: any) => e.workerType === workerType
    );
    const currentUser = currentUserList[currentUserIndex];

    // 앞사람
    const prevUser = currentUserList.find((e: any) => {
      if (currentUser.workerType === 'TRANSLATOR') return false;
      if (currentUser.workerType === 'FIRST_CHECKER')
        return e.workerType === 'TRANSLATOR';
      if (currentUser.workerType === 'SECOND_CHECKER')
        return e.workerType === 'FIRST_CHECKER';
      return false;
    });

    // 앞사람이 존재하고 앞사람이 완료가 아니면
    if (prevUser && prevUser.workerStatus !== 'COMPLETE') {
      isControl = false;
    }

    // console.debug(
    //   isControl,
    //   taskStatus,
    //   workerType,
    //   workerId,
    //   prevUser?.name,
    //   prevUser?.workerType,
    //   prevUser?.workerStatus,
    //   currentUser?.name,
    //   currentUser?.workerType,
    //   currentUser?.workerStatus
    // );
  }

  const PROGRESS = {
    label: '진행',
    onClick: async () => {
      if (!fileRegisterStatus) {
        toastOpen({
          text: '작업 파일이 등록되지 않았습니다.',
          severity: 'error'
        });
        return;
      }
      await putStatus({
        workerId,
        workerStatus: 'PROGRESS'
      });
      await refetch?.();
      setOpen(false);
    }
  };
  const WAITQUERY = {
    label: '쿼리 대기',
    onClick: async () => {
      if (!fileRegisterStatus) {
        toastOpen({
          text: '작업 파일이 등록되지 않았습니다.',
          severity: 'error'
        });
        return;
      }
      await putStatus({
        workerId,
        workerStatus: 'WAIT_QUERY'
      });
      await refetch?.();
      setOpen(false);
    }
  };
  const REJECT = {
    label: '반려',
    onClick: async () => {
      if (!fileRegisterStatus) {
        toastOpen({
          text: '작업 파일이 등록되지 않았습니다.',
          severity: 'error'
        });
        return;
      }
      await putStatus({
        workerId,
        workerStatus: 'REJECT'
      });
      await refetch?.();
      setOpen(false);
    }
  };
  const COMPLETE = {
    label: '완료',
    onClick: async () => {
      if (!fileRegisterStatus) {
        toastOpen({
          text: '작업 파일이 등록되지 않았습니다.',
          severity: 'error'
        });
        return;
      }
      await putStatus({
        workerId,
        workerStatus: 'COMPLETE'
      });
      await refetch?.();
      setOpen(false);
    }
  };
  const COMPLETETASK = {
    label: '완료',
    onClick: async () => {
      if (!fileRegisterStatus) {
        toastOpen({
          text: '작업 파일이 등록되지 않았습니다.',
          severity: 'error'
        });
        return;
      }
      await putStatus({
        workerId,
        workerStatus: 'COMPLETE_TASK'
      });
      await refetch?.();
      setOpen(false);
    }
  };

  const actions = [
    //
    ['NEW', 'TRANSLATOR', [PROGRESS]],
    ['NEW', 'FIRST_CHECKER', [PROGRESS]],
    ['NEW', 'SECOND_CHECKER', [PROGRESS]],
    //
    ['PROGRESS', 'TRANSLATOR', [WAITQUERY, COMPLETE]],
    ['PROGRESS', 'FIRST_CHECKER', [REJECT, WAITQUERY, COMPLETE]],
    ['PROGRESS', 'SECOND_CHECKER', [REJECT, WAITQUERY, COMPLETE]],
    //
    ['WAIT_QUERY', 'TRANSLATOR', [PROGRESS, COMPLETE]],
    ['WAIT_QUERY', 'FIRST_CHECKER', [PROGRESS, REJECT, COMPLETE]],
    ['WAIT_QUERY', 'SECOND_CHECKER', [PROGRESS, REJECT, COMPLETE]],
    //
    ['REJECT', 'TRANSLATOR', [PROGRESS]],
    ['REJECT', 'FIRST_CHECKER', [PROGRESS]],
    ['REJECT', 'SECOND_CHECKER', [PROGRESS]],
    //
    ['COMPLETE_TASK', 'TRANSLATOR', []],
    ['COMPLETE_TASK', 'FIRST_CHECKER', []],
    ['COMPLETE_TASK', 'SECOND_CHECKER', []],
    //
    ['STOP', 'TRANSLATOR', []],
    ['STOP', 'FIRST_CHECKER', []],
    ['STOP', 'SECOND_CHECKER', []]
  ];

  const items = actions
    .filter(([ts, wt]) => ts === taskStatus && wt === workerType)
    .map(([a, b, c]) => {
      return c;
    })[0];

  return (
    <>
      {taskStatus ? (
        <>
          <div
            className="color"
            style={{
              width: 10,
              height: 10,
              backgroundColor: getTaskStatusLabelColor(taskStatus)
            }}
          />
          <div
            className="label"
            style={{
              whiteSpace: 'nowrap',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (Array.isArray(items) && items.length > 0 && isControl)
                setOpen((v) => !v);
            }}
          >
            {getLanguageSubTaskStatusTypeLabel(taskStatus)}
          </div>
        </>
      ) : (
        <div>미배정</div>
      )}
      {Array.isArray(items) && items.length > 0 && isControl && (
        <div
          style={{
            position: 'relative'
          }}
        >
          <div
            className="button"
            style={{
              padding: 4,
              height: 41,
              cursor: 'pointer',
              userSelect: 'none'
            }}
            onClick={() => setOpen((v) => !v)}
          >
            <IcoTriangle />
          </div>
          {open && (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div
                className="menu"
                style={{
                  position: 'absolute',
                  left: '50%',
                  zIndex: 1051,
                  width: 125,
                  border: '1px solid #ececec',
                  boxShadow: '2px 2px 4px 0px #00000014',
                  transform: 'translateX(-50%)'
                }}
              >
                {items.map((item: any, i: number) => (
                  <Box
                    key={i}
                    sx={{
                      position: 'relative',
                      cursor: 'pointer',
                      userSelect: 'none',
                      textAlign: 'center',
                      padding: '4px',
                      height: '31px',
                      backgroundColor: '#fff',
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </Box>
                ))}
              </div>
            </ClickAwayListener>
          )}
        </div>
      )}
    </>
  );
}

const JobDetailMyWorkModalButtonWrap = styled.div``;
export function JobDetailMyWorkModalButton({
  children,
  languageTaskId
}: {
  children: ReactNode;
  languageTaskId: number;
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <JobDetailMyWorkModalButtonWrap>
      {cloneElement(children as any, {
        style: { cursor: 'pointer' },
        onClick: () => {
          navigate(`/mytasktab/detail/${languageTaskId}`);
        }
      })}
      <Modal
        open={open}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') return;
          handleClose();
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <JobDetailMyWork close={handleClose} />
      </Modal>
    </JobDetailMyWorkModalButtonWrap>
  );
}

export const SubTitleWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -1px;
  text-align: left;
  color: #9b51e0;
`;

export const TitleWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -1px;
  text-align: left;
  color: #242424;
`;

export const ModalTitleWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.25px;
  text-align: left;
  color: #242424;
`;

export const INFO_GROSS = `입력할 매치율 별 단어 수 총합이 자동 계산됩니다.`;
export const INFO_NET = `입력할 매치율 별 단어 수가 각 배율에 따라 자동 계산됩니다.<br />
소수점 첫째 자리에서 반올림됩니다.<br />

[Net의 각 매치율 별 배율]<br />
101% = 0<br />
Rep = 0.1<br />
100% = 0.1<br />
95%~99% = 0.7<br />
85%~94% = 0.7<br />
75%~84% = 0.7<br />
50%~74% = 1.0<br />
No match = 1.0`;

export const INFO_NET_NEW = (
  <div>
    [번역, 폴리싱]
    <br />
    입력한 매치율 별 단어 수가 각 배율에 따라 자동 계산
    <br />
    (소수점 첫째 자리에서 반올림됩니다.)
    <br />
    <br />
    [LQA]
    <br />
    입력한 작업 시간 (250단어 · 자 / 시간)
  </div>
);
export const INFO_GROSS_NEW = (
  <div>
    [번역, 폴리싱]
    <br />
    입력한 매치율 별 단어 수의 총합
    <br />
    <br />
    [LQA]
    <br />
    입력한 작업 시간 (250단어 · 자 / 시간)
  </div>
);

export function renderNetInfo(
  position?: 'top' | 'center' | 'bottom',
  title: string = '총 분량(Net)',
  tooltip: boolean = true
) {
  return (
    <InfoWrap>
      <span>{title}</span>
      <CustomizedTooltips title={INFO_NET_NEW} />
    </InfoWrap>
  );
}

export function renderGrossInfo() {
  return (
    <InfoWrap>
      <span>총 분량(Gross)</span>
      <CustomizedTooltips title={INFO_GROSS_NEW} />
    </InfoWrap>
  );
}

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          boxShadow: '2px 2px 4px 0px #0001',
          border: '1px solid #ececec',
          color: '#000',
          fontSize: '12px',
          fontWeight: '400'
        }
      }
    }
  }
});

export default function CustomizedTooltips({ title }: { title: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={title}>
        <IconInfoBlack />
      </Tooltip>
    </ThemeProvider>
  );
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 2px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

// 파일관리 숫자 내려오는 노직
export function TaskFileButton({ taskId, taskTitle, completeCount }: any) {
  const navigate = useNavigate();

  return (
    <TaskFileButtonWrap
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        sessionStorage.setItem('keyword', taskTitle);
        navigate(`/taskManager`);
      }}
    >
      <span className="label">파일관리</span>
      <span className="count">{completeCount}</span>
    </TaskFileButtonWrap>
  );
}
const TaskFileButtonWrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.25px;
  text-align: left;
  color: #727272;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  user-select: none;
  cursor: pointer;

  .label {
    text-decoration: underline;
  }
  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    background-color: #fafafa;
    border-radius: 20px;
  }
`;

export function dateToKST(date: any) {
  if (date instanceof Date)
    return format(date, 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T');
  return '';
}

// const customOrder = [
//   'PERCENT_101',
//   'PERCENT_REP',
//   'PERCENT_100',
//   'PERCENT_9599',
//   'PERCENT_8594',
//   'PERCENT_7584',
//   'PERCENT_5074',
//   'NO_MATCH'
// ];
const customOrder = [
  'percent101',
  'percentRep',
  'percent100',
  'percent9599',
  'percent8594',
  'percent7584',
  'percent5074',
  'noMatch'
];

export function formatAmountObject(obj: Record<string, number>) {
  if (!obj || typeof obj !== 'object') return {};

  return {
    percent101: obj.percent101 ?? '',
    percentRep: obj.percentRep ?? '',
    percent100: obj.percent100 ?? '',
    percent9599: obj.percent9599 ?? '',
    percent8594: obj.percent8594 ?? '',
    percent7584: obj.percent7584 ?? '',
    percent5074: obj.percent5074 ?? '',
    noMatch: obj.noMatch ?? ''
  };
}
// export function amountArrayToObject(arr: any) {
//   if (Array.isArray(arr)) {
//     return arr.reduce(
//       (r, { matchingRate, amount }) =>
//         Object.assign(r, { [matchingRate]: amount }),
//       {}
//     );
//   }
//   return {};
// }

export function amountArraySortToObject(obj: Record<string, number>) {
  if (!obj || typeof obj !== 'object') return {};

  // customOrder에 따라 정렬된 객체 반환
  return customOrder.reduce((result, key) => {
    if (key in obj) {
      result[key] = obj[key];
    }
    return result;
  }, {} as Record<string, number>);
}
// export function amountArraySortToObject(arr: any) {
//   if (Array.isArray(arr)) {
//     return arr
//       .sort((a, b) => {
//         return (
//           customOrder.indexOf(a.matchingRate) -
//           customOrder.indexOf(b.matchingRate)
//         );
//       })
//       .reduce(
//         (r, { matchingRate, amount }) =>
//           Object.assign(r, { [matchingRate]: amount }),
//         {}
//       );
//   }
//   return {};
// }

// export function amountObjectToArray(
//   obj: any,
//   isBoracatUseOrNot: boolean = false
// ) {
//   const arr = [];
//   if (obj instanceof Object) {
//     for (let matchingRate in obj) {
//       const amount = obj[matchingRate];
//       arr.push({ matchingRate, amount: isBoracatUseOrNot ? 0 : amount });
//     }
//   }
//   return arr;
// }

export function amountObject(obj: any, isBoracatUseOrNot: boolean = false) {
  const result: { [key: string]: number } = {};
  if (obj instanceof Object) {
    for (let matchingRate in obj) {
      result[matchingRate] = isBoracatUseOrNot ? 0 : Number(obj[matchingRate]);
    }
  }
  return result;
}
