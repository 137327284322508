import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import {
  STATUS_COLOR,
  getStatus,
  getStatusLabel,
  getTaskStatusLabel
} from '@src/common/config/Code';
import { useAlert } from '@src/hooks/modal/useAlert';
import { useGetTask } from '@src/hooks/task/get';
import { useUpsertTask } from '@src/hooks/task/post';
import { IcoTriangle } from '@src/resources/icon';
import {
  CSSProperties,
  HTMLAttributes,
  MouseEvent,
  useEffect,
  useId,
  useRef,
  useState
} from 'react';

interface RowStatusBadge2Props extends React.HTMLAttributes<HTMLDivElement> {
  rowData?: any;
  id?: any;
  status?: any;
  statusChangeMap?: any;
  style?: CSSProperties;
}

function RowStatusBadge2({
  rowData,
  id,
  status,
  statusChangeMap,
  style
}: RowStatusBadge2Props) {
  // props
  const cid = useId();
  const statusCode = status;
  const [statusChangeList, setStatusChangeList] = useState(() =>
    statusCode ? statusChangeMap?.[statusCode] : undefined
  );
  const label = getTaskStatusLabel(statusCode);
  const color =
    STATUS_COLOR[statusCode as keyof typeof STATUS_COLOR] ?? 'transparent';
  // states
  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const handleClose = (event: Event) => {
    // @ts-ignore
    if (event?.detail?.cid !== cid) setOpenDropdown(false);
  };
  // hooks
  const { putTaskStatus } = useUpsertTask();
  const { useQueryByTaskStatusHistory } = useGetTask();
  const { openHandle: alertHandle } = useAlert();

  const { refetch: refetchTaskStatusHistory } = useQueryByTaskStatusHistory(
    id,
    {
      enabled: id && status === 'STOP',
      onSuccess: (data: any) => {
        if (data?.taskStatus)
          setStatusChangeList((v: any) => [
            // ...v.filter((e: any) => e !== status),
            ...(Array.isArray(v) ? v.filter((e: any) => e !== status) : []),
            data.taskStatus
          ]);
      }
    }
  );

  useEffect(() => {
    if (status === 'STOP') refetchTaskStatusHistory();
    else setStatusChangeList(statusChangeMap?.[status]);
  }, [status]);

  // handlers
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (statusChangeList !== undefined) setOpenDropdown((v) => !v);
    const event = new CustomEvent('RowStatusBadge2ClickEvent', {
      detail: { cid }
    });
    window.dispatchEvent(event);
  };
  useEffect(() => {
    window.addEventListener('RowStatusBadge2ClickEvent', handleClose);
    return () => {
      window.removeEventListener('RowStatusBadge2ClickEvent', handleClose);
    };
  }, [openDropdown, setOpenDropdown]);
  // render

  return (
    <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
      <RowStatusBadge2Wrap ref={anchorRef} onClick={onClick} style={style}>
        <div
          className={cx({ bar: true, [statusCode ?? 'non']: true })}
          style={{ backgroundColor: color }}
        />
        <div className="label">{label}</div>
        {statusChangeList &&
          !(
            rowData?.taskStatus === 'COMPLETE' && rowData?.includeSubTaskByPm
          ) && (
            <div className="statusChangeList-btn">
              <IcoTriangle />
            </div>
          )}
        {openDropdown && statusChangeList && (
          <Popper
            sx={{
              zIndex: 1
            }}
            open={openDropdown}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'right top' : 'right bottom',
                  // width: anchorRef.current?.parentElement?.clientWidth,
                  paddingLeft: 2,
                  paddingRight: 2
                }}
              >
                <Paper>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {id &&
                    Array.isArray(statusChangeList) &&
                    statusChangeList.length > 0 ? (
                      statusChangeList.map((nextStatusCode: any, i) => (
                        <div
                          className="button-item"
                          key={i}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const payload = {
                              taskId: id,
                              taskStatus: nextStatusCode
                            };
                            alertHandle({
                              title: '상태 변경',
                              text: '상태를 변경하시겠습니까?',
                              cancelLabel: '취소',
                              ok: () => {
                                putTaskStatus(payload);
                              }
                            });
                            setOpenDropdown(false);
                          }}
                        >
                          {getStatusLabel(nextStatusCode)}
                        </div>
                      ))
                    ) : (
                      <div className="button-item">변경 불가</div>
                    )}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </RowStatusBadge2Wrap>
    </ClickAwayListener>
  );
}

export default RowStatusBadge2;

export const RowStatusBadge2Wrap = styled.div<RowStatusBadge2Props>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  user-select: none;
  width: 100%;
  padding: 7px 23px;

  .bar {
    width: 10px;
    height: 10px;
    border-radius: 1px;
  }

  .statusChangeList-btn {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      svg {
        fill: #000;
        stroke: #000;
      }
    }
  }

  .dropdown {
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    border: 1px solid #ececec;
    box-shadow: 2px 2px 4px 0px #00000014;
    animation: dropdown 0.3s ease-out;

    z-index: 1;

    & > * {
      padding: 8px 16px;
      &:hover {
        background-color: #9b51e00f;
      }
    }
  }
  .button-item {
    user-select: none;
    padding: 1px 16px;
    &:hover {
      background-color: #00000014;
    }
  }

  @keyframes dropdown {
    0% {
      transform: translateY(calc(100% - 4px));
      opacity: 0.6;
    }
    100% {
      transform: translateY(100%);
      opacity: 1;
    }
  }
`;
