import { css, cx } from '@emotion/css';
import { IcoMenuChevronDown } from '@resources/icon';
import { useHookFunc } from '@src/hooks/utils';
import _ from 'lodash';
import { ReactElement, useMemo } from 'react';
import { TPInputCheckBox } from '../input/TPInput.checkBox';
import { FilterInput, FilterWrap, Gap, SelectList } from './TPSelect.Style';

interface ISelectCheckedProps {
  label: string | ReactElement;
  optionList: readonly {
    code: string;
    label: string;
    text?: string;
  }[];
  useOptionLabel?: boolean;
  useFilterStatus?: { [key: string]: string | [string, string] };
  selectOptions: string[];
  changeSelect: (state: any) => void;
  icon?: ReactElement;
  iconDown?: ReactElement;
  iconUp?: ReactElement;
  multiple?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const TPSelectCheck = (props: ISelectCheckedProps) => {
  const { arraySet } = useHookFunc();
  const {
    label,
    useOptionLabel,
    optionList,
    selectOptions,
    changeSelect,
    size
  } = props;
  const showSelectedValue = useMemo(() => {
    let v: any = selectOptions.filter(
      (e) =>
        e !== 'ALL' && e !== '0' && e !== '' && e !== null && e !== undefined
    ).length;
    if (v === 0) v = '';
    return v;
  }, [selectOptions]);
  const setSelect = (option: string) => {
    const setOption = _.sortBy(
      _.filter(
        arraySet(selectOptions, option),
        (el) => !['0', 'ALL'].includes(el)
      )
    );

    const defaultOption = _.sortBy(
      _.filter(
        optionList.map((v) => v.code),
        (el) => !['0', 'ALL'].includes(el)
      )
    );

    if (_.isEqual(setOption, defaultOption)) {
      changeSelect('ALL');
    } else {
      changeSelect(option);
    }
  };

  return (
    <FilterWrap>
      <FilterInput
        className={cx('filter-input', {
          'filter--active': Boolean(showSelectedValue)
        })}
      >
        <div className="label--checked">
          <span className="label">{label}</span>
          <span className="checked">{showSelectedValue}</span>
        </div>

        <div>
          <IcoMenuChevronDown className="icon up" />
        </div>
      </FilterInput>
      <div className="dropdown">
        <Gap />
        <SelectList className={size ? `size--${size}` : ''}>
          {optionList.map((item, index) => {
            return props.multiple === undefined || props.multiple === true ? (
              <TPInputCheckBox
                option={useOptionLabel ? item.label : item.text ?? ''}
                optionKey={item.code}
                selectOptions={selectOptions}
                onClick={setSelect}
                key={index}
              />
            ) : (
              <div
                key={index}
                onClick={() => {
                  setSelect(item.code);
                }}
                className={css`
                  cursor: pointer;
                  text-align: left;
                  width: 100%;
                  padding: 0.2rem 1rem;
                  &:hover {
                    background-color: #f7f7f7;
                  }
                `}
              >
                {useOptionLabel ? item.label : item.text ?? ''}
              </div>
            );
          })}
        </SelectList>
      </div>
    </FilterWrap>
  );
};
