import { css, keyframes } from '@emotion/react';
import { IconInputCheckUrl } from '@resources/icon';

const verticalUp = keyframes`
  0% { transform: translateY(50px); opacity: 0; } 
  100% { transform: translateY(0); opacity: 1; } 
`;

const verticalDown = keyframes`
  0% { transform: translateY(-50px); opacity: 0; } 
  100% { transform: translateY(0); opacity: 1; }
`;

const inputError = keyframes`
  0% { transform: translateY(-2px); } 
  20% { transform: translateY(2px); }
  40% { transform: translateY(-2px); }
  60% { transform: translateY(2px); }
  80% { transform: translateY(-2px); }
  100% { transform: translateY(0); } 
`;

export const loginWrap = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ececec;

  .loginBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32px 58px 25px;
    width: 624px;
    height: 720px;
    max-height: calc(100vh - 50px);
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    .logo {
      margin-bottom: 153px;
      width: 155px;
      height: 32px;
    }
    .loginTitle {
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 59px;
      color: #242424;
    }
    .loginInfo {
      margin-bottom: 18px;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: -1.4px;
      color: #727272;
    }
    .formStepWrap {
      padding-top: 10px;
      overflow: hidden;
      .formStep {
        position: relative;
        left: 0;
        width: 200%;
        display: flex;
        transition: all 0.2s ease-out;
        &.step1 {
          left: 0;
        }
        &.step2 {
          left: -100%;
          min-height: 199px;
          max-height: 235px;
        }
        .googleLoginBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 100%;
          height: 75px;
          font-size: 20px;
          border: 1px solid #cbcbcb;
          border-radius: 50px;
        }
        &:hover {
          #google-login-button {
            position: absolute;
            right: 0%;
            transform: scale(2);
            transform-origin: top;
          }
        }
        #google-login-button {
          height: 75px;
          iframe {
            * {
              width: 508px !important;
              height: 75px !important;
            }
          }
        }
      }
    }
    .loginPrivacyInfo {
      margin-top: auto;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -1.4px;
      line-height: 160%;
      color: #727272;
      .loginPrivacyBtn {
        color: #9b51e0;
      }
    }
  }
`;

export const formStepInner = css`
  width: 50%;
  &:first-of-type {
    margin-right: 20px;
  }
  .formLabel {
    position: relative;
    display: block;
    background: #fff;
    .formInput {
      width: 100%;
      height: 56px;
      vertical-align: top;
      background: #ffffff;
      border: 1px solid #ececec;
      box-sizing: border-box;
      padding: 15px 20px;
      transition: border-color 0.3s ease-out;
      &:focus,
      &.active {
        border-color: #aa51e0;
      }
      &.error {
        border: 1px solid #b3261e;
        background: #fffcfc;
        animation: ${inputError} 0.3s ease-in-Out 1;
      }
      &.valid {
        background: #fff url(${IconInputCheckUrl}) no-repeat calc(100% - 20px)
          center;
      }
    }
    .formPlaceholder {
      position: absolute;
      left: 6px;
      top: 18px;
      display: inline-block;
      padding: 0 5px;
      font-weight: 304;
      color: #aaaaaa;
      background: transparent;
      pointer-events: none;
      transition: color, background, font-size, top 0.15s ease-out;
      &.active,
      &.focus {
        color: #aa51e0;
        background: #fff;
        font-size: 0.75rem;
        top: -11px;
      }
      &.error {
        color: #b3261e;
        background: #fffcfc;
        font-size: 0.75rem;
        top: -11px;
      }
    }
  }
  .formValidText {
    font-weight: 500;
    display: block;
    text-align: left;
    font-size: 0.75rem;
    color: #b3261e;
    padding: 8px 0 0 4px;
  }
  .loginEmailBtn {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
  }
  .loginOR {
    position: relative;
    margin: 40px 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      border-top: 1px solid #eee;
    }
    span {
      position: relative;
      display: inline-block;
      background: #fff;
      padding: 0 16px;
    }
  }
  .loginPwReissuanceWrap {
    position: relative;
    margin: 40px 0;
    button {
      border: 0 none;
      color: #9b51e0;
      background: transparent;
    }
  }
`;

export const googleloginBtn = css`
  width: 100%;
  font-family: Spoqa Han Sans Neo, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  line-height: 54px;
  letter-spacing: -0.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #ffffff;
  border: 1px solid #d5d3e4;
  box-sizing: border-box;
  border-radius: 30px;
  svg {
    margin-right: 10px;
  }
`;
