import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { useCallback, useEffect, useState } from 'react';
import { addDays, addMonths, addYears, format } from 'date-fns';
import { DayPicker, DateRange, DateFormatter } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { CalendarWrap, CalendarStyle } from '@hooks/modal/Calendar.style';
import { IconModalClose } from '@resources/icon';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import TPButtonClose from '@src/units/button/TPButton.Close';
import { calendarPickerClasses } from '@mui/lab';

/**
 * @interface IDateRange
 * @description 달력에서 선택할 수 있는 날짜 범위
 * @property {Date | string} from - 최소 날짜
 * @property {Date | string} to - 최대 날짜
 */
export interface IDateRange {
  from?: Date | string;
  to?: Date | string;
}

/**
 * @interface IDateLimit
 * @description 달력에서 선택할 수 있는 최소, 최대 날짜
 * @property {Date | string} fromLimit - 최소 날짜
 * @property {Date | string} toLimit - 최대 날짜
 */
interface IDateLimit {
  fromLimit?: Date | string;
  toLimit?: Date | string;
}

/**
 * @interface IRangeCalendarState
 * @description 달력에서 선택할 수 있는 날짜 범위
 * @property {IDateRange} dateRange - 선택할 날짜 범위
 * @property {IDateLimit} dateLimit - 선택할 수 있는 최소, 최대 날짜
 */
interface IRangeCalendarState {
  dateType: 'dateRange' | string;
  dateLimit?: IDateLimit;
  from?: Date;
  to?: Date;
}

/**
 * 달력 caption format (년. 월)
 * @type {(month: Date) => JSX.Element}
 * @param month
 */
const formatCaption: DateFormatter = (month: Date) => (
  <>
    {`${month.getFullYear()}. ${month.getMonth() + 1 <= 9 ? 0 : ''}${
      month.getMonth() + 1
    }`}
  </>
);

/**
 * 달력 요일 format (Mo, Tu, We, Th, Fr, Sa, Su)
 * @type {(weekday: Date) => JSX.Element}
 * @param weekday
 */
const formatWeekdayName: DateFormatter = (weekday: Date): JSX.Element => (
  <>{format(weekday, 'eeeeee')}</>
);

/**
 * dateRangePicker hook
 * @type {(props?: IRangeCalendarState) => {dateRange: {from: Date, to: Date }; openHandle: () => void; closeHandle: () => void; component: () => JSX.Element}}
 */
export const useRangeCalendar = (props?: IRangeCalendarState) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  //#region props
  const nextMonth = addMonths(new Date(), 0);
  const [month, setMonth] = useState<Date>(nextMonth);

  const { dateType } = props ?? { dateType: 'dateRange' };
  const { fromLimit, toLimit } = props?.dateLimit ?? {
    fromLimit: addYears(new Date(), -1),
    toLimit: addYears(new Date(), 1)
  };
  //#endregion

  //#region global state
  const calenderData = useGlobalValue([dateType], {
    open: false,
    setDate: () => {}
  });
  const { setDate } = calenderData;
  const modalFetcher = useSetGlobalState([dateType]);
  //#endregion

  //#region state
  const [selectRange, setSelectRange] = useState<DateRange | undefined>({
    from: props?.from ?? new Date(),
    to: props?.to ?? addDays(new Date(), 0)
  });
  //#endregion

  //#region handler
  const openHandle = (
    dateType: string,
    dateRange:
      | { startDateTime?: Date | string; endDateTime?: Date | string }
      | undefined,
    setDate: (el?: any) => void
  ) => {
    modalFetcher({ ...calenderData, open: true, dateRange, dateType, setDate });
    const date = dateRange
      ? {
          from: dateRange?.startDateTime
            ? new Date(dateRange?.startDateTime as string)
            : new Date(),
          to: dateRange?.endDateTime
            ? new Date(dateRange?.endDateTime as string)
            : addDays(new Date(), 0)
        }
      : {
          from: new Date(),
          to: addDays(new Date(), 0)
        };
    setSelectRange(date);
    setIsCalendarOpen(true);
  };

  const closeHandle = useCallback(() => {
    modalFetcher({ calenderData, open: false });
    setIsCalendarOpen(false);
  }, [calenderData, modalFetcher]);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const calendarElement = document.querySelector('.range');
      if (calendarElement && !calendarElement.contains(e.target as Node)) {
        closeHandle();
      }
    },
    [closeHandle]
  );

  useEffect(() => {
    if (isCalendarOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isCalendarOpen, handleOutsideClick]);

  const clickConfirm = (fromTo: DateRange | undefined) => {
    let confirmRange = JSON.parse(JSON.stringify(fromTo));
    setDate({
      startDateTime: confirmRange?.from,
      endDateTime: confirmRange?.to
    });
    setTimeout(() => {
      closeHandle();
    });
  };
  //#endregion

  //#region component
  const Component = (): JSX.Element => {
    return (
      calenderData.open && (
        <div css={CalendarWrap} className={'range'}>
          <div className="top">
            <TPButtonClose className="close-button" onClick={closeHandle} />
          </div>
          <div className="middle">
            <DayPicker
              month={month}
              onMonthChange={setMonth}
              formatters={{ formatCaption, formatWeekdayName }}
              css={CalendarStyle}
              mode={'range'}
              numberOfMonths={2}
              selected={selectRange}
              weekStartsOn={0}
              onSelect={setSelectRange}
              fromDate={fromLimit ? new Date(fromLimit) : undefined}
              toDate={toLimit ? new Date(toLimit) : undefined}
            />
          </div>
          <div className="bottom" />
          <div className="buttonWrap">
            <div className="cancelButton">
              <TPButtonBasic
                fullWidth
                label="취소"
                size="large"
                variant="outlined"
                onClick={closeHandle}
              />
            </div>
            <div className="confirmButton">
              <TPButtonBasic
                fullWidth
                disabled={!selectRange || !selectRange.from || !selectRange.to}
                label="확인"
                size="large"
                variant="contained"
                onClick={() => {
                  clickConfirm(selectRange);
                }}
              />
            </div>
          </div>
        </div>
      )
    );
  };

  return {
    open: calenderData.open,
    closeHandle,
    openHandle,
    Component,
    selectRange,
    setSelectRange
  };
  //#endregion
};
