import { css } from '@emotion/react';
import { Color } from '@common/styles/color';

export const AssignAlertStyle = css`
  .assignDateBox {
    display: flex;
    gap: 130px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 16px;
    background: #fafafa;
    .dateCheck {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #727272;
        &.disabled {
          opacity: 0.4;
        }
      }
      p {
        font-size: 12px;
        color: #eb760a;
      }
    }
    .selectBox {
      &.disabled {
        opacity: 0.3;
        &:hover {
          fieldset {
            border-color: #ececec;
          }
          input {
            cursor: auto;
            &:focus {
              border: none;
            }
          }
        }
        fieldset {
          border-color: #ececec;
        }
      }
      .dateReminder {
        text-align: left;
        font-size: 11px;
        color: #e6311a;
      }
    }
  }
  .languageList {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 8px;
    max-height: 280px;
    overflow-y: auto;
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      background-color: #fafafa;
      .languageTitle {
        width: calc(100% - 466px);
        color: #727272;
        .bar {
          display: flex;
          align-items: center;
          gap: 7px;
          margin-bottom: 4px;
          text-align: left;
          > span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 1px;
          }
        }
        .languageCode {
          display: flex;
          gap: 8px;
          align-items: center;
          text-align: left;
        }
      }
      > p {
        width: 434px;
        text-align: left;
        color: #242424;
      }
    }
  }
  .buttonBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    > .normal {
      padding: 0 14px;
      height: 32px;
      color: rgba(36, 36, 36, 1);
      background: #fff;
      border: 1px solid rgba(236, 236, 236, 1);
    }
  }
`;
