import api from '@api/Api';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { languageTaskKeys } from '../queryKeyFactory';
import { IUser } from '@src/common/config/ICode';
import { useEffect, useState } from 'react';
import { getLanguageLabelByCode } from '@src/common/config/Code';

/**
 * /languageTask/assignWorker
 * @param languageTaskId 담당자 목록 가졍오기 (modal)
 * @returns {{ assignWorkerData, searchWorkerData, keyword, setKeyword }}
 */
export const useGetTaskAssignWorker = () => {
  const [keyword, setKeyword] = useState('');
  const [searchWorkerData, setSearchWorkerData] = useState<IUser[]>([]);

  const getData = async (): Promise<IUser[]> => {
    return (await api.get(`/languageTask/assignWorker`)).data
      .assignWorkUserList;
  };

  const { data: assignWorkerData = [] } = useQuery(
    [...languageTaskKeys.assignWorker],
    getData
  );

  useEffect(() => {
    let v = assignWorkerData;
    if (keyword.length) {
      v = [
        ..._.filter(
          assignWorkerData,
          (el) =>
            el.name.toUpperCase().includes(keyword.toUpperCase()) ||
            (Array.isArray(el.languagePairList) &&
              el.languagePairList.some(
                ({ startLanguage, destinationLanguage }) =>
                  getLanguageLabelByCode(startLanguage).includes(keyword) ||
                  getLanguageLabelByCode(destinationLanguage).includes(keyword)
              ))
        )
      ];
    }

    // setSearchWorkerData(v);
    if (!_.isEqual(searchWorkerData, v)) {
      setSearchWorkerData(v);
    }
  }, [keyword, assignWorkerData]);

  return { assignWorkerData, searchWorkerData, keyword, setKeyword };
};
