import api from '@api/Api';
import _ from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IUser } from '@src/common/config/ICode';
import { useState } from 'react';
import { useHookFunc, useHookLLAssignFunc } from '../utils';
import { getLanguageLabelByCode } from '@src/common/config/Code';

export const useGetAllProjectAssignerList = () => {
  const [keyword, setKeyword] = useState<string>('');
  const { defaultUserList } = useHookLLAssignFunc();
  const getData = async (): Promise<IUser[]> => {
    return (await api.get(`/project/allProjectAssignerList`)).data
      .projectAssignerList;
  };

  const projectAssignerListQuery = useQuery(
    ['project/allProjectAssignerList'],
    getData,
    {
      select: (data) =>
        keyword.length
          ? [
              ..._.filter(
                data,
                (el) =>
                  el.name.toUpperCase().includes(keyword.toUpperCase()) ||
                  (Array.isArray(el.languagePairList) &&
                    el.languagePairList.some(
                      ({ startLanguage, destinationLanguage }) =>
                        getLanguageLabelByCode(startLanguage).includes(
                          keyword
                        ) ||
                        getLanguageLabelByCode(destinationLanguage).includes(
                          keyword
                        )
                    ))
              )
            ]
          : data
    }
  );

  const {
    data: allProjectAssignerList = [...defaultUserList],
    refetch: refetchAllProjectAssignerList
  } = projectAssignerListQuery;

  return {
    keyword,
    setKeyword,
    allProjectAssignerList,
    refetchAllProjectAssignerList,
    projectAssignerListQuery
  };
};

// 담당자 선택 출발어, 도착어, 사용자 이름 검색 필터 post 요청
export const usePostFilterAssignerList = () => {
  // 초기 데이터를 위한 쿼리
  const { data: assignerList, mutate: postFilterAssignerList } = useMutation({
    mutationFn: async (payload: {
      userName?: string;
      startLanguageList?: string[];
      destinationLanguageList?: string[];
    }) => {
      const newPayload: Record<string, string | string[]> = {};

      // 각 필드별로 값이 있는 경우에만 새 객체에 추가
      if (payload.userName && payload.userName.trim() !== '') {
        newPayload['userName'] = payload.userName;
      }

      if (payload.startLanguageList && payload.startLanguageList.length > 0) {
        newPayload['startLanguageList'] = payload.startLanguageList;
      }

      if (
        payload.destinationLanguageList &&
        payload.destinationLanguageList.length > 0
      ) {
        newPayload['destinationLanguageList'] = payload.destinationLanguageList;
      }

      const response = await api.post(
        '/project/filterAssignerList',
        newPayload
      );
      return response.data.projectAssignerList;
    }
  });

  return {
    postFilterAssignerList,
    data: assignerList || []
  };
};

export const useGetProjectAssignerList = () => {
  const { defaultUserList } = useHookFunc();
  const getData = async (): Promise<IUser[]> => {
    return (await api.get(`/project/projectAssignerList`)).data
      .projectAssignerList;
  };

  const projectAssignerListQuery = useQuery(
    ['/project', '/projectAssignerList'],
    getData,
    {
      select: (data) => [...defaultUserList, ...data]
    }
  );

  const {
    data: projectAssignerList = [...defaultUserList],
    refetch: refetchAllProjectAssignerList
  } = projectAssignerListQuery;

  return {
    projectAssignerList,
    refetchAllProjectAssignerList,
    projectAssignerListQuery
  };
};
