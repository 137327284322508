import { ThemeProvider } from '@mui/material/styles';
import { IcoMenuChevronDown, IconPlus } from '@resources/icon';
import { Fab } from '@mui/material';
import { TPButtonTheme } from './TPButton.Theme';
import { ReactElement, MouseEvent } from 'react';
import styled from '@emotion/styled';

interface IButtonProps {
  size?: 'small' | 'medium' | 'large' | undefined;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  icon?: ReactElement;
  outline?: boolean;
  content?: string;
  calendarType?: string;
}

export const TPButtonActionCalendar = (props: IButtonProps) => {
  const { icon, outline, content, calendarType } = props;
  const propsOverride = {
    ...props,
    icon: undefined,
    outline: undefined
  };
  if (calendarType === 'normal') {
    return (
      <CalendarNormal>
        <span>{content}</span>
        <IcoMenuChevronDown />
      </CalendarNormal>
    );
  } else {
    return (
      <ThemeProvider theme={TPButtonTheme}>
        <Fab
          className={content ? 'contentWrap' : outline ? '' : 'bordernone'}
          {...propsOverride}
        >
          <span>{content}</span>
          {icon ? icon : <IconPlus />}
        </Fab>
      </ThemeProvider>
    );
  }
};

const CalendarNormal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  min-width: 140px;
  height: 32px;
  color: #727272;
  cursor: pointer;
`;
