import api from '@api/Api';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

export function usePartPerformance(pageType: string) {
  const queryClient = useQueryClient();
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(false);

  const postData = async (payload: any) => {
    return await api
      .postPms(`/statistics/languagePairPerWordAmount`, payload)
      .then((res) => res.data);
  };

  const { mutate: postFilter } = useMutation({
    mutationFn: postData,
    onSuccess: (data) => {
      setResponseData(data);
      setLoading(true);
      queryClient.invalidateQueries([pageType]);
    },
    onError: (error) => {
      console.log('파트별 퍼포먼스 post 오류', error);
      setLoading(false);
    }
  });

  return { postFilter, responseData, loading };
}
